import Table from "components/_UI/Table"
import Text from "components/_UI/Text"
import InfoTooltip from "components/_UI/InfoTooltip"
import ToastContainer from "components/_UI/ToastsContainer"
import useToast from "hooks/useToast"
import { Row } from "styled/alignment/Row"
import { Copy, Download } from "react-feather"
import styled from "styled-components"
import colors from "theme/colors"
import { copyToClipboard } from "helpers/clipboard"
import { useEffect, useState } from "react"
import financialApi from "api/requests/financial"
import Loader from "components/_UI/Loader"
import { Column } from "styled/alignment/Column"
import LinkPage from "components/_UI/LinkPage"
import Paginate from "components/_UI/Paginate"
import NotResultDashBoard from "components/commons/NotResultDashBoard"


export default function InvoicesTable() {

    const { toasts, toast } = useToast()

    const [isLoading, setIsLoading] = useState(false)
    const [invoices, setInvoices] = useState([])
    const [perPage, setPerpage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [totalItems, setTotalItems] = useState(null);

    function onClipboardSuccess() {
        toast.success('Copiado com sucesso!', { position: 'top-right' })
    }

    async function getMyInvoices(page = 1) {
        setIsLoading(true)
        try {
            const {data} = await financialApi.myInvoices(page)  
                    
            setInvoices(data?.data)
            
            setPerpage(data?.per_page)
            setLastPage(data?.last_page)
            setCurrentPage(data?.current_page)
            setTotalItems(data?.total)
            
        } catch (error) {
           toast.error('Ocorreu um erro inesperado', {
                position: 'top-center'
            }
           ) 
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        
      getMyInvoices()
    }, [])

    const listInvoices = invoices?.map(item => {
        
        const date = new Date(item?.data_emissao);
        return {
            name: <Text name='small'>{item?.event_name}</Text>,
            validationCode: (
                <Row verticalCenter  >
                    {item?.codigo_verificacao? (
                        <>
                            <Text>#{item?.codigo_verificacao}</Text>
                            <InfoTooltip
                                bgColor={colors.night}
                                text='Copiar código de validação'
                                type='none'
                            >
                                <ActionButton onClick={() => copyToClipboard(item?.codigo_verificacao, onClipboardSuccess)}>
                                    <Copy size={18} color={colors.primaryLight} />
                                </ActionButton>
                            </InfoTooltip>
                        </>
                    ) : (
                        <Text name='small'>
                            não possui
                        </Text>

                    )
                    }
                   
                </Row>
            ), 
            date: item?.data_emissao ? <Text name='small'>
                        {date?.toLocaleDateString('pt-BR')} - {date?.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}
                </Text> : <Text name="small">não possui</Text>,
            value: `R$ ${parseFloat(item.valor_servicos).toFixed(2).replace('.', ',')}`,
           
            actions:
            <Row>
                <InfoTooltip
                    bgColor={colors.night}
                    text='Baixar nota fiscal'
                    type='none'
                >   
                    <LinkPage to={item?.url}>
                        <Download size={18} color={colors.primary} />
                    </LinkPage>
                </InfoTooltip>
            </Row>
        };
    })
    

    return (
        <>
            <ToastContainer toasts={toasts} />
            <Text name="subtitle" paddingBottom={'30px'}>Minhas notas fiscais</Text>

            
            {isLoading ? (
                <ContentLoading>
                    <Loader />
                </ContentLoading>
            ) : (
                <Column>
                {
                    invoices?.length > 0 ? (
                       
                        <>
                            <Table 
                                rows={listInvoices}
                                columns={['Evento', 'Cod. validação', 'Data de emissão', 'Valor',  'Ações']}
                            />
                            
                            {lastPage > 1 && (
                                <Paginate
                                    perPage={perPage}
                                    totalItems={totalItems}
                                    lastPage={lastPage}
                                    firstPage={1}
                                    currentPage={currentPage}
                                    marginTop={'20px'}
                                    onPaginate={(number) => getMyInvoices(number)}
                                /> 
                            )}
                        
                        </>
                    ) : (
                        <ContentNotResult>
                           <NotResultDashBoard
                               text={
                                   <Text name='highlight'>
                                       Não foram encontradas notas fiscais.
                                   </Text>
                               }
                               ilustration={'not-found-nfse'}
                               marginTop={'15px'}
                           />
                       </ContentNotResult>
                    )
                }

                   
                        
                       

                </Column>
            )}

        </>
    )
}

const ActionButton = styled.button`
    background-color: transparent;
    padding: 2px 4px;
    border-radius: 4px;
    cursor: pointer;
    transition: all ease-in-out .35;
    margin-left: 4px;

    &:hover {
    transform: scale(1.1);
    }
`;

const ContentLoading = styled(Column)`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ContentNotResult = styled.div`
    & > div {
        flex-direction: column;
    }

    & > div > picture {
        width: 45%;
    }

    & > div > div {
        width: 100%;
    }
`;