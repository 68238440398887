import Text from 'components/_UI/Text';
import DynamicProfileImage from 'components/commons/DynamicProfileImage';
import { useCheckout } from 'context/checkout/Checkout';
import currency from 'helpers/currency';
import React, { useState, useEffect } from 'react';
import { MinusCircle, PlusCircle } from 'react-feather';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

export default function ProductItem({ product, minimal = false, loginModalCount = false, selectedProducts, setSelectedProducts, couponTrigger, onChangeTotalValue }) {
    const [quantity, setQuantity] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [batch, setBatch] = useState(product.batches.find(batch => batch.is_active) || product.batches[0])

    const { checkout, setCheckout } = useCheckout();

    const incrementQuantity = () => {
        if (quantity < product.quantity && quantity < 15) {
            setQuantity(prev => prev + 1);

            const updatedTotalPrice = batch.price * (quantity + 1);
            const updatedTotalOriginalPrice = Number(batch.original_price) * (quantity + 1);
            const priceChange = updatedTotalPrice - totalPrice;
            const totalPriceChange = updatedTotalOriginalPrice - (Number(batch.original_price) * quantity);

            setTotalPrice(updatedTotalPrice);
            onChangeTotalValue(priceChange, totalPriceChange);

            // Adiciona uma nova instância do ticket completo ao selectedTickets
            setSelectedProducts(prev => [
                ...prev,
                product
            ]);
        }
    };

    const decrementQuantity = () => {
        if (quantity > 0) {
            setQuantity(prev => prev - 1);

            const updatedTotalPrice = batch.price * (quantity - 1);
            const updatedTotalOriginalPrice = Number(batch.original_price) * (quantity - 1);
            const priceChange = updatedTotalPrice - totalPrice;
            const totalPriceChange = updatedTotalOriginalPrice - (Number(batch.original_price) * quantity);

            setTotalPrice(updatedTotalPrice);
            onChangeTotalValue(priceChange, totalPriceChange);

            // Remove apenas uma instância do produto correspondente
            setSelectedProducts(prev => {
                const productIndex = prev.findIndex(selectedProduct => selectedProduct.id === product.id);
                if (productIndex > -1) {
                    const updatedProducts = [...prev];
                    updatedProducts.splice(productIndex, 1); // Remove uma instância do produto
                    return updatedProducts;
                }
                return prev;
            });
        }
    };

    // Função para calcular a quantidade de produtos selecionados por id
    const calculateQuantityFromSelectedProducts = (id) => {
        return selectedProducts.filter(product => product.id === id).length;
    };

    useEffect(() => {
        if (loginModalCount) {
            setQuantity(0);
            setTotalPrice(0);
        }

        // Atualiza o checkout com base na quantidade selecionada e nos batches
        setCheckout(prevCheckout => {
            const existingBatches = prevCheckout.batches || [];

            // Remove os batches do produto atual do checkout
            const filteredBatches = existingBatches.filter(batch =>
                !product.batches.some(batchData => batchData.id === batch.id)
            );

            if (quantity === 0) {
                // Se a quantidade for 0, apenas retorne os batches filtrados
                return {
                    ...prevCheckout,
                    batches: filteredBatches
                };
            } else {
                // Adiciona ou atualiza os batches do produto atual
                const updatedBatches = product.batches.map(batchData => {
                    const existingBatch = filteredBatches.find(
                        batch => batch.id === batchData.id
                    );

                    if (existingBatch) {
                        // Atualiza a quantidade do lote existente
                        return { ...existingBatch, quantity: quantity };
                    } else {
                        // Adiciona o novo lote
                        return { id: batchData.id, quantity: quantity };
                    }
                });

                return {
                    ...prevCheckout,
                    batches: [...filteredBatches, ...updatedBatches]
                };
            }
        });
    }, [quantity]);

    useEffect(() => {
        // Atualiza a quantidade conforme o selectedTickets
        const newQuantity = calculateQuantityFromSelectedProducts(product.id);
        setQuantity(newQuantity);

        const updatedTotalPrice = batch.price * newQuantity;
        const updatedTotalOriginalPrice = Number(batch.original_price) * newQuantity;
        const priceChange = updatedTotalPrice - totalPrice;
        const totalPriceChange = updatedTotalOriginalPrice - totalPrice;

        setTotalPrice(updatedTotalPrice);
        onChangeTotalValue(priceChange, totalPriceChange);
    }, [couponTrigger])

    return (
        <ProductItemContainer minimal={minimal}>
            <Row>
                {
                    product?.image ? (
                        <DynamicProfileImage
                            variant='squired'
                            size='60px'
                            img={product?.image}
                            word={product?.name}
                        />
                    ) : (
                        <img
                            src='/assets/images/placeholder-products.png'
                            style={{
                                width: 60,
                                height: 60,
                                borderRadius: 6
                            }}
                        />
                    )
                }

                <Column marginLeft='10px'>
                    <Text type='bold' paddingBottom={0}>
                        {product.name}
                    </Text>

                    {
                        product?.description && (
                            <Text marginTop={'8px'} name='small' paddingBottom={0}>
                                {product?.description}
                            </Text>
                        )
                    }

                    {
                        // não tem desconto
                        Number(batch?.original_price) === Number(batch?.price) &&
                        <Text color={colors.night} marginBottom={'5px'}>
                            {batch?.original_price != 0 ? currency.format(batch?.original_price) : 'Gratuito'}
                        </Text>
                    }
                    {
                        // tem desconto
                        Number(batch?.original_price) !== Number(batch?.price) &&
                        <Row marginBottom={'8px'}>
                            <Text
                                color={colors.nightLight}
                                marginRight={'.5rem'}
                                decoration={'line-through'}
                            >
                                {currency.format(batch?.original_price)}
                            </Text>
                            <Text color={colors.success}>
                                {currency.format(batch?.price)}
                            </Text>
                        </Row>
                    }

                    {
                        product.quantity <= 15 &&
                        <Text
                            name='small'
                            paddingBottom={0}
                            marginTop={'8px'}
                            color={colors.occasionalOrange}
                        >
                            Restam poucas unidades
                        </Text>

                    }
                </Column>
            </Row>

            <QuantityContainer>
                <ToggleValuesContainer
                >
                    <MinusCircle
                        style={{ cursor: 'pointer' }}
                        color={colors.night}
                        size={20}
                        onClick={decrementQuantity}
                    />

                    <Text marginLeft={'6px'} marginRight={'6px'} color={colors.night} paddingBottom={0}>
                        {quantity}
                    </Text>

                    <PlusCircle
                        style={{ cursor: 'pointer', pointerEvents: quantity === 15 ? 'none' : 'visible' }}
                        color={quantity === 15 ? colors.nightLight : colors.night}
                        size={20}
                        onClick={incrementQuantity}
                    />
                </ToggleValuesContainer>

                <Text type='bold' color={colors.night} paddingBottom={0}>
                    R$ {totalPrice.toFixed(2)}
                </Text>
            </QuantityContainer>
        </ProductItemContainer>
    )
}

const ProductItemContainer = styled(Row)`
    margin-top: 20px;
    padding: 14px 0;
    align-items: center;
    border-bottom: 1px solid ${colors.nightLight};

    ${({ stoped }) => stoped && `
        opacity: .5;
    `};

    @media screen and (min-width: ${breakpoints.lg}) {
        border-bottom: none;
        border-top: 1px solid ${colors.nightLight};
        margin-top: 0;
        
        &:first-child {
            border-top: none;
        }
    
        ${({ minimal }) => minimal && `
            border-top: none;
            border-bottom: 1px solid ${colors.nightLight};
            &:last-child {
                border-bottom: 0;
            }
        `}

    }
`;

const QuantityContainer = styled(Column)`
    flex-direction: column;
    width: auto;
    align-items: center;
    width: 100px;
`

const ToggleValuesContainer = styled(Row)`
    margin-bottom: 10px;
    width: auto;
`;
