import React, { useState, useEffect, } from 'react';
import styled from 'styled-components';
import colors from 'theme/colors';
import TimeWarning from './TimeWarning';
import ReceiptForm from './ReceiptForm';
import { Column } from 'styled/alignment/Column';
import { AlertCircle, Check, CheckCircle } from 'react-feather';
import { Row } from 'styled/alignment/Row';
import CreditcardForm from './CreditcardForm';
import Text from 'components/_UI/Text';
import checkoutApi from 'api/requests/checkout';
import customFieldsApi from 'api/requests/customFields';
import { useCheckout } from 'context/checkout/Checkout';
import Loader from 'components/_UI/Loader';
import { FullyCentered } from 'styled/alignment/Center';
import PixForm from './PixForm';
import { useCollapse } from 'context/general/Collapse';
import PixCode from './PixCode';
import Modal from 'components/_UI/Modal';
import Button from 'components/_UI/Button';
import buttonText from 'texts/buttons/buttonText';
import UtilityHeader from 'components/_UI/UtilityHeader';
import CheckoutOrder from '../products/CheckoutOrder';
import { useEvent } from 'context/events/Event';
import { useNavigate, useSearchParams } from 'react-router-dom';
import routeParams from 'theme/routeParams';
import paths from 'routes/paths';
import {
  hasUniqueGuestError,
  uniqueGuestErrorMessage
} from '../commom/paymentFormUtils';


export default function PaymentForm({ tickets, onPixTrigger }) {
  const navigate = useNavigate();
  const { checkout, setCheckout } = useCheckout();
  const { collapse, setCollapse } = useCollapse();
  const [searchParams, setSearchParams] = useSearchParams();
  const { event } = useEvent();

  const [paymentMethod, setPaymentMethod] = useState([
    { name: 'creditcard', label: 'Cartão de crédito', selected: true },
    { name: 'pix', label: 'PIX', selected: false },
  ]);
  const [selected, setSelected] = useState(paymentMethod[0]);
  const [trigger, setTrigger] = useState(0);
  const [receiptFormData, setReceiptFormData] = useState(null);
  const [creditcardData, setCreditcardData] = useState(null);
  const [pixData, setPixData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [creditcardError, setCreditcardError] = useState(null);
  const [successCreditCard, setSuccessCreditCardModal] = useState(false);
  const [time, setTime] = useState(null)
  const [errorRequest, setErrorRequest] = useState(0)
  const [refusedPayment, setRefusedPayment] = useState(false)
  const [customFields, setCustomFields] = useState([])

  function changePaymentMethod(selectedName) {

    const updatedMethods = paymentMethod.map(method =>
      method.name === selectedName
        ? { ...method, selected: true }
        : { ...method, selected: false }
    );

    setPaymentMethod(updatedMethods);

    const selectedMethod = updatedMethods.find(method => method.name === selectedName);
    setSelected(selectedMethod);
  }
  function onTriggerCreditcard(data) {
    setPixData(null);
    setCreditcardData(data);

    setTrigger(previousTrigger => previousTrigger + 1)

  }

  function onTriggerPix(data) {
    // order matters
    setPixData(data);
    setCreditcardData(null);

    setTrigger(previousTrigger => previousTrigger + 1);
  }

  function onReceiptFormCompleted(receiptForm) {
    setReceiptFormData(receiptForm);
    if (selected.name === 'pix') {
      onSubmitPix(pixData, receiptForm);
    }

    if (selected.name === 'creditcard') {
      onSubmitCreditcard(creditcardData, receiptForm);
    }
  }

  async function onSubmitCreditcard(creditcard, receiptForm) {
    if (!creditcard || !receiptForm) {
      return;
    }

    setLoading(true);

    const payload = createCreditcardPayload(creditcard, receiptForm)

    try {
      const { data } = await checkoutApi.checkoutOrder(payload, checkout?.orderId);

      if (data?.status === 'refused') {
        setRefusedPayment(data?.message)
        setErrorRequest(errorRequest + 1)
      }

      if (data?.status === 'approved') {
        setSuccessCreditCardModal(true);
      }
    } catch (error) {
      if (hasUniqueGuestError(error)) {
        setCreditcardError(uniqueGuestErrorMessage)
      } else {
        setCreditcardError('Ocorreu um erro durante o pagamento. Por favor, tente novamente!')
      }
      setErrorRequest(errorRequest + 1)
    } finally {
      setReceiptFormData(false)
      setLoading(false);
    }
  }


  function createCustomerPayload(data) {
    const { firstName, lastName, phone, document, guests } = data;

    if (guests.length) {
      return {
        first_name: firstName,
        last_name: lastName,
        phone,
        document,
        guests
      }
    }

    return {
      first_name: firstName,
      last_name: lastName,
      phone,
      document,
      guests: []
    }
  }

  function createCreditcardPayload(creditcard, receipt) {
    const { creditcardNumber, date, code, holder, document, grecaptcha_response } = creditcard;

    return {
      payment_method: 'credit_card',
      installments: 1,
      grecaptcha_response: grecaptcha_response,
      customer: createCustomerPayload({ ...receipt, document }),
      card: {
        holder,
        number: creditcardNumber,
        expiration_date: date,
        security_code: code,
      }
    }
  }

  function createPixPayload(data, receipt) {
    const { document } = data;

    return {
      payment_method: 'pix',
      customer: createCustomerPayload({ ...receipt, document })
    }
  }

  async function onSubmitPix(pixData, receiptForm) {
    if (!pixData || !receiptForm) {
      return;
    }

    setLoading(true);

    const payload = createPixPayload(pixData, receiptForm)

    setCheckout({
      ...checkout,
      payload
    })

    try {
      const { data } = await checkoutApi.checkoutOrder(payload, checkout?.orderId);

      setCheckout({ ...checkout, pix: data })
      onPixTrigger(() => <PixCode time={time} />)
      setCollapse({
        ...collapse,
        componentToRender: () => (
          <Column>
            <UtilityHeader title={'Pagamento'} onBack={goToOrder} />
            <PixCode time={time} />
          </Column>
        )
      })
    } catch (error) {
      if (hasUniqueGuestError(error)) {
        setCreditcardError(uniqueGuestErrorMessage)
      } else {
        setCreditcardError('Ocorreu um erro durante a compra! Tente novamente.')
      }
      setErrorRequest(errorRequest + 1)
    } finally {
      setReceiptFormData(false)
      setLoading(false);
    }
  }

  function goToWallet() {
    setSuccessCreditCardModal(false);
    navigate(paths.user.wallet);
    // setCollapse({
    //   ...collapse,
    //   arrowPosition: false,
    //   componentToRender: () => <MainWallet />
    // })
  }

  function goToHome() {
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);
      newSearchParams.delete(routeParams.payments.checkout)

      return newSearchParams;
    });
    setSuccessCreditCardModal(false);
    setCollapse({
      ...collapse,
      height: 'min',
      withContent: false,
    })
  }

  function goToOrder() {

    setCollapse({
      ...collapse,
      componentToRender: () => <CheckoutOrder event={event} />
    })
  }

  async function getCustomFields() {
    const response = await customFieldsApi.get(event.slug)
    setCustomFields((prev) => {
      return [
        ...prev,
        ...response?.data?.data
      ]
    })
  }

  useEffect(() => {
    getCustomFields()
  }, [])

  return (
    <>
      <FormContent>
        <TimeWarning setTime={setTime} />

        <ReceiptForm
          trigger={trigger}
          tickets={tickets}
          customFields={customFields}
          onReceiptFormCompleted={onReceiptFormCompleted}
        />

        <Text name='subtitle' textAlign={'center'} type='bold' marginTop={'70px'}>
          Informações de pagamento
        </Text>


        <Text textAlign={'center'} marginTop={'10px'} color={colors.night}>
          Escolha sua forma de pagamento
        </Text>

        {
          loading ? (
            <FullyCentered height='200px'>
              <Loader />
            </FullyCentered>
          ) : (
            <Column>
              <SelectContainer marginTop={'30px'}>
                {paymentMethod.map(el => (
                  <SelectItem
                    selected={el.selected}
                    key={el.name}
                    onClick={() => changePaymentMethod(el.name)}
                  >
                    {el.selected && (
                      <Check size={18} color={colors.neutral} />
                    )}
                    <Text
                      color={el.selected ? colors.neutral : colors.primaryLight}
                      type="bold"
                      name="small"
                      marginLeft={el.selected ? '4px' : 0}
                      paddingBottom={0}
                    >
                      {el.label}
                    </Text>
                  </SelectItem>
                ))}
              </SelectContainer>

              {
                selected.name === 'creditcard' && (
                  <CreditcardForm onTrigger={(e) => onTriggerCreditcard(e)} setErrorRequest={setErrorRequest} errorRequest={errorRequest} />
                )
              }

              {
                selected.name === 'pix' && (
                  <PixForm onTrigger={(e) => onTriggerPix(e)} />
                )

              }

              {creditcardError && (
                <FullyCentered>
                  <Text color={colors.danger} textAlign={'center'}>
                    {creditcardError}
                  </Text>
                </FullyCentered>
              )}
            </Column>
          )
        }

        {
          successCreditCard && (
            <Modal isOpen={successCreditCard} closeButton={false}>
              {/* <Modal isOpen={true} closeButton={false}> */}
              <Row fullyCentralized>
                <CheckCircle color={colors.successDark} />
                <Text name='highlight' paddingBottom={0} marginLeft={'5px'} color={colors.successDark}>
                  Compra realizada com sucesso
                </Text>
              </Row>

              <Text marginTop={'20px'}>Seu pagamento está em processamento.</Text>
              <Text marginTop={'10px'}>Você receberá um e-mail em breve com todas as informações sobre o seu pedido, incluindo detalhes do ingresso/produto adquirido e instruções adicionais.</Text>
              <Text marginTop={'10px'}>Agradecemos pela sua compra e esperamos que você aproveite ao máximo sua experiência!</Text>

              <RowButtons>
                <Button
                  variant='light'
                  marginRight={'12px'}
                  onClick={goToHome}

                >
                  {buttonText.close}
                </Button>

                <Button variant='lightBlue' onClick={goToWallet}>
                  {buttonText.seeWallet}
                </Button>
              </RowButtons>
            </Modal>
          )
        }
      </FormContent>
      <Modal isOpen={refusedPayment} onClose={() => setRefusedPayment(false)}>
        <WrapperModal>
          <Row fullyCentralized>
            <AlertCircle color={colors.danger} />
            <Text paddingBottom={'0'} marginLeft={'5px'}>{refusedPayment}</Text>
          </Row>
          <ContentButtonAgain>
            <Button marginTop={'15px'} variant='featured' onClick={() => setRefusedPayment(false)}>{buttonText.tryAgain}</Button>
          </ContentButtonAgain>
        </WrapperModal>
      </Modal>
    </>
  );
}

const FormContent = styled.form`
  width: 100%;
  /* height: 100%; */
  padding: 10px 30px;
  background-color: ${colors.neutralLight};
`;

const SelectContainer = styled(Row)`
  justify-content: center;
`;

const SelectItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: ${colors.neutralLight};
  width: auto;
  border-radius: 30px;
  padding: 6px 16px;
  border: 1.5px solid ${colors.primaryLight};
  margin-right: 10px;
  position: relative;
  cursor: pointer;

  ${({ selected }) => selected && `
    background-color: ${colors.primaryLight};
  `};
`;


const RowButtons = styled(Row)`
  width: 100%;
  margin-top: 20px;

  & > button {
    box-shadow: unset;
  }
`;

const WrapperModal = styled(Column)`
  padding: 10px;
  justify-content: center;
  align-items: center;
`;

const ContentButtonAgain = styled.div`
  width: 200px;
`;