import Home from 'screens/home/Home';
import paths from './paths';
import UseTerms from 'screens/UseTerms';
import PrivacyPolicie from 'screens/PrivacyPolicie';
// import CheckoutOrder from 'modules/checkout/products/CheckoutOrder';
import EventPage from 'screens/EventPage';


export default [
    {
        exact: true,
        path: paths.home,
        element: <Home />
    },

    {
        exact: true,
        path: paths.terms.policiePrivacy,
        element: <PrivacyPolicie />
    },
    
    {
        exact: true,
        path: paths.terms.useTerms,
        element: <UseTerms />
    },
    
    // {
    //     exact: false,
    //     path: `${paths.checkout.orders}/:slug`,
    //     element: <Checkout />
    // },
    
    {
        exact: false,
        path: `${paths.events.page}/:slug`,
        element: <EventPage />
    },
    // {
    //     exact: false,
    //     path: paths.user.following,
    //     element: <UserFollowing />
    // },
    // {
    //     exact: false,
    //     path: paths.user.followers,
    //     element: <UserFollowers />
    // },
]