import Fieldset from 'components/_UI/Fieldset'
import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text'
import masks from 'helpers/masks'
import React, { useEffect, useState } from 'react'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components'
import Button from 'components/_UI/Button'
import breakpoints from 'theme/breakpoints'
import Switch from 'components/_UI/Switch'
import fonts from 'theme/fonts'
import { useForm } from 'react-hook-form'
import fieldValidations from 'helpers/fieldValidations'
import colors from 'theme/colors'
import { useEvent } from 'context/events/Event'
import promotionalCodesApi from 'api/requests/promotionalCodes'
import { FullyCentered } from 'styled/alignment/Center'
import Loader from 'components/_UI/Loader'
import buttonText from 'texts/buttons/buttonText'

export default function CreatePromotionalCode({ visible, isEdit, toast, onClose, onSuccess }) {
    const { event } = useEvent();

    const [discountTypes, setDiscountTypes] = useState([]);
    const [selectedApliedIn, setSelectedApliedIn] = useState();
    const [selectedDiscountType, setSelectedDiscountType] = useState();
    const [autocompleteSelected, setAutocompleteSelected] = useState(null);
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(false);
    const [discountTypeLoading, setDiscountTypeLoading] = useState(false);
    const [autocomplete, setAutocomplete] = useState(false);
    const [loaderAutocomplete, setLoaderAutocomplete] = useState(false);

    const [error, setError] = useState(false);
    const [codeError, setCodeError] = useState(false)
    const [appliedInError, setAppliedInError] = useState(false)
    const [discountTypeError, setDiscountTypeError] = useState(false)



    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            quantity: '',
            code: '',
            value: ''
        }
    });

    useEffect(() => {
        Promise.all([
            getCouponTypes(),
            getOldersCoupons()
        ])

        if (isEdit && discountTypes) {
            const { value, max_uses, is_active, coupon_type_id, code, discount_type } = isEdit;

            setAutocompleteSelected(isEdit)

            reset({
                quantity: max_uses,
                value,
                code
            })

            fillAppliedIn(discount_type)
            fillDiscountType(coupon_type_id);
            setActive(is_active);
        }
    }, [isEdit, discountTypes])

    const appliedIn = [
        { label: 'Ingresso', value: 'ticket' },
        { label: 'Produtos', value: 'product' },
        { label: 'Todos', value: 'both' },
    ];

    function fillAppliedIn(item) {
        const selected = appliedIn.find(el => el.value === item);
        setSelectedApliedIn(selected);
    }

    function fillDiscountType(item) {
        if (discountTypes) {
            const selected = discountTypes.find(el => el.id === item);
            setSelectedDiscountType(selected);
        }
    }

    async function getCouponTypes() {
        if (discountTypes.length) {
            return
        }

        setDiscountTypeLoading(true);

        try {
            const { data } = await promotionalCodesApi.getTypes(event?.slug);

            const mappedDiscountTypes = data.map(item => ({
                label: renderDiscountTypeText(item?.symbol),
                value: item.symbol,
                id: item.id
            }));

            setDiscountTypes(mappedDiscountTypes);
        } catch (error) {
            console.log('error: ', error);
        } finally {
            setDiscountTypeLoading(false);
        }
    }

    async function getOldersCoupons() {
        setLoaderAutocomplete(true);
        try {
            const { data } = await promotionalCodesApi.getDistincts();

            setAutocomplete(Object.values(data));
        } catch (error) {
            console.log('error: ', error);
        } finally {
            setLoaderAutocomplete(false);
        }
    }

    function renderDiscountTypeText(symbol) {
        if (symbol == '%') {
            return 'Porcentagem (%)'
        }

        if (symbol == 'R$') {
            return 'Desconto fixo (R$)'
        }
    }

    async function handlePayload({ code, quantity, value }) {
        const payload = {
            code,
            value,
            coupon_type_id: selectedDiscountType?.id,
            event_id: event?.id,
            discount_type: selectedApliedIn?.value,
            max_uses: quantity,
            is_active: active
        };

        setLoading(true);

        !isEdit ? createCode(payload) : updateCode(payload);

    }

    function selectsError(payload) {
        if (payload?.code && payload?.discount_type && payload?.coupon_type_id) {
            setCodeError(false);
            setAppliedInError(false);
            setDiscountTypeError(false);

            return false
        }

        if (!payload?.code) {
            setCodeError(true);
        }

        if (!payload?.discount_type) {
            setAppliedInError(true);
        }

        if (!payload?.coupon_type_id) {
            setDiscountTypeError(true);
        }

        return true
    }

    async function createCode(payload) {
        setError(false);

        if (selectsError(payload)) {
            setLoading(false);
            return
        }

        try {
            const { data } = await promotionalCodesApi.create(payload);
            onSuccess(data);
            toast.success('Código promocional criado com sucesso!', {
                position: 'top-center'
            })
        } catch ({ response }) {
            if (response.status == '500') {
                setError(response?.data?.message);
            }
        } finally {
            setLoading(false);
        }
    }

    function onSelectAutocomplete(item) {
        const { is_active, coupon_type_id, code, discount_type, max_uses, value } = item;

        if (typeof (item) == 'string') {
            setAutocompleteSelected({ code: item });
            reset({ code: item })
            return
        }

        setAutocompleteSelected(item);

        reset({
            quantity: max_uses,
            code,
            value
        })

        fillAppliedIn(discount_type)
        fillDiscountType(coupon_type_id);
        setActive(is_active);
    }

    async function updateCode(payload) {
        setError(false);

        if (selectsError(payload)) {
            setLoading(false);
            return
        }

        try {
            const { data } = await promotionalCodesApi.update(isEdit?.id, payload);
            onSuccess(data);
            toast.success('Código promocional atualizado com sucesso!', {
                position: 'top-center'
            })
        } catch ({ response }) {
            if (response.status == '500') {
                setError(response?.data?.message);
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal isOpen={visible} onClose={onClose} size='medium'>
            <Text name={'highlight'}>Código promocional</Text>

            <form
                onSubmit={handleSubmit(handlePayload)}
                style={{ width: 'auto', marginTop: '15px' }}
            >
                <Column width='auto'>
                    {
                        loaderAutocomplete ? (
                            <FullyCentered>
                                <Loader />
                            </FullyCentered>
                        ) : (
                            autocomplete.length && !isEdit ? (
                                <Column>
                                    <Text textAlign={'left'} name='small' color={colors.nightDark} type='bold'>Código</Text>
                                    <CustomSelect>
                                        <CreatableSelect
                                            isClearable={true}
                                            formatCreateLabel={(inputValue) => inputValue}
                                            placeholder='Seu código'
                                            value={{ label: autocompleteSelected?.code, value: autocompleteSelected }}
                                            options={autocomplete.map(el => ({ label: el.code, value: el }))}
                                            onChange={e => onSelectAutocomplete(e?.value || e)}
                                        />
                                    </CustomSelect>
                                    {codeError && (
                                        <Column>
                                            <Text color={colors.danger} name='small'> O campo código é obrigatorio </ Text>
                                        </Column>
                                    )}
                                </Column>

                            ) : (
                                <Column>
                                    <Fieldset
                                        label='Código'
                                        layout='squired'
                                        placeholder='seu código'
                                        register={register}
                                        name='code'
                                        validations={fieldValidations.name('código')}
                                    />
                                    {errors.code?.message && (
                                        <Column>
                                            <Text color={colors.danger} name='small'> {errors.code?.message} </ Text>
                                        </Column>
                                    )}
                                </Column>
                            )
                        )
                    }

                    <StyledContainerFields>
                        <Column>
                            <Fieldset
                                label='Quantidade'
                                layout='squired'
                                placeholder='100'
                                register={register}
                                name='quantity'
                                validations={fieldValidations.number('quantidade')}
                            />
                            {errors.quantity?.message && (
                                <Column>
                                    <Text color={colors.danger} name='small'> {errors?.quantity?.message} </ Text>
                                </Column>
                            )}
                        </Column>

                        <ContentTypeAplication>
                            <Text name='small' color={colors.nightDark} type='bold'>Tipo de aplicação</Text>
                            <CustomSelect>
                                <Select
                                    placeholder='Aplícavel em'
                                    value={selectedApliedIn}
                                    options={appliedIn}
                                    onChange={setSelectedApliedIn}
                                />
                            </CustomSelect>
                            {appliedInError && (
                                <Column>
                                    <Text color={colors.danger} name='small'> O campo tipo de aplicação é obrigatorio</ Text>
                                </Column>
                            )}
                        </ContentTypeAplication>
                    </StyledContainerFields>

                    <StyledContainerFields discount>
                        <Column style={{ textAlign: 'left', marginRight: '12px' }}>
                            <Text name='small' color={colors.nightDark} type='bold'>Tipo de desconto</Text>
                            <CustomSelect>
                                <Select
                                    placeholder='Tipo de desconto'
                                    value={selectedDiscountType}
                                    options={discountTypes}
                                    isDisabled={discountTypeLoading}
                                    onChange={e => setSelectedDiscountType(e)}
                                />
                            </CustomSelect>
                            {discountTypeError && (
                                <Column>
                                    <Text color={colors.danger} name='small'> O campo tipo de desconto é obrigatorio</ Text>
                                </Column>
                            )}
                        </Column>

                        <Column>
                            <Fieldset
                                label='Valor'
                                layout='squired'
                                placeholder='10'
                                register={register}
                                name='value'
                                validations={fieldValidations.number('valor')}
                            />
                            {errors.value?.message && (
                                <Column>
                                    <Text color={colors.danger} name='small'> {errors.value?.message} </ Text>
                                </Column>
                            )}
                        </Column>
                    </StyledContainerFields>

                    <Column>
                        <Switch
                            label='Código ativo'
                            labelSize={fonts.sizes.small}
                            marginTop={'10px'}
                            onChange={(e) => setActive(e)}
                            checked={active}
                        />
                    </Column>
                </Column>

                {error && (
                    <Column>
                        <Text color={colors.danger}> {error} </Text>
                    </Column>
                )}

                {
                    loading ? (
                        <FullyCentered height='40px'>
                            <Loader />
                        </FullyCentered>
                    ) : (
                        <ButtonsContainer>
                            <Button variant={'light'} category={'roundedBorded'} onClick={onClose}>
                                {buttonText.cancel}
                            </Button>

                            <Button category={'roundedBorded'} type={'submit'}>
                                {!isEdit ? buttonText.create : buttonText.update} código
                            </Button>
                        </ButtonsContainer>
                    )
                }

            </form>
        </Modal>
    )
}


const ButtonsContainer = styled(Column)`
        margin-top: 20px;
    
        @media screen and (min-width: ${breakpoints.md}) {
        flex-direction: row;
       
        button:nth-child(2) {
            margin-left: 10px;
        }
    }
`;

const CustomSelect = styled.div`
    width: 100%;
    
    
    ${({ marginLeft }) => marginLeft && `
        margin-left: 12px;
    `};
    

    & > div > div {
        text-align: left;
        font-size: ${fonts.sizes.small};
    }
`;

const ContentTypeAplication = styled(Column)`
    text-align: left;
    margin-top: 10px;
    padding-bottom: 8px;
    @media screen and (min-width: 375px) {
        margin-left: 12px;
        margin-top: 0;
    }
`

const StyledContainerFields = styled(Column)`
    text-align: left;
    margin-top: 10px;
    @media screen and (min-width: 375px) {
        display: flex;
        flex-direction: row;
    }

    ${({ discount }) => discount && `
        & > div:first-child {
            padding-bottom: 8px;
            @media screen and (min-width: 375px) {
                padding-bottom: 0;
            }
        }

        &  > div:nth-child(2) {
            margin-top: 10px;
            @media screen and (min-width: 375px) {
                margin-top: 0;
            }
        }
    `}
`