import React, { useEffect, useState } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom';
import routeParams from 'theme/routeParams';
import StatisticsCards from './statistics/StatisticsCards'
import { Column } from 'styled/alignment/Column'
import WithdrawList from './withdraw/WithdrawList'
import DeclinedTransactions from './declined-transactions/DeclinedTransactions'
import Divider from 'components/_UI/Divider'
import { useUser } from 'context/user/User'
import NotResultDashBoard from 'components/commons/NotResultDashBoard'
import paths from 'routes/paths'
import Loader from 'components/_UI/Loader'
import styled from 'styled-components'
import Text from 'components/_UI/Text'
import financialApi from 'api/requests/financial'
import DashCollapse from 'components/_UI/DashCollapse'
import BankForm from './bank-form/BankForm';
import InvoicesTable from './invoices/InvoicesTable';
import ToastContainer from 'components/_UI/ToastsContainer';
import useToast from 'hooks/useToast';
import establishmentsApi from 'api/requests/establishments';
import { useMyEstablishment } from 'context/establishments/MyEstablishment';
import { useWithdraw } from 'context/financial/Withdraw';
import ReceiptsCalendar from './calendar/ReceiptsCalendar';


export default function Financial() {
    const { myEstablishment, setMyEstablishment } = useMyEstablishment();
    const { withdraw, setWithdraw } = useWithdraw();
    const { user } = useUser()
    const { toasts, toast } = useToast()
    const [searchParams, setSearchParams] = useSearchParams();
    const { search } = useLocation();

    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState(null)
    const [paginate, setPaginate] = useState(null)
    const [historyWithdraw, setHistoryWithdraw] = useState(null)
    const [notWithdraw, setNotWithdraw] = useState(false)
    const [showCollapse, setShowCollapse] = useState(false)
    const [showBankForm, setShowBankForm] = useState(false)
    const [showInvoices, setShowInvoices] = useState(false)
    // const [showCalendar, setShowCalendar] = useState(false)

    const closeCollapse = () => {
        setSearchParams((prevSearchParams) => {
            const newSearchParams = new URLSearchParams(prevSearchParams);

            Object.values(routeParams.financial).map(el => {
                newSearchParams.delete(el)
            })

            return newSearchParams;
        });
    }

    function loadData() {
        Promise.all([
            getFinancial(),
            getEstablishments(),
            getWithdrawData(),
        ]).then(() => {
            setIsLoading(false)
        })
    }

    async function getFinancial() {
        try {
            const { data } = await financialApi.get()
            setData(data)
            setWithdraw({
                ...withdraw,
                availableValue: data?.balance_available
            })
        } catch (error) {

        }
    }

    async function getWithdrawData(number = 1) {
        try {
            const { data } = await financialApi.getWithdrawData({ page: number })
            setPaginate(data)
            setHistoryWithdraw({
                ...historyWithdraw,
                data: data
            })


            if (data?.data?.length == 0) {
                setNotWithdraw(true)

            }
        } catch (error) {

        }
    }

    async function getEstablishments() {
        if (myEstablishment?.length) {
            return
        }

        try {
            const { data } = await establishmentsApi.my()
            setMyEstablishment(data?.data)
            validateBankFormCollapse(data?.data)
        } catch (error) {

        }
    }

    function validateBankFormCollapse(establishments) {
        if (showBankForm && !establishments?.length) {
            setShowBankForm(false)
            closeCollapse()
        }
    }

    useEffect(() => {
        const bankParam = searchParams.get(routeParams.financial.bank)
        const invoicesParam = searchParams.get(routeParams.financial.invoices)
        // const calendarParams = searchParams.get(routeParams.financial.calendar)

        setShowBankForm(!!bankParam);
        setShowInvoices(!!invoicesParam);
        // setShowCalendar(!!calendarParams)
        // setShowCollapse(bankParam || invoicesParam || calendarParams)
        setShowCollapse(bankParam || invoicesParam)
    }, [search]);

    useEffect(() => {
        setIsLoading(true)
        loadData();

    }, [withdraw?.success]);

    return (
        <>
            <ToastContainer toasts={toasts} />
            <Column>
                {
                    !isLoading ? (

                        !user?.establishments?.length && user?.role?.id == import.meta.env.VITE_ESTABLISHMENT_ROLE_ID ? (
                            <NotResultDashBoard
                                ilustration={'profile'}
                                text={'Notei que você ainda não possuí seu estabelecimento. Que tal criar seu estabelecimento?'}
                                textButton={'Criar meu estabelecimento'}
                                path={`${paths.dashboard.home}?create=1`}
                            />
                        ) : (
                            <>
                                <StatisticsCards data={data} />
                                {
                                    historyWithdraw?.data?.data?.length > 0 ? (
                                        <WithdrawList
                                            data={historyWithdraw}
                                            currentPage={paginate?.current_page}
                                            totalItems={paginate?.total}
                                            lastPage={paginate?.last_page}
                                            firstPage={paginate?.first_page}
                                            perPage={paginate?.per_page}
                                            onPaginate={(number) => getWithdrawData(number)}
                                        />
                                    ) : (
                                        notWithdraw &&
                                        <NotResultDashBoard
                                            text={
                                                <>
                                                    <Text name='subtitle'>Históricos de saques</Text>
                                                    <Text>Parece que você ainda não efetuou nenhum saque.</Text>
                                                    <Text marginTop={'5px'}>Verifique se possui algum valor a receber disponivel e faça o primeiro repasse para sua conta bancaria.</Text>
                                                </>
                                            }
                                            ilustration={'withdraw'}
                                            marginTop={'15px'}
                                        />
                                    )
                                }

                                <ReceiptsCalendar />

                                {/* <Divider marginTop={'40px'} marginBottom={'0'} opacity={.4} /> */}

                                {/* <DeclinedTransactions /> */}
                            </>
                        )
                    ) : (
                        <Loader />
                    )
                }
            </Column>
            <DashCollapse visible={showCollapse} onClose={() => closeCollapse()}>
                {
                    showBankForm && <BankForm visible={showBankForm} closeCollapse={closeCollapse} toast={toast}></BankForm>
                }
                {
                    showInvoices && <InvoicesTable closeCollapse={closeCollapse} toast={toast}></InvoicesTable>
                }
                {/* {
                    showCalendar && <ReceiptsCalendar />
                } */}
            </DashCollapse>
        </>
    )
}

