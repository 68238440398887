import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import checkoutApi from 'api/requests/checkout';
import { FullyCentered } from 'styled/alignment/Center';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import Button from 'components/_UI/Button';
import { Column } from 'styled/alignment/Column';
import colors from 'theme/colors';
import { Row } from 'styled/alignment/Row';
import { useCheckout } from 'context/checkout/Checkout';
import TicketItem from './TicketItem';
import breakpoints from 'theme/breakpoints';
import PaymentForm from '../payment/Payment';
import FreePaymentForm from '../payment/FreePayment';
import ProductItem from './ProductItem';
import buttonText from 'texts/buttons/buttonText';
import CouponModal from './CouponModal';
import currency from 'helpers/currency';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import Modal from 'components/_UI/Modal';
import CheckoutCollapse from 'modules/events/page/collapse/CheckoutCollapse';
import routeParams from 'theme/routeParams';
import auth from 'middlewares/auth';
import paths from 'routes/paths';


const TICKET_TYPE = 'ticket';
const PRODUCT_TYPE = 'product';

export default function CheckoutOrderMinimal({ event }) {
    const location = useLocation();
    const navigate = useNavigate();

    const { checkout, setCheckout } = useCheckout();
    const [searchParams, setSearchParams] = useSearchParams();
    // const { event, setEvent } = useEvent();
    // const { collapse, setCollapse } = useCollapse();

    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [originalPriceTotal, setOriginalPriceTotal] = useState(0)
    const [error, setError] = useState(false)
    const [getTicketsError, setGetTicketsError] = useState('')
    const [isCouponModalVisible, setIsCouponModalVisible] = useState(false)
    const [couponCode, setCouponCode] = useState('')
    const [couponId, setCouponId] = useState('')
    const [selectedTickets, setSelectedTickets] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])
    const [couponTrigger, setCouponTrigger] = useState(0)
    const [errorMinOrder, setErrorMinOrder] = useState(false)
    const [createOrderError, setCreateOrderError] = useState('')
    const [componentToRender, setComponentToRender] = useState(false)
    const [goToLoginModal, setGoToLoginModal] = useState(false)
    const [loginModalCount, setLoginModalCount] = useState(0);
    const [isPix, setIsPix] = useState(false);
    const [pixToRender, setPixToRender] = useState(false);
    // const [event, setEvent] = useState(null)

    useEffect(() => {
        setTotalPrice(0);
        setOriginalPriceTotal(0);
        setCouponCode('');
        setCheckout({});
        setSelectedProducts([]);
        setSelectedTickets([]);
        getOrder();
    }, [event]);


    // async function getEvent() {
    //     setLoading(true);
    //     try {
    //         const { data } = await eventsApi.getBySlug('liga-de-beach-tenis');
    //         // setEvent(data?.data);
    //     } catch (error) {
    //         console.error(error);
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    async function getOrder(coupon, onSuccess) {
        setLoading(true);

        try {
            const { data } = await checkoutApi.getTickets(event?.slug, coupon ? { params: { coupon } } : {});
            setTickets(data?.items?.filter(el => el.type === TICKET_TYPE));
            setProducts(data?.items?.filter(el => el.type === PRODUCT_TYPE));
            setCouponId(data?.coupon_id);

            if (onSuccess) {
                onSuccess();
            }

        } catch (error) {
            setGetTicketsError('Código promocional inválido.');
        } finally {
            setLoading(false);
        }
    }


    const handleUpdateTotal = (priceChange, originalPriceChange) => {
        if (!auth.getToken()) {
            setLoginModalCount(loginModalCount + 1)

            if (loginModalCount > 0) {
                setGoToLoginModal(true);
                return
            }
        }

        // setLoginModalCount(0)
        setTotalPrice((prevTotal) => (parseFloat(prevTotal) + priceChange));
        setOriginalPriceTotal((prevTotal) => (parseFloat(prevTotal) + originalPriceChange));
    }

    async function buyTicket() {
        if (!auth.getToken()) {
            setGoToLoginModal(true);
            return;
        }


        const payload = {
            ...checkout,
            event_id: event.id,
        }

        if (couponId) {
            payload.coupon_id = couponId
        }

        if (totalPrice < 5 && totalPrice !== 0) {
            setErrorMinOrder('Valor mínimo do pedido é de 5 reais!')
            return
        }

        if (!payload?.batches?.length) {
            setError(true)
            return
        }

        setLoading(true);

        try {
            const { data } = await checkoutApi.createOrder(payload);

            setTotalPrice(0);
            setOriginalPriceTotal(0);

            setCheckout({ ...checkout, orderId: data.id });
            setSearchParams({ 'checkout': 1 })

            if (Number(totalPrice) === 0) {
                setComponentToRender(() => (
                    <StyledCheckoutOrder>
                        {/* <UtilityHeader title={'Pagamento'} onBack={goBack} /> */}
                        <FreePaymentForm
                            tickets={selectedTickets}
                            event={event}
                        />
                    </StyledCheckoutOrder>
                ))
            } else {
                setComponentToRender(() => (
                    <StyledCheckoutOrder>
                        {/* <UtilityHeader title={'Pagamento'} onBack={goBack} /> */}
                        <PaymentForm
                            tickets={selectedTickets}
                            onPixTrigger={(pixComponent) => renderPix(pixComponent)}
                        />
                    </StyledCheckoutOrder>
                ))
            }

        } catch ({ response }) {
            if (response?.data?.key === 'expired_batch') {
                handleExpiredBatchError()
            }

            if (response?.data?.message === 'Tickets not available') {
                handleBatchSoldOutError()
            }
        } finally {
            setLoading(false);
        }
    }

    // function goBack() {
    //     setCollapse({
    //         withHideCity: false,
    //         arrowPosition: false,
    //         ...collapse,
    //         componentToRender: () => (
    //             <StyledCheckoutOrder>
    //                 <UtilityHeader title={event?.name} onBack={goToDetail} />
    //                 <CheckoutOrder event={event} />
    //             </StyledCheckoutOrder>
    //         )
    //     });
    // }

    // function goToDetail() {
    //     setCollapse({
    //         ...collapse,
    //         withHideCity: false,
    //         arrowPosition: false,
    //         componentToRender: () => <EventDetail data={event} />
    //     });
    // }

    function renderPix(PixComponent) {
        setIsPix(true);
        setPixToRender(() => (<PixComponent />))
    }

    function toggleCouponModal() {
        if (!auth.getToken()) {
            setLoginModalCount(loginModalCount + 1)

            if (loginModalCount > 0) {
                setGoToLoginModal(true);
                return
            }
        }

        setIsCouponModalVisible(!isCouponModalVisible)
        setGetTicketsError('');
    }

    function getOrderWithCoupon(coupon) {
        const onSuccess = () => {
            setGetTicketsError('')
            setCouponCode(coupon)
            toggleCouponModal()
            setTotalPrice(0)
            setOriginalPriceTotal(0)
            setCheckout({})
        }

        getOrder(coupon, onSuccess)
    }

    function handleExpiredBatchError() {
        setCreateOrderError('Algum de seus ingressos se esgotaram. Recarregando a página.')

        setTimeout(() => {
            setCreateOrderError('')
            setCheckout({})
            getOrder()
        }, 1500)
    }

    function handleBatchSoldOutError() {
        setCreateOrderError('Algum de seus ingressos se esgotaram. Recarregando a página.')

        setTimeout(() => {
            setCreateOrderError('')
            setCheckout({})
            getOrder()
        }, 1500)
    }

    function onCloseCollapse() {
        setTotalPrice(0);
        setOriginalPriceTotal(0);
        setCouponCode('');
        setCheckout({});
        setSelectedProducts([]);
        setSelectedTickets([]);
        setComponentToRender(false);
        setIsPix(false);
        setSearchParams((prevSearchParams) => {
            const newSearchParams = new URLSearchParams(prevSearchParams);
            newSearchParams.delete(routeParams.payments.checkout)

            return newSearchParams;
        });
        getOrder();
    }

    return (
        <>
            {
                loading
                    ? (
                        <FullyCentered height='80%'>
                            <Loader />
                        </FullyCentered>
                    )
                    : (
                        <>
                            <Container>
                                {/* <Text
                                    name='highlight'
                                    color={colors.occasionalPurple}
                                    type='bold'
                                    textAlign={'center'}
                                    marginTop={'14px'}
                                >
                                    {event?.name}
                                </Text> */}

                                <TicketsContainer
                                // isProducts={products.length > 0}
                                >

                                    {
                                        tickets.length > 0 && (
                                            <>
                                                <Text
                                                    type='bold'
                                                    textAlign={'center'}
                                                    marginTop={'25px'}
                                                    color={colors.nightDark}
                                                >
                                                    Ingressos
                                                </Text>

                                                <Tickets>
                                                    {
                                                        tickets.map(el => (
                                                            el?.batches.map(item => (
                                                                <TicketItem
                                                                    minimal
                                                                    loginModalCount={loginModalCount}
                                                                    key={item.id}
                                                                    ticket={item}
                                                                    couponTrigger={couponTrigger}
                                                                    selectedTickets={selectedTickets}
                                                                    setSelectedTickets={setSelectedTickets}
                                                                    onChangeTotalValue={handleUpdateTotal}
                                                                />
                                                            ))
                                                        ))
                                                    }
                                                </Tickets>
                                            </>
                                        )
                                    }
                                </TicketsContainer>

                                <CouponButton>
                                    <Button
                                        onClick={toggleCouponModal}
                                        width={'300px'}
                                        variant='promoted'
                                    >
                                        {buttonText.addCoupon}
                                    </Button>
                                    {
                                        couponCode && <Text type="bold">Cupom aplicado: {couponCode}</Text>
                                    }
                                </CouponButton>

                                <TicketsContainer isTypeProduct>
                                    {
                                        products.length > 0 && (
                                            <>
                                                <Text type='bold' textAlign={'center'} marginTop={0}>
                                                    Produtos
                                                </Text>

                                                <Text textAlign={'center'} color={colors.night}>
                                                    Compre agora e retire no evento.
                                                </Text>
                                                <Tickets>
                                                    {
                                                        products.map((el, index) => (
                                                            <ProductItem
                                                                minimal
                                                                loginModalCount={loginModalCount}
                                                                key={index}
                                                                selectedProducts={selectedProducts}
                                                                setSelectedProducts={setSelectedProducts}
                                                                couponTrigger={couponTrigger}
                                                                product={el}
                                                                onChangeTotalValue={handleUpdateTotal}
                                                            />
                                                        ))
                                                    }
                                                </Tickets>
                                            </>
                                        )}
                                </TicketsContainer>

                                <Footer>
                                    {
                                        loading ? (
                                            <FullyCentered>
                                                <Loader />
                                            </FullyCentered>
                                        ) : (
                                            <>
                                                {
                                                    originalPriceTotal === totalPrice &&
                                                    <Text type='bold' color={colors.night} name='small'>
                                                        Total: R$ {totalPrice.toFixed(2)}
                                                    </Text>
                                                }

                                                {
                                                    originalPriceTotal !== totalPrice &&
                                                    <Row fullyCentralized >
                                                        <Text
                                                            type='bold'
                                                            color={colors.night}
                                                            name='small'
                                                            marginRight={'4px'}
                                                        >
                                                            Total
                                                        </Text>
                                                        <Text
                                                            type='bold'
                                                            color={colors.night}
                                                            name='small'
                                                            decoration={'line-through'}
                                                            marginRight={'.5rem'}
                                                        >
                                                            R$ {currency.format(originalPriceTotal)}
                                                        </Text>
                                                        <Text
                                                            type='bold'
                                                            color={colors.success}
                                                            name='small'
                                                        >
                                                            R$ {totalPrice.toFixed(2)}
                                                        </Text>
                                                    </Row>
                                                }

                                                {errorMinOrder && (
                                                    <FullyCentered>
                                                        <Text color={colors.danger} name='small'> {errorMinOrder}</Text>
                                                    </FullyCentered>
                                                )}

                                                <Button
                                                    size='medium'
                                                    variant={checkout?.batches?.length == 0 ? 'disabled' : 'featured'}
                                                    onClick={() => buyTicket()}
                                                >
                                                    Comprar 
                                                </Button>


                                                {createOrderError && (
                                                    <FullyCentered>
                                                        <Text color={colors.danger} name='small'>{ createOrderError }</Text>
                                                    </FullyCentered>
                                                )}
                                            </>
                                        )
                                    }
                                </Footer>
                            </Container>


                            <CheckoutCollapse
                                visible={searchParams.get(routeParams.payments.checkout) == 1 && !!componentToRender}
                                onClose={onCloseCollapse}
                            >
                                {!isPix ? componentToRender : pixToRender}
                            </CheckoutCollapse>
                            {/* {
                                componentToRender && (
                                )
                            } */}

                            <CouponModal
                                code={couponCode}
                                isOpen={isCouponModalVisible}
                                onClose={toggleCouponModal}
                                onSubmit={getOrderWithCoupon}
                                error={getTicketsError}
                            />
                        </>
                    )

            }

            {
                goToLoginModal && (
                    <Modal isOpen={goToLoginModal} onClose={() => setGoToLoginModal(false)} >
                        <Column>
                            <Text type='bold' name='subtitle' textAlign={'center'}>
                                Você não está logado.
                            </Text>

                            <Text type='bold' color={colors.night} textAlign={'center'} marginTop={'14px'}>
                                Para realizar a compra, você precisa estar logado em sua conta.
                            </Text>

                            <Button
                                marginTop={'14px'}
                                onClick={() => navigate({
                                    pathname: paths.login,
                                    search: new URLSearchParams({
                                        redirectTo: location.pathname
                                    }).toString()
                                })}
                            >
                                Fazer login
                            </Button>
                        </Column>
                    </Modal>
                )
            }

            <Modal isOpen={error} onClose={() => setError(false)}>
                <Column verticalCenter>
                    <Text color={colors.danger}>Você deve selecionar pelo menos 1 item!</Text>
                    <ContentButton>
                        <Button onClick={() => setError(false)} marginTop={'15px'}>
                            {buttonText.understood}
                        </Button>
                    </ContentButton>
                </Column>
            </Modal>

        </>
    )
}

const StyledCheckoutOrder = styled(Column)`
    height: 100vh;
`;


const Container = styled.div`
    width: 100%;
    height: auto;
    /* padding-bottom: 300px; */
    /* background-color: #fefefe; */
    background-color: ${colors.neutralLight};    
    border-radius: 8px;
    border: 1px solid #eee;
    box-shadow: 0px 1px 6px #ccc;
    padding: 0 12px;
    

    @media screen and (min-width: ${breakpoints.lg}) {
        width: 85%;
        padding: 0;
    }
`;

const Footer = styled(FullyCentered)`
    flex-direction: column;
    width: 100%;
    padding: 10px 30px;
    background: ${colors.neutralLight};
    box-shadow: 0 -2px 8px #ddd;
    padding-top: 10px;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        padding-top: auto;
        padding-bottom: 10px;
        margin-bottom: 0;

        & >  p {
            padding: 16px 0;
        }

        & > button { 
            width: 80%;
            margin-bottom: 6px;
        }
    }
`;

const CouponButton = styled(FullyCentered)`
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 10px;
`;

const TicketsContainer = styled(FullyCentered)`
    flex-direction: column;
    width: 100%;
    padding-bottom: 50px;

    /* ${({ isProducts }) => !isProducts && `
        padding-bottom: 50px;
    `}

    ${({ isProducts }) => isProducts && `
        padding-bottom: 50px;
    `} */
    
    /* ${({ isTypeProduct }) => isTypeProduct && `
        padding-bottom: 150px;
    `} */
    
`;

const Tickets = styled(Column)`
    width: 100%;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        width: 100%;
        background-color: ${colors.neutralLight};
        /* border-radius: 8px; */
        /* border: 1px solid #eee; */
        /* box-shadow: 0px 1px 4px #eee; */
        padding: 0 20px;
    }
`;


const ContentButton = styled(Row)`
    width: 270px;
`