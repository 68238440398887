const uniqueGuestErrorMessage = 'O email deve ser único para cada participante.';
function hasUniqueGuestError (error) {
    const guestError = error?.response?.data?.errors?.['customer.guests']
    if (guestError && Array.isArray(guestError)) {
      if (guestError[0] === 'The guest email is unique.') {
        return true
      }
    }
    
    return false
}

export {
    uniqueGuestErrorMessage,
    hasUniqueGuestError
}