import Fieldset from 'components/_UI/Fieldset';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import { useEvent } from 'context/events/Event';
import masks from 'helpers/masks';
import regex from 'helpers/regex';
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import addressApi from 'api/requests/address';
import { HorizontalCenter } from 'styled/alignment/Center';
import colors from 'theme/colors';
import fieldMessages from 'texts/messages/field';
import Switch from 'components/_UI/Switch';

export default function AddressInfo({ trigger, edit }) {
    const { event, setEvent } = useEvent();

    const [address, setAddress] = useState(null);
    const [addressLoading, setAddressLoading] = useState(false);
    const [cepFilled, setCepFilled] = useState(false);
    const [cepValue, setCepValue] = useState(edit ? edit?.address?.street?.postalcode : '');
    const [numberValue, setNumberValue] = useState('');
    const [nameValue, setNameValue] = useState('');
    const [hideAddress, setHideAddress] = useState(false);
    const [complementValue, setComplementValue] = useState('');
    const [withoutNumber, setWithoutNumber] = useState(false);
    const [errors, setErrors] = useState(false);

    useEffect(() => {
        if (edit && !trigger) {
            setCepFilled(true);
            setHideAddress(!!edit?.hide_address)

            if (edit?.address?.number) {
                setAddress({ ...edit?.address })
                setNumberValue(edit?.address?.number)
                showAddressFields(edit?.address?.street?.postalcode, true)
            }

            if (edit?.address?.complement) {
                setComplementValue(edit?.address?.complement)
                setAddress(edit?.address)
                setEvent({
                    ...edit,
                    address: {
                        ...edit.address,
                        complement: edit.address.complement
                    }
                })
            }

            if (edit?.address?.name) {
                setWithoutNumber(true)
                setNameValue(edit?.address?.name)
                setAddress(edit?.address)
                setEvent({
                    ...edit,
                    address: {
                        ...edit?.address,
                        name: edit?.address?.name
                    }
                })
                showAddressFields(edit?.address?.street?.postalcode)
            }

            return
        }

        if (trigger && !cepFilled) {
            setErrors({ ...errors, cep: 'CEP inválido' })
            return
        }

        if (
            trigger
            && cepFilled
            && event?.address?.number
            && event?.address?.name
        ) {
            setErrors({ ...errors, number: fieldMessages.required('número') })
        }


    }, [trigger])

    async function getAddress(payload, firstTimeEdit) {
        if (firstTimeEdit) {
            return
        }

        setAddressLoading(true)
        try {
            const { data } = await addressApi.getByPostalCode(payload);

            setAddress(data)
            setEvent({ ...event, address: data })
        } catch (error) {
            setErrors({ ...errors, cep: 'CEP inválido' })
            setAddress(false)
        } finally {
            setAddressLoading(false)
        }
    }

    function validateCEP(cep) {
        return regex.cep.test(cep);
    }

    function showAddressFields(value, firstTimeEdit = false) {
        setCepValue(value)

        if (!validateCEP(value)) {
            if (trigger) {
                setErrors({ ...errors, cep: 'CEP inválido' })
            }
            return
        }

        const postalCodeNumbers = value.replace(regex.onlyNumber, '');

        if (postalCodeNumbers.length === 9) {
            setErrors({ ...errors, cep: false })
            setCepFilled(true);
            getAddress(postalCodeNumbers, firstTimeEdit)
        }
    };

    function onChangeNumber(e) {
        setNumberValue(e)
        setNameValue(null)
        setErrors({ ...errors, number: null });

        if (withoutNumber && !address?.number) {
            setEvent({
                ...event,
                address: {
                    postalcode: cepValue,
                    name: address?.street?.name
                },
            });

            return
        }

        setEvent({
            ...event,
            address: {
                postalcode: cepValue,
                number: e
            },
        });
    }

    function clearAddress() {
        setEvent({
            ...event,
            address: { notYet: true }
        });
        setCepFilled(false);
        setCepValue('')
    }

    return (
        <Column marginTop='18px'>
            <Text size='small' type='bold' color={colors.nightDark}>
                Endereço
            </Text>

            <Fieldset
                placeholder='CEP do local'
                layout='squired'
                mask={masks.cep}
                value={cepValue}
                name="cep"
                syncProp={true}
                onChangeValue={(e) => showAddressFields(e)}
            />
            <Switch
                label='Ainda não tenho um endereço para o evento'
                marginBottom='10px'
                marginTop='4px'
                checked={!event?.address}
                onChange={clearAddress}
            />
            {errors && (
                <HorizontalCenter>
                    <Text color={colors.danger}> {errors?.cep} </Text>
                </HorizontalCenter>
            )}

            {address && (
                addressLoading
                    ? <Loader />
                    : (
                        <AddressFields visible={cepFilled} withoutNumber={withoutNumber}>
                            <Row>
                                <Fieldset
                                    label='Bairro'
                                    layout='squired'
                                    value={address.street.district}
                                    // badgeText='Bairro'
                                    disabled={'disabled'}
                                    marginRight='14px'
                                />
                                <Fieldset
                                    label='Rua'
                                    layout='squired'
                                    value={address.street.name}
                                    // badgeText='Rua'
                                    disabled={'disabled'}
                                />
                            </Row>
                            <Row>
                                <Fieldset
                                    label='Número'
                                    layout='squired'
                                    marginRight='14px'
                                    disabled={withoutNumber ? 'disabled' : ''}
                                    value={numberValue}
                                    onChangeValue={(e) => onChangeNumber(e)}
                                    refresh={withoutNumber}
                                />
                                <Fieldset
                                    label='Complemento'
                                    layout='squired'
                                    value={complementValue}
                                    syncProp={true}
                                    disabled={withoutNumber ? 'disabled' : ''}
                                    onChangeValue={(e) => {
                                        setComplementValue(e)
                                        setEvent({
                                            ...event,
                                            address: {
                                                ...event.address,
                                                complement: e
                                            }
                                        })
                                    }
                                    }
                                />
                            </Row>

                            {/* <Row marginLeft='4px'>
                                <input
                                    type="checkbox"
                                    checked={withoutNumber}
                                    onChange={({ target }) => {
                                        setWithoutNumber(target.checked);
                                        setEvent({
                                            ...event,
                                            address: { ...event.address, number: null },
                                        });
                                        onChangeNumber(false)
                                    }}
                                />
                                <Text
                                    size='small'
                                    color={colors.night}
                                    paddingTop={'10px'}
                                    paddingLeft={'8px'}
                                >
                                    O endereço não possui número
                                </Text>
                            </Row> */}

                            <Switch
                                checked={withoutNumber}
                                label='O endereço não possui número'
                                marginBottom='10px'
                                marginTop='4px'
                                onChange={() => {
                                    setWithoutNumber(!withoutNumber);
                                    setEvent({
                                        ...event,
                                        address: { ...event.address, number: null },
                                    });
                                    onChangeNumber(false)
                                }}
                            />

                            {
                                withoutNumber && (
                                    <Fieldset
                                        layout='squired'
                                        label='Nome do local'
                                        value={nameValue}
                                        onChangeValue={(e) => {
                                            setNameValue(e)
                                            setEvent({
                                                ...event,
                                                address: {
                                                    ...event.address,
                                                    name: e
                                                }
                                            })
                                        }
                                        }
                                    />
                                )
                            }


                            <Switch
                                checked={!!hideAddress}
                                onChange={(e) => {
                                    setEvent({
                                        ...event,
                                        hide_address: e
                                    })
                                    setHideAddress(e);
                                }}
                                label='Não mostrar o endereço na página do evento'
                            />
                        </AddressFields>
                    )
            )}

            {errors && (
                <HorizontalCenter marginTop='12px'>
                    <Text color={colors.danger}> {errors?.number} </Text>
                </HorizontalCenter>
            )}
        </Column>
    )
}

const AddressFields = styled.div`
    width: 100%;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease-in;

    ${({ visible }) => visible && `
        visibility: visible;
        opacity: 1;
    `};

    ${({ visible, withoutNumber }) => visible && !withoutNumber && `
        height: 220px;
    `};

    ${({ visible, withoutNumber }) => visible && withoutNumber && `
        height: 260px;
    `};
`;