import participantsApi from 'api/requests/participants'
import Button from 'components/_UI/Button'
import Loader from 'components/_UI/Loader'
import Modal from 'components/_UI/Modal'
import Paginate from 'components/_UI/Paginate'
import Table from 'components/_UI/Table'
import Text from 'components/_UI/Text'
import ToastContainer from 'components/_UI/ToastsContainer'
import DynamicProfileImage from 'components/commons/DynamicProfileImage'
import NotResultDashBoard from 'components/commons/NotResultDashBoard'
import { useEvent } from 'context/events/Event'
import downloadBlob from 'helpers/downloadBlob'
import useToast from 'hooks/useToast'
import React, { useEffect, useState } from 'react'
import { Check, CheckCircle, Download, Info, Mail, UserCheck } from 'react-feather'
import styled, { keyframes } from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row'
import breakpoints from 'theme/breakpoints'
import colors from 'theme/colors'
import fonts from 'theme/fonts'
import { Link } from 'react-router-dom'
import InfoTooltip from 'components/_UI/InfoTooltip'
import Radio from 'components/_UI/Radio'
import Icon from 'components/_UI/Icon'
import buttonText from 'texts/buttons/buttonText'
import ticketsApi from 'api/requests/tickets'

let slug = null

const DOWNLOAD_FORMATS = [
    { name: 'xlsx', label: 'xlsx (Planilhas Excel - versão atual)' },
    { name: 'xls', label: 'xls (Planilhas Excel - versão antiga)' },
    // { name: 'pdf', label: 'pdf (Documento em PDF - leitura e impressão)' },
    { name: 'csv', label: 'csv (Planilhas de dados - formato simples)' },
]

export default function ParticipantsList({ data }) {
    const { event } = useEvent();
    const { toast, toasts } = useToast();


    const [participants, setParticipants] = useState([]);
    const [loading, setLoading] = useState(false);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [perPage, setPerpage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [totalItems, setTotalItems] = useState(null);
    const [detail, setDetail] = useState();
    const [fastCheckin, setFastCheckin] = useState(false);
    const [buttonCheckinLoading, setButtonCheckinLoading] = useState(false);
    const [formatDateOrder, setFormatDateOrder] = useState('')
    const [downloadModal, setDownloadModal] = useState(false)
    const [selectedFormat, setSelectedFormat] = useState(DOWNLOAD_FORMATS[0].name)


    useEffect(() => {
        slug = data;
        getParticipants();
    }, []);


    const listParticipants = participants?.map(item => {

        const date = new Date(item.created_at);
        return {
            code: <Text name='small'>#{item?.code}</Text>,
            name: <Text name='small'>{item?.name}</Text>,
            type: <StyledStatus status={item?.type}> {item.type === 'ticket' ? 'Ingresso' : 'Produto'} </StyledStatus>,
            product: <Text name='small'>{item?.product}</Text>,
            date: <Text name='small'>
                {date.toLocaleDateString('pt-BR')} - {date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}
            </Text>,
            status: <StyledStatus status={item.used}>{item.used ? 'Utilizado' : 'Não Utilizado'}</StyledStatus>,
            actions:
                <>
                    <ContentAction
                        onClick={() => getDetail(item)}
                    >
                        <InfoTooltip
                            bgColor={colors.night}
                            text={'Visualizar Detalhes'}
                            type='none'
                        >
                            <Info size={20} color={colors.primaryLight} />
                        </InfoTooltip>
                    </ContentAction>
                    <ContentAction
                        used={item.used}
                        onClick={() => openCheckinModal(item)}
                    >
                        <InfoTooltip
                            bgColor={colors.night}
                            text={item.type === 'ticket' ? 'Fazer check-in' : 'Validar produto'}
                            type='none'
                        >
                            <CheckContainer used={item.used}>
                                <UserCheck size={20} color={colors.primaryLight} />
                                {/* <Icon name='qrcode' color={colors.primaryLight} /> */}
                            </CheckContainer>
                        </InfoTooltip>
                    </ContentAction>
                </>
        };
    })


    async function downloadFile() {
        setDownloadLoader(true);
        try {
            const { data } = await participantsApi.download(event?.id);
            const fileName = `Participantes_${event?.name}.${selectedFormat}`;

            downloadBlob(data, fileName, selectedFormat);
            setDownloadModal(false);

            toast.success('Arquivo baixado com sucesso!', { position: 'top-center' })
        } catch (error) {
            toast.error('Ocorreu um erro ao baixar o arquivo.', { position: 'top-center' })
        } finally {
            setDownloadLoader(false);
        }
    }

    const getDetail = (item) => {
        setDetail(item)
        let formatDate = new Date(item?.created_at)
        setFormatDateOrder(formatDate);
    }

    const openCheckinModal = (item) => {
        setFastCheckin(item)
    }


    const getParticipants = async (number = 1) => {
        setLoading(true)

        try {
            const { data } = await participantsApi.list(slug, number);

            setParticipants(data.data)
            setPerpage(data?.meta.per_page)
            setLastPage(data.meta.last_page)
            setCurrentPage(data?.meta.current_page)
            setTotalItems(data?.meta.total)

        } catch (error) {
            // console.log('error');

        } finally {
            setLoading(false)
        }
    }

    const doCheckin = async () => {
        setButtonCheckinLoading(true);
        try {
            await ticketsApi.checkIn({ code: fastCheckin.code });
            toast.success(`${fastCheckin?.type === 'ticket' ? 'Ingresso' : 'Produto'} validado com sucesso!`, { position: 'top-center' })
            setFastCheckin(false);
            getParticipants();
        } catch (error) {
            toast.error(`Ocorreu um erro ao validar o ${fastCheckin?.type === 'ticket' ? 'Ingresso' : 'Produto'}.`, { position: 'top-center' })
        } finally {
            setButtonCheckinLoading(false);
        }
    }


    return (
        <Column>
            <ToastContainer toasts={toasts} />

            {
                loading ? (
                    <ContentLoading>
                        <Loader size='large' />
                    </ContentLoading>
                ) : (
                    <Container>
                        {
                            participants.length > 0 ? (
                                <>
                                    <HeaderContent>
                                        <Text name='subtitle' paddingBottom={0}>
                                            Lista de participantes
                                        </Text>

                                        <ButtonContainer>
                                            <Button
                                                variant={downloadLoader ? 'disabled' : 'featured'}
                                                category='squired'
                                                onClick={() => setDownloadModal(true)}
                                            >
                                                {
                                                    downloadLoader ? (
                                                        <FullyCentered width='auto'>
                                                            <Text paddingBottom={0}>
                                                                Baixando
                                                            </Text>
                                                            <AnimatedDots />
                                                        </FullyCentered>
                                                    ) : (
                                                        <>
                                                            <Download color={colors.neutralLight} size={16} />
                                                            <Text
                                                                color={colors.neutralLight}
                                                                paddingBottom={0}
                                                                marginLeft={'6px'}
                                                            >
                                                                Baixar lista
                                                            </Text>
                                                        </>
                                                    )
                                                }
                                            </Button>
                                        </ButtonContainer>
                                    </HeaderContent>

                                    <Table
                                        rows={listParticipants}
                                        columns={['Código', 'Participante', 'Tipo', 'Item', 'Data de compra', 'Status', 'Ações']}
                                    />
                                    {
                                        lastPage > 1 &&
                                        <Paginate
                                            perPage={perPage}
                                            totalItems={totalItems}
                                            lastPage={lastPage}
                                            firstPage={1}
                                            currentPage={currentPage}
                                            marginTop={'30px'}
                                            onPaginate={(number) => getParticipants(number)}
                                        />
                                    }
                                </>
                            ) : (
                                <ContentNotResult>
                                    <NotResultDashBoard
                                        text={
                                            <Text name='highlight'>
                                                Não foram encontrados participantes no seu evento até o momento.
                                            </Text>
                                        }
                                        ilustration={'withoutParticipants'}
                                        marginTop={'15px'}
                                    />
                                </ContentNotResult>
                            )
                        }
                    </Container>
                )
            }

            <Modal isOpen={fastCheckin} onClose={() => setFastCheckin(false)}>
                <Text name='highlight'> {fastCheckin?.type === 'ticket' ? 'Check-in' : 'Validação de produto'} </Text>
                <Text>
                    {`Ao clicar em`}
                    <b> {fastCheckin?.type === 'ticket' ? 'Fazer Check-in' : 'Validar produto'} </b>
                    {`você irá validar o ${fastCheckin?.type === 'ticket' ? 'ingresso' : 'produto'}. Esta ação não poderá ser desfeita posteriormente.`}</Text>
                <Text> Tem certeza que deseja prosseguir?</Text>

                {
                    buttonCheckinLoading ? (
                        <FullyCentered marginTop='20px'>
                            <Loader />
                        </FullyCentered>
                    ) : (
                        <Row marginTop='20px'>
                            <Button
                                variant={'light'}
                                category='roundedBorded'
                                onClick={() => setFastCheckin(false)}
                            >
                                {buttonText.cancel}
                            </Button>

                            <Button
                                variant={'lightBlue'}
                                category='roundedBorded'
                                marginLeft={'15px'}
                                onClick={() => doCheckin(false)}
                            >
                                {fastCheckin?.type === 'ticket' ? 'Fazer Check-in' : 'Validar produto'}
                            </Button>
                        </Row>
                    )
                }
            </Modal>

            <Modal isOpen={detail} onClose={() => setDetail(false)}>
                <ModalWrapper>
                    <Row verticalCenter paddingBottom='25px'>
                        <Column width='100px'>
                            <DynamicProfileImage
                                img={detail?.avatar}
                                word={detail?.name}
                                size={'85px'}
                            />
                        </Column>
                        <Column>
                            <Row marginLeft={'10px'} >
                                <Text name='subtitle'>
                                    {detail?.name}
                                </Text>
                            </Row>
                            <Row verticalCenter marginLeft={'10px'} >
                                <Mail
                                    size={20}
                                    color={colors.night}
                                />
                                <Text
                                    marginLeft={'5px'}
                                    paddingBottom={0}
                                >

                                    {detail?.email}
                                </Text>
                            </Row>
                        </Column>
                    </Row>

                    <Row paddingBottom='0'>
                        <Text><b>Código:</b> {detail?.code} </Text>
                    </Row>

                    <Row marginTop='5px' paddingBottom='0'>
                        <Text><b>Tipo:</b> {detail?.type === 'ticket' ? 'Ingresso' : 'Produto'} </Text>
                    </Row>

                    <Row marginTop='5px' paddingBottom='0'>
                        <Text><b>Item:</b> {detail?.product} </Text>
                    </Row>

                    <Row marginTop='5px' paddingBottom='0'>
                        <Text><b>Data de compra:</b>
                            {formatDateOrder &&
                                ` ${formatDateOrder.toLocaleDateString('pt-BR')} - ${formatDateOrder.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}`
                            }
                        </Text>
                    </Row>

                    <Row marginTop='5px' paddingBottom='0'>
                        <Text><b>Status:</b> {detail?.used ? 'Utilizado' : 'Não utilizado'} </Text>
                    </Row>

                    <Row marginTop='5px' paddingBottom='0'>
                        <Text><b>Código promocional:</b> {detail?.coupon ? detail?.coupon : 'Não'}</Text>
                    </Row>

                    {
                        detail?.custom_fields && Object?.keys(detail?.custom_fields).length > 0 && (
                            <ContentAditionalFields>
                                <Text name='highlight' marginTop={'5px'}>Campos adicionais</Text>

                                {detail?.custom_fields && Object.entries(detail?.custom_fields).map(([key, value]) => (
                                    <Row marginTop="5px" key={key} verticalCenter>
                                        <Text paddingBottom='0'>
                                            <b>{key}:</b>
                                        </Text>
                                        {typeof value === 'string' && (value.startsWith('https://') || value.startsWith('www')) ? (
                                            <AditionalLinkField to={value.startsWith('www') ? `https://${value}` : value} target="_blank" rel="noopener noreferrer">
                                                abrir link
                                            </AditionalLinkField>
                                        ) : (
                                            <Text marginLeft={'5px'} paddingBottom='0' >
                                                {Array.isArray(value) ? value.join(", ") : value}
                                            </Text>
                                        )}
                                    </Row>
                                ))}

                            </ContentAditionalFields>
                        )
                    }
                </ModalWrapper>
            </Modal>

            {
                !!downloadModal && (
                    <Modal isOpen={downloadModal} onClose={() => setDownloadModal(false)}>
                        <Text type='bold'>Selecione o formato que deseja baixar a lista</Text>

                        {
                            DOWNLOAD_FORMATS.map(el => (
                                <Radio
                                    label={el.label}
                                    selected={el.name == selectedFormat}
                                    onSelect={() => setSelectedFormat(el.name)}

                                />
                            ))
                        }

                        {
                            !downloadLoader ? (
                                <Button
                                    marginTop={'14px'}
                                    variant={selectedFormat ? 'featured' : 'disabled'}
                                    onClick={downloadFile}
                                >
                                    Baixar lista
                                </Button>
                            ) : (
                                <FullyCentered marginTop='4px'>
                                    <Loader />
                                </FullyCentered>
                            )

                        }
                    </Modal>
                )
            }
        </Column >
    )
}
const StyledStatus = styled(Row)`
    padding: 2px;
    border-radius: 4px;
    color: ${colors.neutralLight};
    font-size: 12px;
    justify-content: center;
    width: auto;
    padding: 3px 5px;
    font-size: ${fonts.sizes.small};
   
   ${({ status }) => status == false && `
        background: ${colors.nightLight};
        border-radius: 6px 30px 30px 6px;
    `}

    ${({ status }) => status == true && `
        background: ${colors.success};
        border-radius: 6px 30px 30px 6px;
    `}
    
    ${({ status }) => status === 'ticket' && `
        background: orange;
    `}

    ${({ status }) => status === 'product' && `
        background: ${colors.occasionalPurple};
    `}
`;

const Container = styled(Column)`
    padding-bottom: 20px;
`;

const ContentNotResult = styled.div`
    & > div {
        flex-direction: column;
    }

    & > div > picture {
        width: 45%;
    }

    & > div > div {
        width: 100%;
    }
`;

const HeaderContent = styled(Column)`
    width: 100%;

    @media screen and (min-width: ${breakpoints.md}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & > h2 {
            width: auto
        }
    }
`;

const dots = keyframes`
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
`;

const AnimatedDots = styled.span`
  font-size: ${fonts.sizes.default};
  &:after {
    content: '';
    animation: ${dots} 1.5s steps(4, end) infinite;
  }
`;

const ButtonContainer = styled.span`
  width: 100%;
  margin-top: 20px;

  @media screen and (min-width: ${breakpoints.md}) {
    width: 152px;
    margin-top: 0;
  }
`;


const ContentAction = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all ease-in-out 0.25s;

    ${({ used }) => used && `
        pointer-events: none !important;
        opacity: .4;
        filter: grayscale(0.8);
    `};

    & > div > svg {
        &:hover {
            transform: scale(1.1);
       }
    }
`

const CheckContainer = styled(FullyCentered)`
    width: 20px;
    height: 20px; 
    margin-left: 14px;
    margin-bottom: 2px;
`;

const ModalWrapper = styled(Column)`
    padding: 10px;

    & > p {
        text-transform: capitalize;
    }
`

const ContentAditionalFields = styled(Column)`
    margin-top: 10px;
    align-items: flex-start;

    & * p {
        text-transform: capitalize;
    }
`

const AditionalLinkField = styled(Link)`
    padding-bottom: 0 !important;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-left: 5px;
`;

const ContentLoading = styled(Column)`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`