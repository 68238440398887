async function copyToClipboard(
    data,
    onSuccess = () => {},
    onError = () => copyToClipboardFallback(data)
) {
    if (navigator?.clipboard?.writeText) {
        try {
            await navigator.clipboard.writeText(data)
            onSuccess()
        } catch (error) {
            onError()
        }
    }
}

function copyToClipboardFallback(value) {
    const input = document.createElement('input');
    input.value = value;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    toast.success('Copiado com sucesso!', { position: 'top-right' })
}

export {
    copyToClipboard,
    copyToClipboardFallback,
}
