import React from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors';
import Text from './Text';

export default function Chips({ text, prefix = '', size = 'default' }) {
    const getStatusText = (status) => {

        if (status === 'completed') {
            return 'Concluído';
        }

        if (status === 'approved') {
            return 'Aprovado';
        }

        if (status === 'accepted') {
            return 'Aceito';
        }

        if (status === 'rejected') {
            return 'Recusado';
        }

        if (status === 'canceled') {
            return 'Expirado';
        }

        if (status === 'failed') {
            return 'Negado';
        }
        if (status === 'pending') {
            return 'Pendente';
        }
    }

    return (
        <ContainerChips>
            <StyledStatus status={text}>
                <Text type='bold' name={size} color={colors.neutralLight} paddingBottom={0}>
                    {prefix} {getStatusText(text)}
                </Text>
            </StyledStatus>
        </ContainerChips>
    )
}

const ContainerChips = styled(Column)`
    width: 80px;
`;

const StyledStatus = styled(Column)`
    border-radius: 4px 30px 30px 4px;
    align-items: center;
    text-transform: capitalize;
    padding: 5px 0;
    /* color: ${colors.neutralLight}; */
    /* font-size: 12px;
    font-weight: bold; */

    ${({ status }) => (status === 'failed' || status === 'rejected' || status === 'canceled') && `
        background: ${colors.danger};
    `}

    ${({ status }) => (status === 'completed' || status === 'accepted' || status === 'approved') && `
        background: ${colors.success};
    `}

    ${({ status }) => status === 'pending' && `
        background: orange;
    `}
`;
