import Button from 'components/_UI/Button'
import Modal from 'components/_UI/Modal'
import Text from 'components/_UI/Text'
import React from 'react'
import buttonText from 'texts/buttons/buttonText'
import colors from 'theme/colors'
import { useCollapse } from 'context/general/Collapse';
import { useEvent } from 'context/events/Event';
import EventDetail from 'modules/events/detail/EventDetail';
import { useSearchParams } from 'react-router-dom'
import routeParams from 'theme/routeParams'

export default function ExpirationModal({ visible }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const { collapse, setCollapse } = useCollapse();
    const { event } = useEvent();

    function goBackToEvent() {
        setSearchParams((prevSearchParams) => {
            const newSearchParams = new URLSearchParams(prevSearchParams);
            newSearchParams.delete(routeParams.payments.checkout)

            return newSearchParams;
        });

        setCollapse({
            ...collapse,
            withHideCity: false,
            arrowPosition: false,
            componentToRender: () => <EventDetail data={event} />
        });
    }
    
    return (
        <Modal isOpen={visible} closeButton={false}>
            <Text name='highlight' color={colors.nightDark}>
                Seu tempo de compra expirou
            </Text>

            <Text marginTop={'10px'} textAlign={'center'}>
                Isso acontece pois precisamos assegurar que os ingressos/produtos fiquem disponíveis para aquisição, considerando que existe um número limitado para venda.
            </Text>

            <Text type='bold' textAlign={'center'}>
                Caso você queira realizar a compra, clique em voltar. O tempo para finalizar o processo da compra é de 20 minutos.
            </Text>

            <Button
                marginTop={'10px'}
                variant={'lightBlue'}
                onClick={goBackToEvent}
            >
                {buttonText.back}
            </Button>
        </Modal>
    )
}
