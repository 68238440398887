import api from "api";

const orders = {
    get: (params) => {
        let customParams = params.status === 'all' ? {...params, status: ''} : {...params}
        return api().get('/orders', { params: { ...customParams } });
    },

    detail: (id) => {
        return api().get(`/orders/detail/${id}`);
    },


}

export default orders;