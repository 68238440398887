import { useUser } from 'context/user/User';
import React from 'react'
import GoogleLogin from 'react-google-login';
import { useNavigate } from 'react-router-dom';
import loginApi from 'api/requests/login';
import Button from 'components/_UI/Button';
import styled from 'styled-components';
import Icon from 'components/_UI/Icon';
import paths from 'routes/paths';
import OnboardingTags from '../sign-up/OnboardingTags';
import { useState } from 'react';

export default function GoogleSignIn({ googleLoading, googleErrorMessage = () => false }) {
    const { setUser } = useUser();
    const navigate = useNavigate();

    const [showPreferences, setShowPreferences] = useState(false);

    const responseGoogle = (response) => {
        googleLoading(true)
        const { accessToken, googleId, profileObj } = response;
        const payload = {
            accessToken,
            googleId,
            email: profileObj.email
        };

        registerUser(payload);
    }

    const registerUser = async (payload) => {
        try {
            const { data } = await loginApi.withGoogle(payload);
            localStorage.setItem('token', data.token);
            setUser(data);
            // navigate(paths.home);
            showPreferences(true);
        } catch (error) {
            googleErrorMessage(error?.response?.data?.message)
        } finally {
            googleLoading(false)
        }
    }

    return (
        <>
            <GoogleLogin
                clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}
                onSuccess={responseGoogle}
                onFailure={() => googleLoading(false)}
                cookiePolicy={'single_host_origin'}
                uxMode={'popup'}
                render={renderProps => (
                    <Button
                        variant='secondary'
                        size='large'
                        onClick={renderProps.onClick}
                    >
                        <IconContainer>
                            <Icon name={'google-logo'} />
                        </IconContainer>
                        Entrar com google
                    </Button>
                )}
            />

            {showPreferences && <OnboardingTags />}
        </>
    )
}

const IconContainer = styled.div`
    width: 18px;
    height: 18px;
    margin-right: 8px;
`;