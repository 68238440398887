import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import EventMarker from './EventMarker';
import { useCollapse } from 'context/general/Collapse';
import { useLocation, useSearchParams } from 'react-router-dom';
import MultipleEventsList from './MultipleEventsList';
import Icon from 'components/_UI/Icon';
import Text from 'components/_UI/Text';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import indexPriority from 'theme/z-index';
import MultipleEstablishmentsList from './MultipleEstablishmentsList';
import paths from 'routes/paths';

export default function MultipleMarkers({ data, specialData, key }) {
    const location = useLocation();

    const [searchParams, setSearchParams] = useSearchParams();
    const { collapse, setCollapse } = useCollapse();

    const isEventTab = location.pathname.includes(paths.events.home);
    const isEstablishment = location.pathname.includes(paths.establishments.home);

    async function showMultiples() {
        const payload = {
            lat: data[0].address?.lat,
            long: data[0].address?.long
        }

        setSearchParams(payload)
        if (isEventTab) {
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => data && <MultipleEventsList data={data} />
            })
        }
        
        if (isEstablishment) {
            setCollapse({
                ...collapse,
                withContent: true,
                height: 'max',
                arrowPosition: false,
                componentToRender: () => data && <MultipleEstablishmentsList  data={data} />
            })
        }
    }

    return (
        <MultipleEventsContainer
            id='map_marker_multiple'
            quantity={data.length}
            onClick={() => showMultiples(data)}
        >

            {
                data.length > 3 && (
                    <span>
                        <IconContainer>
                            <Text
                                type='bold'
                                name='small'
                                color={colors.night}
                            >
                                +{data.length - 3}
                            </Text>
                        </IconContainer>
                    </span>
                )
            }

            {
                data.map(el => (
                    <EventMarker
                        key={el.slug}
                        name={el.name}
                        type={el.classification.name}
                        image={el?.thumb || el?.profileImage?.thumb}
                        onMarkerClick={() => false}
                    />
                ))
            }
        </MultipleEventsContainer>
    )
}

const MultipleEventsContainer = styled(Row)`
    width: 80px;
    height: 70px;
    position: relative;

    & > span {
        width: 100%;
        height: 100;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-bottom: -6px;
        margin-right: 10px;
    }

    &:before {
      content: '';
      background: ${colors.neutral};
      width: 46px;
      height: 46px;
      border-radius: 50%;
      margin-top: 20px;
      transform: scale(1.4, 1);
      margin-left: -32px;
      border: 1px solid ${colors.nightLight};
      z-index: ${indexPriority.xSmall} !important;
      position: absolute;
      left: 44px;
      bottom: 4px;
      box-shadow: 0 2px 7px ${colors.nightLight};
    }

    // ${({ quantity }) => quantity === 4 && `
    //     background: purple !important;
    //     div:nth-child(2) {
    //         margin-left: 34px;
    //     }

    //     div:nth-child(3) {
    //         margin-left: 18px;
    //         margin-top: 8px;
    //         left: 44px;
    //     }
    // `}

    ${({ quantity }) => quantity >= 3 && `
        div:nth-child(2) {
            margin-left: 36px;
        }

        div:nth-child(3) {
            margin-left: 18px;
            margin-top: 10px;
        }
    `}

    ${({ quantity }) => quantity === 2 && `
        &:before {
            width: 38px;
            height: 38px;
            left: 40px;
            bottom: 12px;
        }

        div:nth-child(2) {
            margin-left: 20px;
        }
    `}
`;


const IconContainer = styled(Column)`
        width: 30px;
        height: 30px;
        z-index: ${indexPriority.small};
        align-items: center;
        justify-content: center;
        background: ${colors.neutralLight};
        border: 1px solid ${colors.nightLight};
        border-radius: 50%;

        & > p {
            padding-bottom: 0 !important;;
        }
`;