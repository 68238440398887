import Button from 'components/_UI/Button';
import Icon from 'components/_UI/Icon';
import LinkPage from 'components/_UI/LinkPage';
import Text from 'components/_UI/Text';
import date from 'helpers/date';
import types from 'helpers/system/types';
import React, { useState, useEffect } from 'react'
import paths from 'routes/paths';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import eventTypes from 'theme/eventTypes';
import fonts from 'theme/fonts';
import sizes from 'theme/sizes';
import { useEvent } from 'context/events/Event';
import CronogramModal from './CronogramModal';
import { useBackButtonCollapse } from 'context/general/BackButtonCollapse';
import useAuth from 'hooks/useAuth';
import { Calendar, MapPin } from 'react-feather';
import Modal from 'components/_UI/Modal';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCollapse } from 'context/general/Collapse';
import CheckoutOrder from 'modules/checkout/products/CheckoutOrder';
import UtilityHeader from 'components/_UI/UtilityHeader';
import Interest from './Interest';
import InfoTooltip from 'components/_UI/InfoTooltip';
import Divider from 'components/_UI/Divider';
import TicketsForSale from './TicketsForSale';
import ProductsForSale from './ProductsForSale';
import { useInvisibleButton } from 'context/general/InvisibleButton';
import DynamicProfileImage from 'components/commons/DynamicProfileImage';
import DescriptionWithHTML from './DescriptionWithHTML';


export default function EventDetail({ data, withBackButton }) {

    const textIa = 'kaue.ia'

    const navigate = useNavigate();
    const location = useLocation();
    const { isAuth } = useAuth()
    const { event, setEvent } = useEvent();
    const { collapse, setCollapse } = useCollapse();
    const { backButtonCollapse, setBackButtonCollapse } = useBackButtonCollapse();

    const [fullCronogramModal, setFullCronogramModal] = useState(false)
    const [goToLoginModal, setGoToLoginModal] = useState(false)
    const [hasTickets, setHastickets] = useState(null)
    const [hasProducts, setHasProducts] = useState(null)
    const { setInvisibleButton } = useInvisibleButton()

    useEffect(() => {
        setEvent(data);

        setHastickets(data?.products?.some(el => el.type === 'ticket'))
        setHasProducts(data?.products?.some(el => el.type === 'product'))

        if (withBackButton) {
            setBackButtonCollapse({
                ...backButtonCollapse,
                visible: true
            })
        }
    }, [])

    function closeModalNotAuth() {
        setInvisibleButton(false)
        setGoToLoginModal(false)
    }

    function buyTicket() {
        window.open(`${import.meta.env.VITE_CLIENT_URL}/evento/${data?.slug}`, '_blank', 'noopener,noreferrer');

        // if (!isAuth) {
        //     setGoToLoginModal(true);
        //     setInvisibleButton(true)

        //     return;
        // }

        // setEvent(data)
        

        // setCollapse({
        //     ...collapse,
        //     arrowPosition: true,
        //     withBackButton: false,
        //     componentToRender: () => (
        //         <Column>
        //             <UtilityHeader title={data?.name} onBack={goBack} />
        //             <CheckoutOrder event={data} />
        //         </Column>

        //     )
        // });
    }

    function goBack() {
        setCollapse({
            ...collapse,
            withHideCity: false,
            arrowPosition: false,
            componentToRender: () => <EventDetail data={event} />
        });
    }

    return (
        <Container>
            <EventDetailHeadContent>
                <StyledBackgroundContainer
                    type={data?.classification?.name}
                    isImage={data?.images[0]}
                    bg={data?.images[0] || 'assets/images/placeholder-events.png'}
                >
                    {
                        data?.images[0] ? (
                            <StyledBackground src={data?.images[0]} />
                        ) : <StyledBackground src={'assets/images/placeholder-events.png'} />
                    }
                </StyledBackgroundContainer>

                <CardMainContent>
                    <Text
                        color={colors.nightDark}
                        name='subtitle'
                        type='bold'
                        paddingTop={'12px'}
                        paddingBottom={'6px'}
                    >
                        {data?.name}
                    </Text>

                    {/* {
                        data?.discount_type && data?.discount && (
                            <Row>
                                <Discount marginBottom='10px'>
                                    <Text
                                        color={colors.neutral}
                                        type='bold'
                                        name='small'
                                    >
                                        {
                                            data.discount_type === 'value'
                                                ? `R$ ${data.discount},00 de desconto`
                                                : `${data.discount}% de desconto`
                                        }
                                    </Text>
                                </Discount>
                            </Row>
                        )
                    } */}

                    <CategoriesInfos>
                        <EventType type={data?.classification?.name}>
                            <Text
                                paddingBottom={0}
                                type='bold'
                                color={colors.neutral}
                                name='small'
                            >
                                {types.getBySlug(data?.classification?.name).label}
                            </Text>
                        </EventType>

                        {
                            data?.adulthood && (
                                <Tag adulthood>
                                    <Text
                                        color={colors.neutralLight}
                                        type='bold'
                                        name='small'
                                    > +18
                                    </Text>
                                </Tag>
                            )
                        }

                        {data?.tags?.map(el => (
                            <Tag key={el.name}>
                                <Text name='small' color={colors.neutralLight} type='bold'>
                                    {el.name}
                                </Text>
                            </Tag>
                        ))}
                    </CategoriesInfos>

                    <br />

                    {
                        !data?.hide_address && (
                            <ContentInfoRow address>
                                <MapPin color={colors.night} size={24} style={{ marginRight: 10 }} />
                                {
                                    data?.address ? (
                                        <ContentLinkAddress>
                                            <LinkPage isWrapper to={data.link_map}>
                                                <Text color={colors.primaryDark} decoration='underline' paddingBottom={'0px'}>
                                                    {data?.address?.street?.name && `${data?.address?.street?.name},`} {data?.address?.street?.district && `Bairro ${data?.address?.street?.district}`}
                                                    {data.address.number && `, nº ${data.address.number}`}
                                                    {data.address.complement && ` - ${data.address.complement}`}
                                                </Text>
                                            </LinkPage>
                                        </ContentLinkAddress>
                                    ) : (
                                        <Text color={colors.night} paddingBottom={'0px'}>
                                            Local a definir
                                        </Text>
                                    )
                                }
                            </ContentInfoRow>
                        )
                    }

                    <ContentInfoRow single={data.dates.length === 1}>
                        <Calendar color={colors.night} size={24} />

                        <DateContainer>
                            {
                                data.dates.length > 1 ? (
                                    <>
                                        <Text
                                            color={colors.nightDark}>
                                            <b>Dia 1</b> - {date.getDay(data?.dates[0].date)}
                                            &nbsp;de {date.getMounthName(data?.dates[0].date)}
                                            &nbsp;- {date.getTimeWithoutSeconds(data?.dates[0].start)}
                                            {data?.dates[0].end ? ` até ${date.getTimeWithoutSeconds(data?.dates[0].end)}` : ''}
                                        </Text>

                                        <Text color={colors.nightDark}>
                                            <b>Dia 2</b> - {date.getDay(data?.dates[1].date)}
                                            &nbsp;de {date.getMounthName(data?.dates[1].date)}
                                            &nbsp;- {date.getTimeWithoutSeconds(data?.dates[1].start)}
                                            {data?.dates[1].end ? ` até ${date.getTimeWithoutSeconds(data?.dates[1].end)}` : ''}
                                        </Text>

                                        {data.dates.length >= 3 && (
                                            <CronogramContainer>
                                                <Button
                                                    category='outline'
                                                    size='medium'
                                                    onClick={() => setFullCronogramModal(true)}
                                                >
                                                    Ver cronograma completo
                                                </Button>
                                                <CronogramModal
                                                    data={data.dates}
                                                    visible={fullCronogramModal}
                                                    setVisible={setFullCronogramModal}
                                                />
                                            </CronogramContainer>
                                        )}
                                    </>
                                ) : (
                                    <OnlyOnedate>
                                        <Text color={colors.nightDark}>
                                            {date.getDay(data?.dates[0].date)}
                                            &nbsp;de {date.getMounthName(data?.dates[0].date)}
                                            &nbsp;- {date.getTimeWithoutSeconds(data?.dates[0].start)}
                                            {data?.dates[0].end ? ` até ${date.getTimeWithoutSeconds(data?.dates[0].end)}` : ''}
                                        </Text>
                                    </OnlyOnedate>
                                )
                            }
                        </DateContainer>

                    </ContentInfoRow>

                    {/* <br />
                    {
                        isAuth &&
                        <Interest slug={data.slug} presence={data.presence} userPresenceStatus={data.user_login_status} />
                    } */}

                    {
                        data?.establishment?.name && (
                            <ContainerOrganizerLink>
                                <LinkPage target={'_self'} to={`/estabelecimentos?estabelecimento=${data?.establishment?.slug}`}>
                                    <ContentOrganizer>
                                        <DynamicProfileImage size='24px' img={data?.establishment?.profile_image_id?.thumb} word={data?.establishment?.name} />
                                        <Text color={colors.nightDark} paddingBottom={'0'} marginLeft={'10px'} marginRight={'5px'}>Organizado por</Text>
                                        <Text color={colors.nightDark} type='bold' paddingBottom={'0px'}>{data?.establishment?.name}</Text>
                                    </ContentOrganizer>
                                </LinkPage>
                            </ContainerOrganizerLink>
                        )
                    }


                    {
                        data?.origin === textIa && (
                            <Row verticalCenter marginBottom='15px'>
                                <StyledIa>
                                    <Icon name='smartToy' color={colors.night} />
                                </StyledIa>

                                <Text paddingBottom={'0'} color={colors.nightDark} marginLeft={'10px'}>Evento mapeado através de IA</Text>

                                <InfoTooltip
                                    bgColor={colors.night}
                                    text=<>O Whizr usa inteligência artificial para o mapeamento de eventos que não são cadastrados através de parceiros oficiais.
                                        <br></br>
                                        Recomendamos checar as informações no local oficial do evento.</>
                                    type='info'
                                />
                            </Row>
                        )
                    }

                    {
                        data?.link_ticket && (
                            <ButtonContainer>
                                <LinkPage isWrapper to={data?.link_ticket} target={'_blank'}>
                                    <Button>Garantir ingresso</Button>
                                </LinkPage>
                            </ButtonContainer>
                        )
                    }

                    {
                        !!hasTickets && (
                            <TicketsForSale data={data?.products} onTicketClick={() => buyTicket()} />
                        )
                    }

                    {
                        !!hasProducts && (
                            <ProductsForSale data={data?.products} onProductClick={() => buyTicket()} />
                        )
                    }
                </CardMainContent >
            </EventDetailHeadContent >

            {
                (hasTickets || hasProducts) && (
                    <Footer>
                        <Button
                            variant='featured'
                            onClick={() => buyTicket()}
                        >
                            {hasTickets && !hasProducts && 'Garantir ingresso'}
                            {!hasTickets && hasProducts && 'Garantir produto'}
                            {hasTickets && hasProducts && 'Garantir ingresso ou produto'}
                        </Button>
                    </Footer>
                )
            }

            <Description>
                <Text marginTop='30px' name='subtitle'>Descrição:</Text>
                {
                    event?.description_with_html
                        ? (
                            <DescriptionWithHtmlContainer>
                                <DescriptionWithHTML data={event?.description_with_html} />
                            </DescriptionWithHtmlContainer>
                        ) : (
                            <Text
                                color={colors.nightDark}
                                marginTop='2px'
                                marginBottom='16px'
                            >
                                {data.description}
                            </Text>
                        )
                }
            </Description>

            {
                (hasTickets || hasProducts) && (
                    <ContentInfos>
                        <Divider
                            marginTop={'20px'}
                            marginBottom='15px'
                            color={colors.primaryDashboard}
                            opacity={0.3}
                            textAlign='center'
                        />

                        <Text
                            marginTop='10px'
                            name='normal'
                            type='bold'
                            marginBottom={'6px'}
                            textAlign='center'
                        >
                            Informações importantes
                        </Text>

                        <Text
                            marginBottom={'6px'}
                            textAlign='center'
                        >
                            Para realizar cancelamentos através do Whizr, é necessário estar atento ao prazo máximo de pedido.
                        </Text>

                        <Text
                            marginBottom={'6px'}
                            textAlign='center'
                        >
                            Conforme os termos de uso e os direitos do consumidor, a solicitação poderá ser realizada em até 7 dias corridos da compra, antecedendo o evento em pelo menos 48h.
                        </Text>

                        <Text
                            marginBottom={'6px'}
                            textAlign='center'
                        >
                            Em casos de políticas ofertadas diretamente pelos organizadores, serão eles os responsáveis pelo cumprimento das mesmas, assim como pelo reembolso e validações necessárias.
                        </Text>

                        <Text textAlign='center'
                        >
                            Fique atento, e em caso de dúvidas sobre as políticas do evento, entre em contato com o organizador diretamente.
                        </Text>
                    </ContentInfos>
                )
            }

            {
                goToLoginModal && (
                    <Modal isOpen={goToLoginModal} onClose={closeModalNotAuth} >
                        <Column>
                            <Text type='bold' name='subtitle' textAlign={'center'}>
                                Você não está logado.
                            </Text>

                            <Text type='bold' color={colors.night} textAlign={'center'} marginTop={'14px'}>
                                Para realizar a compra, você precisa estar logado em sua conta.
                            </Text>

                            <Button
                                marginTop={'14px'}
                                onClick={() => navigate(paths.login, { state: { from: location } })}
                            >
                                Fazer login
                            </Button>
                        </Column>
                    </Modal>
                )
            }
        </Container >
    )
};

const Container = styled(Column)`   
    padding-bottom: 300px;
`

const StyledBackgroundContainer = styled(FullyCentered)`
    width: 100%;
    height: auto;  
    position: relative;
    background: ${colors.neutralDark};

    @media screen and (min-width: ${breakpoints.lg}) {
        & > img {
            filter: blur(10px);
        }
        
        &:after {
            background-image: ${({ bg }) => `url(${bg})`};
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat; 
            content: '';
            position: absolute;
            left: auto;
            bottom: 0;
            width: 50%;
            height: 100%;    
            border-radius: 8px;
            box-shadow: 0px 2px 5px #aaa;
        }
    }

    ${({ type }) => type === eventTypes.free.slug && ` 
        & > div {
            background: ${eventTypes.free.color};
            height: 200px;
            border-radius: 0 0 18px 18px;
        }
        
    `}

    ${({ type }) => type === eventTypes.paid.slug && `
        & > div {
            background: ${eventTypes.paid.color};
            height: 200px;
            border-radius: 0 0 18px 18px;
        }    
    `}
    
    ${({ isImage }) => isImage && `
        background: ${colors.neutralLight};
        width: 100%;
    `}
`;


const StyledBackground = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 0 0 18px 18px;
`;

const EventDetailHeadContent = styled(Column)`
    width: 100%;
    border-radius: 18px;
    box-shadow: 2px 4px 10px ${colors.nightLight};
`;

const ButtonContainer = styled(FullyCentered)`
    width: 100%;
    margin-top: 4px;
    flex-direction: column;
    padding: ${sizes.none} ${sizes.medium};

    @media screen and (min-width: ${breakpoints.sm}) {
        flex-direction: row;
        
        & > a:nth-child(2) {
            margin-left: 4% !important;
        }
    }
    
    @media screen and (min-width: ${breakpoints.lg}) {
        flex-direction: column;
        width: 300px;

        & > a:nth-child(2) {
            margin-left: 0% !important;
        }
    }
`;

const CategoriesInfos = styled(Row)`
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 15px;

    & > p {
        padding-bottom: 0;
    }

    @media screen and (min-width: ${breakpoints.lg}) {
        margin-bottom: 5px;    
        justify-content: flex-start;
    }
    
`;

const ContentTickets = styled(Column)`

    & > div > div > div {
       width: 100%;
       flex-direction: row;

    }
    & > div > div > div > div {
        border-right: unset;
        flex-direction: row;
        overflow-x: auto;
        width: 100%;
        height: 175px;
        & > div {
            margin-right: 35px;
        }
    }
   
    & > div > div > div > div > div >  div {
        margin-right: 10px;
        width: 100%;

        @media screen and (min-width: 425px) {
            width: 300px;
        }

        @media screen and (min-width: ${breakpoints.lg}) {
            width: 320px;
            &:after {
                background: ${colors.neutralBackground};
            }   
            &:before {
                background: ${colors.neutralBackground};
            }   
        }
    }
`;

const ContentProducts = styled(Column)`
    & > div > :first-child {
        margin: 10px 0 10px;
    }
    & > div > div:nth-of-type(3) {
        margin-top: 21px;
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        margin-bottom: 20px;
    }

    & > div > div:nth-of-type(3) > div > div {
        width: 300px;
        margin-right: 10px;
    }
`

const CardMainContent = styled(Column)`
    padding: 16px 6%;
    justify-content: center;
    align-items: center;   
    width: 100%; 

    & > h1 {
        margin-top: 6px;
        margin-bottom: 4px;
    }
`

const EventType = styled.div`
    color: ${colors.neutralLight};
    border-radius: 6px;
    padding: 4px 10px;
    font-size: ${fonts.sizes.small};
    text-transform: capitalize;

    ${({ type }) => type === eventTypes.free.slug && `
        background: ${eventTypes.free.color};
    `}

    ${({ type }) => type === eventTypes.paid.slug && `
        background: ${eventTypes.paid.color};
    `}
`;

const Description = styled(Column)`
    padding: 0 26px;
    align-items: center;
    text-align: left;
    
`;

const ContentIconContainer = styled.div`
    width: 33px;
    height: 33px;
    margin-right: 14px;
    margin-bottom: 5px;
`;

const ContentInfoRow = styled(Row)`
    margin-bottom: 10px;
    align-items: flex-start;
    text-align: left;

    ${({ address }) => address && `
        align-items: center;
    `}
    
    ${({ single }) => single && `
        align-items: center;

        & > p {
            padding-bottom: 0 !important;
        }
    `}

    &:nth-child(3){
        margin-bottom: 0;
    }

    & > p {
        padding: 0;
        margin: 0;
    }

    & > a > p{
        padding-bottom: 0 !important;
    }
`;

const ContentLinkAddress = styled.div`
    width: auto;
`;

const CronogramContainer = styled.div`
    width: 100%;
`;

const Footer = styled(FullyCentered)`
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 60px;
    padding: 25px 30px;
    border-radius: 20px 20px 0 0;
    background: ${colors.neutralLight};
    border: 1px solid #ddd;
    box-shadow: 0 -2px 8px #ddd;
    z-index: 10;
    & > button { 
        margin-bottom: 0;
    }
    @media screen and (min-width: ${breakpoints.sm}) {
        & > button { 
            width: 50%;
            margin-bottom: 0;
        }
    }
    @media screen and (min-width: ${breakpoints.lg}) {
        bottom: 0;
        width: 60%;
        background: ${colors.neutralBackground};
        padding: 15px 30px;
        & > button { 
            width: 40% !important;
            margin-bottom: 0;
        }
    }
`;

const DateContainer = styled.div`
    height: auto;
    margin-left: 10px;
`;

const OnlyOnedate = styled.div`
    height: 100%;
    margin-top: 8px;
`;


const Discount = styled(Column)`
    padding: 4px 10px;
    background: ${colors.successDark};
    width: auto;
    border-radius: 30px;
    & > p {
        padding: 0;
    }
`;

const Tag = styled(FullyCentered)`
    padding: 4px 8px;
    border-radius: 6px;
    background: ${colors.goldLight};
    width: auto;
    margin-left: 4px;

    ${({ adulthood }) => adulthood && `
        background: ${colors.nightDark};
    `};

    & > p {
        padding-bottom: 0 !important;
        text-transform: capitalize !important;
    }
`;

const StyledIa = styled.div`
    width: 26px;
    height: 26px;
`;

const ContentInfos = styled(Column)`
    padding: 0 6%;
`;


const ContentOrganizer = styled(Row)`
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    text-align: left;

    transition: all ease-in-out .25s;
    &:hover {
        transform: scale(1.01)
    }

`;


const ContainerOrganizerLink = styled(Row)`
    width: 100%;
`

const DescriptionWithHtmlContainer = styled(Column)`
    @media screen and (min-width: ${breakpoints.sm}) {
        padding: 0 26px;
    }
`;