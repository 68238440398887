import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import TabWallet from './slots/TabWallet';
import Text from 'components/_UI/Text';
import Header from './slots/Header';
import colors from 'theme/colors';
import Navbar from 'components/commons/Navbar';
import { useNavigate } from 'react-router-dom';
import ticketsApi from 'api/requests/tickets';
import OrdersList from './slots/OrdersList';
import WalletDetail from './detail/WalletDetail';
import { useCollapse } from 'context/general/Collapse';
import breakpoints from 'theme/breakpoints';
import { useBackButtonCollapse } from 'context/general/BackButtonCollapse';
import DashHeader from 'modules/dashboard/_common/DashHeader';

export default function MainWallet({ home }) {
  const [selectedTab, setSelectTab] = useState('active');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [finish, setFinish] = useState([]);
  const [active, setActive] = useState([]);
  const [selectedDetail, setSelectDetail] = useState(false)
  const { collapse, setCollapse } = useCollapse()
  const { backButtonCollapse, setBackButtonCollapse } = useBackButtonCollapse()

  useEffect(() => {

    setBackButtonCollapse({
      ...backButtonCollapse,
      from: 'wallet-detail',
      data: selectedTab,
    })
    if (selectedDetail) {

      setCollapse({
        ...collapse,
        withHideCity: false,
        componentToRender: () => (
          <WalletDetail
            withBackButton
            home
            onBack={() => back()}
            data={selectedDetail}
          />
        )
      });
    }
  }, [selectedDetail]);


  const handleTabClick = (item) => {
    setSelectTab(item);
  };

  function renderDetail(item) {
    setSelectDetail(item);
  }

  function back() {
    setSelectDetail(false)
  }


  const getTickets = async () => {
    try {
      const { data } = await ticketsApi.get();

      const currentDate = new Date();

      const finishedEvents = [];
      const activeEvents = [];

      data?.events?.forEach(event => {
        let allTicketsUsed = true;
        let eventDatePassed = false;

        event.tickets.forEach(ticket => {
          if (ticket.used_at === null) {
            allTicketsUsed = false;
          }
        });

        event.dates.forEach(dateItem => {
          const eventEndDate = new Date(`${dateItem.date}T${dateItem.end}`);
          if (eventEndDate < currentDate) {
            eventDatePassed = true;
          }
        });

        if (allTicketsUsed || eventDatePassed) {
          finishedEvents.push(event);
        } else {
          activeEvents.push(event);
        }
      });

      setFinish(finishedEvents);
      setActive(activeEvents);

    } catch (error) {
      // console.error('Erro ao buscar os tickets:', error);
    } finally {
      setIsLoading(false);
    }
  };

  function onSelectValidation() {
    setSelectValidation(selectValidation + 1);
  }


  useEffect(() => {
    getTickets();
  }, []);

  const renderContent = () => {
    return (
      <>
        {selectedTab === 'finish' && (
          <OrdersList
            data={finish}
            isLoading={isLoading}
            expired={true}
            home={home}
            renderDetail={renderDetail}
          />
        )}

        {selectedTab === 'active' && (
          <OrdersList
            home={home}
            data={active}
            isLoading={isLoading}
            renderDetail={renderDetail}
          />
        )}
      </>
    );
  };


  return (
    <Container>

      <DashHeader onSelectValidation={onSelectValidation} userView={true} />

      {!selectedDetail ? (
        <ContentListWallet>
          <Header title={'Carteira'} />
          <TabWallet toogleTab={handleTabClick} selectedTab={selectedTab} />

          {selectedTab && renderContent()}

          {!home && <Navbar />}
        </ContentListWallet>

      ) : (
        <StyledDetail>
          <WalletDetail
            withBackButton
            home
            onBack={() => back()}
            data={selectedDetail}
          />
        </StyledDetail>
      )

      }
    </Container>
  );
}

const Container = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  background: ${colors.neutralBackground};

  @media screen and (min-width: ${breakpoints.lg}) {
    background: ${colors.neutralBackground}
  }
`;

const StyledDetail = styled.div`
  background: ${colors.neutralBackground};
  padding-bottom: 10px;

  overflow: auto;
  @media screen and (min-width: ${breakpoints.lg}) {
    background: ${colors.neutralBackground};
  }

`


const ContentListWallet = styled(Column)`
  margin-top: 100px;
  padding-bottom: 100px;
  background: ${colors.neutralBackground};

`