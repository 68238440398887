import { useState, useEffect, useRef, forwardRef, useImperativeHandle, } from "react"
import styled from 'styled-components'
import Fieldset from "components/_UI/Fieldset"
import Radio from "components/_UI/Radio"
import TextArea from "components/_UI/TextArea"
import Text from "components/_UI/Text"
import Modal from "components/_UI/Modal"
import Button from "components/_UI/Button"
import { Row } from "styled/alignment/Row"
import { Column } from "styled/alignment/Column"
import colors from "theme/colors"
import { StyledLabel } from "styled/UI/StyledForm"
import ImageUpload from "components/_UI/ImageUpload"
import allowedFilesTypes from "texts/messages/allowedFilesTypes"
import validateInputFile from "helpers/validate-input-file"
import imageUploadApi from 'api/requests/imageUpload';

const CustomFieldsForm = forwardRef(({ customFields }, ref) => {
    const rootRef = useRef()
    const [customFieldsValues, setCustomFieldsValues] = useState({})
    const [customFieldsErrors, setCustomFieldsErrors] = useState({})
    const [modalTitle, setModalTitle] = useState()
    const [modalData, setModalData] = useState()

    const labelMaxLength = 200

    useImperativeHandle(ref, () => ({
        validate() {
            return validateAllFields()
        },
        getCustomFieldsValues() {
            return customFieldsValues
        },
        scrollIntoView: (options) => {
            rootRef.current.scrollIntoView(options)
        },
    }));

    useEffect(() => {
        setCustomFieldsValues((prev) => {
            return {
                ...prev,
                ...createCustomFieldsVariables(customFields)
            }
        })

        setCustomFieldsErrors((prev) => {
            return {
                ...prev,
                ...createCustomFieldsErrors(customFields)
            }
        })
    }, [])

    function createCustomFieldsVariables(customFields) {
        const result = {}

        customFields.forEach(field => {
            if (field.type === 'radio') {
                result[field.id] = []

                return
            }

            if (field.type === 'checkbox') {
                result[field.id] = []

                return
            }

            if (field.type === 'upload') {
                result[field.id] = {}

                return
            }

            result[field.id] = ''
        })

        return result
    }

    function createCustomFieldsErrors(customFields) {
        const result = {}

        customFields.forEach(field => {
            result[field.id] = ''
        })

        return result
    }

    function handleOnChange(field, value) {
        if (field.required) {
            setCustomFieldsErrors((prev) => {
                return {
                    ...prev,
                    [field.id]: value ? '' : 'Campo obrigatório'
                }
            })
        }

        setCustomFieldsValues((prev) => {
            return {
                ...prev,
                [field.id]: value
            }
        })
    }

    function handleRadioChange(field, value) {
        setCustomFieldsValues((prev) => {
            return {
                ...prev,
                [field.id]: [value]
            }
        })
    }

    function handleCheckboxChange(field, value) {
        if (customFieldsValues[field.id].includes(value)) {
            const newCheckboxValues = customFieldsValues[field.id].filter(fieldValue => fieldValue !== value)

            setCustomFieldsValues((prev) => {
                return {
                    ...prev,
                    [field.id]: newCheckboxValues
                }
            })

            return
        }

        setCustomFieldsValues((prev) => {
            return {
                ...prev,
                [field.id]: [...prev[field.id], value]
            }
        })
    }

    async function handleFileChange(field, [file]) {
        const additionalFileTypes = ['application/pdf']
        const validationFile = validateInputFile(file, 5, additionalFileTypes)

        if (!validationFile.isValid) {
            setCustomFieldsErrors((prev) => {
                return {
                    ...prev,
                    [field.id]: validationFile.message
                }
            })
            return
        }

        const formData = new FormData()
        formData.append('image', file)
        formData.append('folder', 'event_custom_fields')

        try {
            const { data } = await imageUploadApi.uploadWithFolder(formData)

            setCustomFieldsValues((prev) => {
                return {
                    ...prev,
                    [field.id]: {
                        id: data.id,
                        url: data.path
                    }
                }
            })
        } catch (error) {
            setCustomFieldsErrors((prev) => {
                return {
                    ...prev,
                    [field.id]: error?.response?.data?.message || error?.message
                }
            })
        }
    }

    async function handleFileDelete(field) {
        const fileId = customFieldsValues[field.id].id

        setCustomFieldsErrors((prev) => {
            return {
                ...prev,
                [field.id]: ''
            }
        })

        if (!fileId) {
            return
        }

        try {
            await imageUploadApi.delete(fileId)

            setCustomFieldsValues((prev) => {
                return {
                    ...prev,
                    [field.id]: {}
                }
            })
        } catch (error) {
            setCustomFieldsErrors((prev) => {
                return {
                    ...prev,
                    [field.id]: error?.response?.data?.message || error?.message
                }
            })
        }
    }

    function validateAllFields() {
        let hasError = false

        Object.values(customFields).forEach((field) => {
            if (field.required) {
                if (field.type === 'radio') {
                    setCustomFieldsErrors((prev) => {
                        return {
                            ...prev,
                            [field.id]: customFieldsValues[field.id].length ? '' : 'Campo obrigatório'
                        }
                    })

                    hasError = customFieldsValues[field.id].length === 0

                    return
                }

                if (field.type === 'checkbox') {
                    setCustomFieldsErrors((prev) => {
                        return {
                            ...prev,
                            [field.id]: customFieldsValues[field.id].length ? '' : 'Campo obrigatório'
                        }
                    })

                    hasError = customFieldsValues[field.id].length === 0

                    return
                }

                if (field.type === 'upload') {
                    setCustomFieldsErrors((prev) => {
                        return {
                            ...prev,
                            [field.id]: customFieldsValues[field.id]?.url ? '' : 'Campo obrigatório'
                        }
                    })

                    hasError = !customFieldsValues[field.id]?.url

                    return
                }

                setCustomFieldsErrors((prev) => {
                    return {
                        ...prev,
                        [field.id]: customFieldsValues[field.id] ? '' : 'Campo obrigatório'
                    }
                })

                hasError = !customFieldsValues[field.id].length
            }
        })

        return hasError
    }

    function showDataModal (title, data) {
        setModalTitle(title)
        setModalData(data)
    }

    return (
        <CustomFieldsContainer
            ref={rootRef}
        >
            {
                customFields.map(field => {
                    if (field.type === 'radio') {
                        return (
                            <div
                                key={field.id}
                            >
                                <StyledLabel>{field.label}</StyledLabel>
                                <Row gap={'.5rem'} marginTop='6px'>
                                    {
                                        field.options.map(option => {
                                            let truncatedLabel
                                            if (option.length > labelMaxLength) {
                                                truncatedLabel = option.substring(0, labelMaxLength) + '...'
                                            }

                                            return (
                                                <div>
                                                    <Radio
                                                        key={option}
                                                        label={truncatedLabel || option}
                                                        selected={customFieldsValues[field.id]?.some(value => value === option)}
                                                        onSelect={() => handleRadioChange(field, option)}
                                                        marginTop={'0'}
                                                    />
                                                    {
                                                        truncatedLabel &&
                                                            <Button
                                                                category="outline"
                                                                marginLeft='24px'
                                                                style={{width: 'auto'}}
                                                                onClick={() => showDataModal(field.label, option)}
                                                            >
                                                                Ver mais
                                                            </Button>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </Row>
                                <Text
                                    color={colors.danger}
                                    name='small'
                                >
                                    {customFieldsErrors[field.id]}
                                </Text>
                            </div>
                        )
                    }

                    if (field.type === 'checkbox') {
                        return (
                            <div
                                key={field.id}
                            >
                                <StyledLabel>{field.label}</StyledLabel>
                                <Row gap={'.5rem'} marginTop='6px'>
                                    {
                                        field.options.map(option => {
                                            return (
                                                <SelectItem
                                                    key={option}
                                                    selected={customFieldsValues[field.id]?.includes(option)}
                                                    onClick={() => handleCheckboxChange(field, option)}
                                                >
                                                    <Text
                                                        name='mini'
                                                        textAlign={'center'}
                                                        paddingBottom={0}
                                                        type='bold'
                                                        ellipsisAt={'98%'}
                                                        color={customFieldsValues[field.id]?.includes(option) ? colors.neutral : colors.night}
                                                    >
                                                        {option}
                                                    </Text>
                                                </SelectItem>
                                            )
                                        })
                                    }
                                </Row>
                                <Text
                                    color={colors.danger}
                                    name='small'
                                >
                                    {customFieldsErrors[field.id]}
                                </Text>
                            </div>
                        )
                    }

                    if (field.type === 'textarea') {
                        return (
                            <div
                                key={field.id}
                            >
                                <StyledLabel>{field.label}</StyledLabel>
                                <TextArea
                                    marginTop='6px'
                                    name={field.label}
                                    register={() => { }}
                                    squired
                                />
                                <Text
                                    color={colors.danger}
                                    name='small'
                                >
                                    {customFieldsErrors[field.id]}
                                </Text>
                            </div>
                        )
                    }

                    if (field.type === 'upload') {
                        return (
                            <div>
                                <Text size='small' type='bold' color={colors.nightDark}>
                                    {field.label}
                                </Text>
                                <FileContainer>
                                    <ImageUpload
                                        onToggleFile={(file) => handleFileChange(field, file)}
                                        onDeleteFile={() => handleFileDelete(field)}
                                        product={false}
                                        isEdit={false}
                                        refreshTryAgain={() => {
                                            setCustomFieldsErrors((prev) => {
                                                return {
                                                    ...prev,
                                                    [field.id]: ''
                                                }
                                            })
                                        }}
                                    />
                                </FileContainer>

                                <Column verticalCenter>
                                    <Text name='small' marginTop={'10px'}>Arquivos permitidos: {allowedFilesTypes.imagesAndPdf}.</Text>
                                    <Text
                                        color={colors.danger}
                                        name='small'
                                    >
                                        {customFieldsErrors[field.id]}
                                    </Text>
                                </Column>
                            </div>
                        )
                    }

                    return (
                        <div
                            key={field.id}
                        >
                            <Fieldset
                                layout='squired'
                                inputType={field.type}
                                label={field.label}
                                onChangeValue={(value) => handleOnChange(field, value)}
                            />
                            <Text
                                color={colors.danger}
                                name='small'
                            >
                                {customFieldsErrors[field.id]}
                            </Text>
                        </div>
                    )
                })
            }
            <Modal
                size='large'
                title={modalTitle}
                isOpen={Boolean(modalData)}
                closeButton={true}
                onClose={() => setModalData(false)}
            >
                <Text color={colors.night}>
                    { modalData }
                </Text>
            </Modal>
        </CustomFieldsContainer>
    )
})

const CustomFieldsContainer = styled.div`
    width: 100%;
`

const SelectItem = styled.div`
    width: 30%;
    margin-bottom: 3%;
    border: 1px solid ${colors.night};
    padding: 10px 5px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    ${({ selected }) => selected && `
        background: #4617ad;
        border: 1px solid #4617ad;
        & > p {
            color: ${colors.neutral} !important;
        }
    `};
`;

const FileContainer = styled.div`
    & > div > div {
        width: 110px;
        height: 110px;
    }
`;

export default CustomFieldsForm