import api from "api";

const financial = {
    get: () => {
        return api().get('/financial/transaction-summary');
    },

    getBanks: () => {
        return api().get('/bank-account/banks');
    },

    getWithdrawData: (params) => {
        return api().get('/financial/withdraw', {
            params: { ...params }
        });
    },

    withdraw: (payload) => {
        return api().post('/financial/withdraw', payload);
    },

    registerBankAccount: (payload) => {
        return api().post('/bank-account', payload);
    },

    getBankAccount: () => {
        return api().get('/bank-account');
    },

    editBankAccount: (accountId, payload) => {
        return api().put(`/bank-account/${accountId}`, payload);
    },

    deleteBankAccount: (accountId) => {
        return api().delete(`/bank-account/${accountId}`);
    },

    myInvoices: (page) => {
        return api().get(`/nfse/my-nfse?page=${page}`);
    },

    receiptSchedule: (mounth, year) => {
        return api().get(`/financial/scheduled-payments?month=${mounth}&year=${year}`);
    }
}

export default financial;