import ButtonSelect from 'components/_UI/ButtonSelect'
import { ButtonSelectItem, SelectContainer, SelectWrapper } from 'components/_UI/SelectsContent'
import Text from 'components/_UI/Text'
import { useTags } from 'context/events/Tags'
import React, { useState, useEffect } from 'react'
import { Column } from 'styled/alignment/Column'
import colors from 'theme/colors'
import tagsApi from 'api/requests/tags'
import Loader from 'components/_UI/Loader'
import buttonText from 'texts/buttons/buttonText'
import Button from 'components/_UI/Button'
import sizes from 'theme/sizes'
import paths from 'routes/paths'
import styled from 'styled-components'
import { useUser } from 'context/user/User'
import userApi from 'api/requests/user'
import step from 'helpers/system/step'
import { FullyCentered } from 'styled/alignment/Center'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useStepRegister } from 'context/login/StepRegister'

const categories = [];

const errorMessages = {
    tags: 'Ocorreu um erro ao carregar, tente novamente.',
    account: 'Ocorreu um erro ao criar sua conta, tente novamente.'
}

export default function OnboardingTags() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { stepRegister, setStepRegister } = useStepRegister();
    const { tags, setTags } = useTags();
    const { user, setUser } = useUser();

    const [tagsError, setTagsError] = useState(false);
    const [createAccountError, setCreateAccountError] = useState(false);
    const [loadCategories, setLoadCategories] = useState(false);
    const [loadSubmit, setLoadSubmit] = useState(false);

    const [selectedTags, setSelectedTags] = useState();

    useEffect(() => {
        getCategories()
    }, [])

    async function getCategories() {
        if (tags) {
            return
        }

        setLoadCategories(true)
        setTagsError(false)

        try {
            const { data } = await tagsApi.get()
            setTags(data)
        } catch (error) {
            setTagsError(true)
        } finally {
            setLoadCategories(false)
        }
    }

    function categoriesFilter(active, item) {
        const categoriesObject = {
            active: active === 'ACTIVE' || false,
            name: item.name,
            id: item.id
        }
    
        if (active === 'INATIVE') {
            const newArray = removeObjectWithId(categories, categoriesObject.id)
    
            if (newArray.length === 0) {
                setSelectedTags(null);
            } else {
                setSelectedTags({ ...selectedTags, tags: newArray });
            }
    
            return;
        }
    
        categories.push(categoriesObject);
        setSelectedTags({ ...selectedTags, tags: categories });
    }

    function removeObjectWithId(arr, id) {
        const objWithIndex = arr.findIndex(obj => obj.id === id);
    
        if (objWithIndex > -1) {
            arr.splice(objWithIndex, 1);
        }
    
        return [...arr]; 
    }

    async function createAccount() {
        const payload = {
            ...user,
            tags: selectedTags?.tags?.map(tag => tag.id)
        }
                
        setLoadSubmit(true);
        setCreateAccountError(false);
        setUser({...user, tags: selectedTags?.tags?.map(tag => tag.id)})
        
        try {
            await userApi.create(payload);
            navigate({
                pathname: paths.login,
                search: new URLSearchParams({
                    ...Object.fromEntries(searchParams),
                    confirmEmail: 1
                }).toString()
            });
        } catch (error) {
            setCreateAccountError(true)
        } finally {
            setLoadSubmit(false)
        }
    }

    return (
        <Column>
            <Column verticalCenter>
                <Text color={colors.nightDark} type='bold' name='highlight'>Para finalizar, selecione as categorias</Text>
                <Text color={colors.nightDark} type='bold' name='highlight'> que se encaixem no seu gosto.</Text>
            </Column>

            <CategoriesContainer>
                {
                    loadCategories ? <Loader /> : (
                        <SelectContainer>
                            {
                                tags?.map((el) => (
                                    <SelectWrapper key={el.id}>
                                        <ButtonSelectItem>
                                            <ButtonSelect
                                                size={'small'}
                                                onToggle={(state) => categoriesFilter(state, el)}
                                            >
                                                {el.name}
                                            </ButtonSelect>
                                        </ButtonSelectItem>
                                    </SelectWrapper>
                                ))
                            }
                        </SelectContainer>
                    )
                }
                {tagsError && (
                    <FullyCentered>
                        <Text color={colors.danger}> {errorMessages.tags} </Text>
                    </FullyCentered>
                )}
            </CategoriesContainer>

            {
                loadSubmit
                    ? <Loader />
                    : (
                        <>
                            <Button
                                category='roundedBorded'
                                variant={selectedTags?.tags?.length ? 'primary' : 'disabled'}
                                size='large'
                                marginTop={sizes.big}
                                onClick={() => createAccount()}
                            >
                                {buttonText.confirm}
                            </Button>

                            <Button
                                category='minimalDark'
                                size='large'
                                marginTop={sizes.nano}
                                onClick={() => { setStepRegister(step.back(stepRegister)) }}
                            >
                                {buttonText.back}
                            </Button>
                        </>

                    )
            }
            {createAccountError && (
                <FullyCentered>
                    <Text color={colors.danger}> {errorMessages.account} </Text>
                </FullyCentered>
            )}
        </Column>
    )
}


const CategoriesContainer = styled(Column)`
    width: 100%;
    max-height: 320px;
    overflow: auto;
    margin: 30px 0;
`;