import { useCheckout } from 'context/checkout/Checkout';
import React, { useEffect, useState } from 'react';
import { Column } from 'styled/alignment/Column';
import colors from 'theme/colors';
import { CheckCircle, Copy } from 'react-feather';
import Text from 'components/_UI/Text';
import { Row } from 'styled/alignment/Row';
import styled from 'styled-components';
import Button from 'components/_UI/Button';
import { FullyCentered } from 'styled/alignment/Center';
import breakpoints from 'theme/breakpoints';
import pusherService from 'services/pusher';
import Modal from 'components/_UI/Modal';
import { useCollapse } from 'context/general/Collapse';
import buttonText from 'texts/buttons/buttonText';
import useToast from 'hooks/useToast';
import ToastContainer from 'components/_UI/ToastsContainer';
import ExpirationModal from '../commom/ExpirationModal';
import QRCode from 'qrcode.react';
import MainWallet from 'modules/users/wallet/MainWallet';
import { useSearchParams } from 'react-router-dom';
import routeParams from 'theme/routeParams';
import paths from 'routes/paths';


export default function PixCode({ time }) {
    const { checkout } = useCheckout();
    const { collapse, setCollapse } = useCollapse();
    const { toast } = useToast();
    const [searchParams, setSearchParams] = useSearchParams();

    const [timeLeft, setTimeLeft] = useState(time - 5); // 15 minutos em segundos
    const [progress, setProgress] = useState(1); // 100% de progresso
    const [messages, setMessages] = useState([]);
    const [pixCode, setPixCode] = useState();
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const [expirationModalVisible, setExpirationModalVisible] = useState(false);
    const [modalCopySuccess, setModalCopySuccess] = useState(false)
    const [remainingTime, setRemainingTime] = useState(time - 5)

    useEffect(() => {

        if (timeLeft <= 0) {
            setExpirationModalVisible(true);
            return;
        }

        const interval = setInterval(() => {
            setTimeLeft(prevTime => prevTime - 1);
        }, 1000);

        return () => clearInterval(interval);

    }, [timeLeft]);

    useEffect(() => {
        setProgress(timeLeft / remainingTime);
    }, [timeLeft]);

    useEffect(() => {
        const parsedOrderId = String(checkout?.orderId);

        setPixCode(checkout?.pix?.key);

        if (parsedOrderId) {
            const handleEvent = (data) => {
                setMessages((prevMessages) => [...prevMessages, data.message]);
                setSuccessModalVisible(true);
            };

            pusherService.subscribe(`order.${parsedOrderId}`, 'order.status.updated', handleEvent);

            // return () => {
            //     pusherService.unsubscribe(`order.${parsedOrderId}`, 'order.status.updated', () => false );
            // };
        }
    }, [checkout?.orderId]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    function goToWallet() {
        setSuccessModalVisible(false);
        navigate(paths.user.wallet);
        // setCollapse({
        //     ...collapse,
        //     arrowPosition: false,
        //     componentToRender: () => <MainWallet />
        // })
    }

    function goToHome() {
        setSearchParams((prevSearchParams) => {
            const newSearchParams = new URLSearchParams(prevSearchParams);
            newSearchParams.delete(routeParams.payments.checkout)

            return newSearchParams;
        });
        setSuccessModalVisible(false);
        setCollapse({
            ...collapse,
            height: 'min',
            withContent: false,
        })
    }

    async function copyToClipboard() {
        if (navigator?.clipboard?.writeText) {
            try {
                await navigator.clipboard.writeText(pixCode)
                setModalCopySuccess(true)
            } catch (error) {
                copyToClipboardFallback(pixCode)
            }
        }
    }


    function copyToClipboardFallback(value) {
        const input = document.createElement('input');
        input.value = value;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        setModalCopySuccess(true)
    }

    return (
        <Container>


            {/* <ToastContainer toasts={toasts} /> */}

            {messages.map((el, index) => (
                <Text key={index}>
                    {el}
                </Text>
            ))}

            <Column verticalCenter marginBottom='30px'>
                <Text name='highlight' textAlign={'center'}>
                    Escaneie o QR code e efetue o pagamento.
                </Text>
                <StyledQrcode>
                    <QRCode
                        value={pixCode}
                        size={256} // Size of the QR code
                        bgColor={"#ffffff"} // Background color
                        fgColor={"#000000"} // Foreground color
                        level={"Q"} // Error correction level ('L', 'M', 'Q', 'H')
                        includeMargin={false} // Whether to include a margin around the QR code
                    />
                </StyledQrcode>
            </Column>

            <Column>
                <Text name='highlight' textAlign={'center'}>
                    Copie o código e efetue o pagamento.
                </Text>

                <Text textAlign={'center'}>
                    Para efetuar o pagamento, basta copiar o código e pagar através do aplicativo do seu banco ou sua carteira digital.
                </Text>
            </Column>

            <PIXField>
                <Text name='highlight' ellipsisAt={'95%'} type='bold' paddingBottom={0}>
                    {pixCode}
                </Text>

                <CopyButton onClick={copyToClipboard}>
                    <Copy size={22} color={colors.primaryLight} />
                </CopyButton>
            </PIXField>



            <Text ellipsisAt={'90%'} type='bold' color={colors.night} marginTop={'25px'}>
                O tempo para você pagar acaba em:
            </Text>

            <Text name='subtitle' type='bold' color={colors.error} marginTop={'15px'}>
                {formatTime(timeLeft)}
            </Text>

            <ProgressBarContainer>
                <ProgressBarBackground>
                    <ProgressBarFill progress={progress} />
                </ProgressBarBackground>
            </ProgressBarContainer>

            <Button marginTop={'30px'} onClick={copyToClipboard}>
                Copiar código
            </Button>



            {/* <img src={checkout?.pix?.qr_code}
                style={{
                    width: '260px',
                    height: '300px'
                }}
            /> */}

            {
                modalCopySuccess &&
                <Modal isOpen={modalCopySuccess} onClose={() => setModalCopySuccess(false)}>
                    <Column verticalCenter>
                        <Row fullyCentralized>
                            <CheckCircle color={colors.successDark} />
                            <Text paddingBottom={0} marginLeft={'5px'} type='bold' color={colors.night}>Código copiado com sucesso!</Text>
                        </Row>

                        <ContentButton>
                            <Button onClick={() => setModalCopySuccess(false)} marginTop={'20px'}>
                                {buttonText.understood}
                            </Button>
                        </ContentButton>
                    </Column>
                </Modal>
            }

            {
                successModalVisible && (
                    <Modal isOpen={successModalVisible} closeButton={false}>
                        <Row fullyCentralized>
                            <CheckCircle color={colors.successDark} />
                            <Text name='highlight' paddingBottom={0} marginLeft={'5px'} color={colors.successDark}>
                                Pagamento efetuado.
                            </Text>
                        </Row>

                        <Text marginTop={'20px'}>Seu pagamento foi processado.</Text>
                        <Text marginTop={'10px'}>Você receberá um e-mail em breve com todas as informações sobre o seu pedido, incluindo detalhes do ingresso/produto adquirido e instruções adicionais.</Text>
                        <Text marginTop={'10px'}>Agradecemos pela sua compra e esperamos que você aproveite ao máximo sua experiência!</Text>

                        <RowButtons>
                            <Button
                                variant='light'
                                marginRight={'12px'}
                                onClick={goToHome}

                            >
                                {buttonText.close}
                            </Button>

                            <Button variant='lightBlue' onClick={goToWallet}>
                                {buttonText.seeWallet}
                            </Button>
                        </RowButtons>
                    </Modal>
                )
            }

            {expirationModalVisible && <ExpirationModal visible={expirationModalVisible} />}
        </Container>
    );
}

const Container = styled(FullyCentered)`
    flex-direction: column;
    margin-top: 40px;
    padding: 0 30px;

    @media screen and (min-width: ${breakpoints.lg}){
        padding: 0 150px;
        /* margin-top: 150px; */
    }
`;

const PIXField = styled(Row)`
    border: 1px dashed ${colors.nightLight};
    padding: 15px 5px;
    padding-right: 40px;
    position: relative;
    margin-top: 25px;
`;

const CopyButton = styled.div`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 14px;
`;

const ProgressBarContainer = styled.div`
    width: 100%;
    margin-top: 20px;
`;

const ProgressBarBackground = styled.div`
    width: 100%;
    height: 10px;
    background-color: #ccc;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
`;

const ProgressBarFill = styled.div`
    height: 100%;
    background-color: ${colors.primaryLight};
    
    ${({ progress }) => progress && `
        width: ${progress * 100}%;
    `};
`;

const RowButtons = styled(Row)`
  width: 100%;
  margin-top: 20px;

  & > button {
    box-shadow: unset;
  }
`;


const ContentButton = styled(Row)`
    width: 250px;
`;

const StyledQrcode = styled(Row)`
    width: 256px;
    height: 256px;
    margin-top: 20px;
`;

