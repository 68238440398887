import financialApi from "api/requests/financial";
import Loader from "components/_UI/Loader";
import Text from "components/_UI/Text";
import currency from "helpers/currency";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { CheckCircle, Clock } from "react-feather";
import styled from "styled-components";
import { FullyCentered } from "styled/alignment/Center";
import { Column } from "styled/alignment/Column";
import colors from "theme/colors";
import fonts from "theme/fonts";

// const data = [
//   { payment_day: "2025-02-27", total_amount: "10.00" },
//   { payment_day: "2025-03-01", total_amount: "4000.00" },
//   { payment_day: "2025-03-10", total_amount: "90.00" },
//   { payment_day: "2025-03-08", total_amount: "500.00" },
// ];

export default function ReceiptsCalendar() {
  const today = new Date();
  const [currentDate, setCurrentDate] = useState(today); // Mês/ano exibido
  const [selectedDate, setSelectedDate] = useState(null); // Dia selecionado pelo usuário
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getReceiptSchedule();
  }, [currentDate]); // Dispara o request ao mudar de mês/ano

  async function getReceiptSchedule() {
    setLoading(true);
    try {
      const { data } = await financialApi.receiptSchedule(
        currentDate.getMonth() + 1,
        currentDate.getFullYear()
      );
      setContent(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  return (
    <CalendarContainer>
      <Text name="highlight" paddingBottom={'30px'}>Agenda de recebimentos</Text>

      {loading ? (
        <FullyCentered height='300px'>
          <Loader />
        </FullyCentered>
      ) : (
        <StyledCalendar
          value={currentDate}
          onChange={(date) => setSelectedDate(date)}
          onActiveStartDateChange={({ activeStartDate }) => {
            if (activeStartDate) {
              setCurrentDate(activeStartDate); // Atualiza o mês/ano ao navegar
            }
          }}
          tileContent={({ date, view }) => {
            if (view === "month") {
              const event = content?.find(
                (item) => new Date(item.payment_day).toDateString() === date.toDateString()
              );
              const isPast = date < today;

              return (
                <Column>
                  {event ? (
                    <>
                      <Text
                        color={isPast ? colors.success : colors.occasionalOrange}
                        type="bold"
                        marginTop={'10px'}
                        paddingBottom={'4px'}
                      >
                        {currency.format(event.total_amount)}
                      </Text>
                      <FullyCentered>
                        {isPast ? (
                          <CheckCircle color={colors.success} size={14} />
                        ) : (
                          <Clock color={colors.occasionalOrange} size={14} />
                        )}
                        <Text
                          name="small"
                          color={isPast ? colors.success : colors.occasionalOrange}
                          paddingBottom={0}
                          marginLeft={'4px'}
                        >
                          {isPast ? "Liberado" : "A liberar"}
                        </Text>
                      </FullyCentered>
                    </>
                  ) : (
                    <Text marginTop={'10px'} name="small">
                      {isPast ? "Não houve depósitos" : "Não há depósitos"}
                    </Text>
                  )}
                </Column>
              );
            }
          }}
        />
      )}
    </CalendarContainer>
  );
}


const CalendarContainer = styled(Column)`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
  background-color: ${colors.neutralLight};
  border-radius: 8px;
  padding: 20px;
  margin-top: 10px;
  box-shadow: 0 2px 7px #ddd;
`;

const StyledCalendar = styled(Calendar)`
  width: 100%;
  height: auto;
  font-size: 16px;
  border: 1px solid #ccc;

  .react-calendar__navigation__label__labelText {
    font-size: ${fonts.sizes.subtitle};
    font-weight: bold;
    color: ${colors.secondaryPurple};
    padding-bottom: 0;
  }

  .react-calendar__tile--active {
    background-color: ${colors.neutral} !important;
    color: ${colors.night};
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${colors.nightLight} !important;
  }

  .react-calendar__month-view__days__day--weekend {
    color: ${colors.nightDark}
  }

  .react-calendar__navigation {
    margin: 6px 0;
  }

  .react-calendar__navigation__label__labelText {
    text-transform: capitalize;
  }

  .react-calendar__navigation__prev-button {
      font-size: 30px;
      color: ${colors.secondaryPurple};
    }
  
    .react-calendar__navigation__next-button {
      font-size: 30px;
      color: ${colors.secondaryPurple};
    }

    .react-calendar__navigation__prev2-button {
      display: none !important;
    }
    
    .react-calendar__navigation__next2-button {
      display: none !important;
    }

  .react-calendar__tile {
    padding: 15px;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #ccc;
  }

  .react-calendar__tile--now {
    background: #eee;

    &:hover{
        background: #ccc;
    }
  }

  /* Estiliza o cabeçalho */
  .react-calendar__month-view__weekdays {
    color: ${colors.secondaryPurple};
    font-size: ${fonts.sizes.highlight};
    font-weight: bold;
    border-top: 1px solid #ccc;
    padding: 10px 0;
    text-align: center;
    
    abbr {
    text-decoration: none;
    }
  }

  .react-calendar__tile abbr {
    font-weight: bold;
  }
`;
