// import Navbar from 'components/commons/Navbar'
import CheckoutProvider from 'context/checkout/Checkout'
import EventProvider from 'context/events/Event'
import CollapseProvider from 'context/general/Collapse'
import OnSelectMenuProvider from 'context/general/OnSelectMenu'
import DashHeader from 'modules/dashboard/_common/DashHeader'
import DashHeaderActions from 'modules/dashboard/_common/DashHeaderActions'
import FullPage from 'modules/events/page/FullPage'
import React, { useState } from 'react'
import styled from 'styled-components'
import breakpoints from 'theme/breakpoints'

export default function EventPage() {
    const [selectValidation, setSelectValidation] = useState(0);


    function onSelectValidation() {
        setSelectValidation(selectValidation + 1);
    }

    return (
        <EventProvider>
            <CheckoutProvider>
                <CollapseProvider>
                    <OnSelectMenuProvider>
                        <StyledHeader>
                            <DashHeader onSelectValidation={onSelectValidation} userView={true} />
                            <DashHeaderActions />
                        </StyledHeader>
                        
                        <FullPage />

                    </OnSelectMenuProvider>
                </CollapseProvider>
            </CheckoutProvider>
        </EventProvider>
    )
}

const StyledHeader = styled.span`
    display: none;
    @media screen and (min-width: ${breakpoints.lg}) {
        display: flex;
    }
`;