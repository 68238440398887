import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import checkoutApi from 'api/requests/checkout';
import eventsApi from 'api/requests/events';
import { useEvent } from 'context/events/Event';
import { Column } from 'styled/alignment/Column';
import Navbar from 'components/commons/Navbar';
import PageCover from './cover/PageCover';
import Loader from 'components/_UI/Loader';
import PageContent from './content/PageContent';
import colors from 'theme/colors';
import breakpoints from 'theme/breakpoints';
import { FullyCentered } from 'styled/alignment/Center';
import Ilustration from 'components/_UI/Ilustration';
import Text from 'components/_UI/Text';
import Button from 'components/_UI/Button';
import paths from 'routes/paths';

export default function FullPage() {
  const { slug } = useParams();
  const { event, setEvent } = useEvent();
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (slug) {
      getEvent(slug);
    }
  }, [slug]);

  async function getEvent(slug) {
    setLoading(true);
    try {
      const { data } = await eventsApi.getBySlug(slug);
      setEvent(data?.data);
    } catch ({ response }) {
      if (response?.status == 404) {
        setShowError(true)
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <Navbar />
      {
        loading ? (
          <FullyCentered marginTop='200px'>
            <Loader size='large' />
          </FullyCentered>
        ) : (
          showError ? (
            <Column fullyCentralized marginTop='100px' paddingLeft='10px' paddingRight='10px'>
              <Column height='250px' marginBottom='30px'>
                <Ilustration name='not-found' />
              </Column>
              <Text name='highlight' textAlign={'center'}>Não encontramos nenhum resultado na sua busca.</Text>
              <Text name='highlight' textAlign={'center'}>Que tal explorar novas expêriencias?</Text>
              <Column width='300px' marginTop='20px'>
                <Button variant='featured' path={paths.events.home}>Buscar expêriencias</Button>
              </Column>
            </Column>
          ) : (
            <>
              <PageCover data={event} />
              <PageContent data={event} />
            </>
          )
        )
      }
    </Container >
  );
}

const Container = styled(Column)`
  width: 100%;
  
  @media screen and (min-width: ${breakpoints.lg}) {
    margin-top: 100px;
  }
`;
