import Button from 'components/_UI/Button';
import InfoTooltip from 'components/_UI/InfoTooltip';
import LinkPage from 'components/_UI/LinkPage';
import Text from 'components/_UI/Text';
import DynamicProfileImage from 'components/commons/DynamicProfileImage';
import date from 'helpers/date';
import types from 'helpers/system/types';
import CronogramModal from 'modules/events/detail/CronogramModal';
import React, { useState } from 'react'
import { Calendar, MapPin } from 'react-feather';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import eventTypes from 'theme/eventTypes';
import fonts from 'theme/fonts';

export default function PageCover({ data }) {
    const textIa = 'kaue.ia'

    const [fullCronogramModal, setFullCronogramModal] = useState(false)
    const [goToLoginModal, setGoToLoginModal] = useState(false)

    return (
        <Container>
            <BluredBackground src={data?.images[0] || '/assets/images/placeholder-events.png'} />

            <ImageContainer>
                {
                    data?.images[0] ? (
                        <StyledBackground src={data?.images[0]} style={{position: 'relative'}} />
                    ) : <StyledBackground src={'/assets/images/placeholder-events.png'} style={{position: 'relative'}} />
                }

                {data?.is_past && (
                    <FinishedEvent />
                )}
            </ImageContainer>


            <CardMainContent>
                <Text
                    color={colors.nightDark}
                    name='subtitle'
                    type='bold'
                    paddingTop={'12px'}
                    paddingBottom={'6px'}
                >
                    {data?.name}
                </Text>

                {/* {
                        data?.discount_type && data?.discount && (
                            <Row>
                                <Discount marginBottom='10px'>
                                    <Text
                                        color={colors.neutral}
                                        type='bold'
                                        name='small'
                                    >
                                        {
                                            data.discount_type === 'value'
                                                ? `R$ ${data.discount},00 de desconto`
                                                : `${data.discount}% de desconto`
                                        }
                                    </Text>
                                </Discount>
                            </Row>
                        )
                    } */}

                <CategoriesInfos>
                    <EventType type={data?.classification?.name}>
                        <Text
                            paddingBottom={0}
                            type='bold'
                            color={colors.neutral}
                            name='small'
                        >
                            {types.getBySlug(data?.classification?.name).label}
                        </Text>
                    </EventType>

                    {
                        data?.adulthood && (
                            <Tag adulthood>
                                <Text
                                    color={colors.neutralLight}
                                    type='bold'
                                    name='small'
                                > +18
                                </Text>
                            </Tag>
                        )
                    }

                    {data?.tags?.map(el => (
                        <Tag key={el.name}>
                            <Text name='small' color={colors.neutralLight} type='bold'>
                                {el.name}
                            </Text>
                        </Tag>
                    ))}
                </CategoriesInfos>

                <br />

                {
                    !data?.hide_address && (
                        <ContentInfoRow address>
                            <MapPin color={colors.night} size={24} style={{ marginRight: 10 }} />
                            {
                                data?.address ? (
                                    <ContentLinkAddress>
                                        <LinkPage isWrapper to={data.link_map}>
                                            <Text color={colors.primaryDark} decoration='underline' paddingBottom={'0px'}>
                                                {data?.address?.street?.name && `${data?.address?.street?.name},`} {data?.address?.street?.district && `Bairro ${data?.address?.street?.district}`}
                                                {data.address.number && `, nº ${data.address.number}`}
                                                {data.address.complement && ` - ${data.address.complement}`}
                                            </Text>
                                        </LinkPage>
                                    </ContentLinkAddress>
                                ) : (
                                    <Text color={colors.night} paddingBottom={'0px'}>
                                        Local a definir
                                    </Text>
                                )
                            }
                        </ContentInfoRow>
                    )
                }

                <ContentInfoRow single={data.dates.length === 1}>
                    <Calendar color={colors.night} size={24} />

                    <DateContainer>
                        {
                            data.dates.length > 1 ? (
                                <>
                                    <Text
                                        color={colors.nightDark}>
                                        <b>Dia 1</b> - {date.getDay(data?.dates[0].date)}
                                        &nbsp;de {date.getMounthName(data?.dates[0].date)}
                                        &nbsp;- {date.getTimeWithoutSeconds(data?.dates[0].start)}
                                        {data?.dates[0].end ? ` até ${date.getTimeWithoutSeconds(data?.dates[0].end)}` : ''}
                                    </Text>

                                    <Text color={colors.nightDark}>
                                        <b>Dia 2</b> - {date.getDay(data?.dates[1].date)}
                                        &nbsp;de {date.getMounthName(data?.dates[1].date)}
                                        &nbsp;- {date.getTimeWithoutSeconds(data?.dates[1].start)}
                                        {data?.dates[1].end ? ` até ${date.getTimeWithoutSeconds(data?.dates[1].end)}` : ''}
                                    </Text>

                                    {data.dates.length >= 3 && (
                                        <CronogramContainer>
                                            <Button
                                                category='outline'
                                                size='medium'
                                                onClick={() => setFullCronogramModal(true)}
                                            >
                                                Ver cronograma completo
                                            </Button>
                                            <CronogramModal
                                                data={data.dates}
                                                visible={fullCronogramModal}
                                                setVisible={setFullCronogramModal}
                                            />
                                        </CronogramContainer>
                                    )}
                                </>
                            ) : (
                                <OnlyOnedate>
                                    <Text color={colors.nightDark}>
                                        {date.getDay(data?.dates[0].date)}
                                        &nbsp;de {date.getMounthName(data?.dates[0].date)}
                                        &nbsp;- {date.getTimeWithoutSeconds(data?.dates[0].start)}
                                        {data?.dates[0].end ? ` até ${date.getTimeWithoutSeconds(data?.dates[0].end)}` : ''}
                                    </Text>
                                </OnlyOnedate>
                            )
                        }
                    </DateContainer>

                </ContentInfoRow>

                {/* <br />
                    {
                        isAuth &&
                        <Interest slug={data.slug} presence={data.presence} userPresenceStatus={data.user_login_status} />
                    } */}

                {
                    data?.establishment?.name && (
                        <ContainerOrganizerLink>
                            <LinkPage target={'_blank'} to={`/estabelecimentos?estabelecimento=${data?.establishment?.slug}`}>
                                <ContentOrganizer>
                                    <DynamicProfileImage size='24px' img={data?.establishment?.profile_image_id?.thumb} word={data?.establishment?.name} />
                                    <Text color={colors.nightDark} paddingBottom={'0'} marginLeft={'10px'} marginRight={'5px'}>Organizado por</Text>
                                    <Text color={colors.nightDark} type='bold' paddingBottom={'0px'}>{data?.establishment?.name}</Text>
                                </ContentOrganizer>
                            </LinkPage>
                        </ContainerOrganizerLink>
                    )
                }


                {
                    data?.origin === textIa && (
                        <Row verticalCenter marginBottom='15px'>
                            <StyledIa>
                                <Icon name='smartToy' color={colors.night} />
                            </StyledIa>

                            <Text paddingBottom={'0'} color={colors.nightDark} marginLeft={'10px'}>Evento mapeado através de IA</Text>

                            <InfoTooltip
                                bgColor={colors.night}
                                text=<>O Whizr usa inteligência artificial para o mapeamento de eventos que não são cadastrados através de parceiros oficiais.
                                    <br></br>
                                    Recomendamos checar as informações no local oficial do evento.</>
                                type='info'
                            />
                        </Row>
                    )
                }

            </CardMainContent >

        </Container>
    )
}


const Container = styled(Column)`
    @media screen and (min-width: ${breakpoints.lg}) {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        padding: 30px 40px;
        /* background-color: ${colors.secondaryLight}; */
        position: relative;
        overflow: hidden;
    }
`;

const BluredBackground = styled.div`
    display: none;
    @media screen and (min-width: ${breakpoints.lg}){
        display: flex;
        width: 200%;
        height: calc(100% + 30px);
        position: absolute;
        left: -10%;
        top: -2%;
        background-image: url(${props => props.src});
        background-size: cover;
        background-position: center;
        filter: blur(10px);
        z-index: -1;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: ${colors.nightDark};
            opacity: .75;  
        }
    }
`;


const StyledBackground = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 0 0 18px 18px;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        width: 85%;
        height: 280px;
        border-radius: 12px;
        box-shadow: none;
    }
`;

const ImageContainer = styled.div`
    width: 100%;
    height: auto;
    position: relative;

    @media screen and (min-width: ${breakpoints.lg}){
    }
`;


const CategoriesInfos = styled(Row)`
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 15px;

    & > p {
        padding-bottom: 0;
    }

    @media screen and (min-width: ${breakpoints.lg}) {
        margin-bottom: 5px;    
        justify-content: flex-start;
    }
    
`;

const CardMainContent = styled(Column)`
    padding: 16px 6%;
    justify-content: center;
    align-items: center;   
    width: 100%; 

    & > h1 {
        margin-top: 6px;
        margin-bottom: 4px;
    }

    @media screen and (min-width: ${breakpoints.lg}){
        align-items: flex-start;   

        h2 {
            color: ${colors.neutral} !important;
            font-size: ${fonts.sizes.hero} !important;
        }

        p {
            color: ${colors.neutral} !important;
        }

        svg {
            stroke: ${colors.neutral} !important;
        }

    }
`

const EventType = styled.div`
    color: ${colors.neutralLight};
    border-radius: 6px;
    padding: 4px 10px;
    font-size: ${fonts.sizes.small};
    text-transform: capitalize;

    ${({ type }) => type === eventTypes.free.slug && `
        background: ${eventTypes.free.color};
    `}

    ${({ type }) => type === eventTypes.paid.slug && `
        background: ${eventTypes.paid.color};
    `}
`;


const Tag = styled(FullyCentered)`
    padding: 4px 8px;
    border-radius: 6px;
    background: ${colors.goldLight};
    width: auto;
    margin-left: 4px;

    ${({ adulthood }) => adulthood && `
        background: ${colors.nightDark};
    `};

    & > p {
        padding-bottom: 0 !important;
        text-transform: capitalize !important;
    }
`;

const ContentInfoRow = styled(Row)`
    margin-bottom: 10px;
    align-items: flex-start;
    text-align: left;

    ${({ address }) => address && `
        align-items: center;
    `}
    
    ${({ single }) => single && `
        align-items: center;

        & > p {
            padding-bottom: 0 !important;
        }
    `}

    &:nth-child(3){
        margin-bottom: 0;
    }

    & > p {
        padding: 0;
        margin: 0;
    }

    & > a > p{
        padding-bottom: 0 !important;
    }
`;

const ContentLinkAddress = styled.div`
    width: auto;
`;

const DateContainer = styled.div`
    height: auto;
    margin-left: 10px;
`;

const OnlyOnedate = styled.div`
    height: 100%;
    margin-top: 8px;
`;

const ContainerOrganizerLink = styled(Row)`
    width: 100%;
`

const ContentOrganizer = styled(Row)`
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    text-align: left;

    transition: all ease-in-out .25s;
    &:hover {
        transform: scale(1.01)
    }

`;

const CronogramContainer = styled.div`
    width: 100%;
`;

const StyledIa = styled.div`
    width: 26px;
    height: 26px;
`;

const FinishedEvent = styled(FullyCentered)`
    position: absolute;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 18px 18px;

    @media screen and (min-width: ${breakpoints.lg}) {
        width: 85%;
        height: 280px;
        border-radius: 12px;
    }

    &:before{ 
        content: '';
        background-color: ${colors.nightDark};
        color: ${colors.neutral};
        opacity: .8;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        border-radius: 0 0 18px 18px;
        
        @media screen and (min-width: ${breakpoints.lg}) {
            border-radius: 12px;
        }
    }
    
    &:after{ 
        content: 'Evento encerrado';
        background-color: ${colors.danger};
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        font-size: ${fonts.sizes.subtitle};
        color: ${colors.neutral};
        z-index: 3;
    }
`;