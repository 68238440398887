import DashEvents from 'screens/dashboard/DashEvents';
import paths from './paths';
import DashProfile from 'screens/dashboard/DashProfile';
import DashSupport from 'screens/dashboard/DashSupport';
import DashResume from 'screens/dashboard/DashResume';
import DashFinancial from 'screens/dashboard/DashFinancial';
import DashCheckin from 'screens/dashboard/DashCheckin';
import DashProducts from 'screens/dashboard/DashProducts';
import DashOrders from 'screens/dashboard/DashOrders';

export default [
    {
        exact: true,
        path: paths.dashboard.resume,
        element: <DashResume />
    },
    {
        exact: true,
        path: paths.dashboard.events,
        element: <DashEvents />
    },
    {
        exact: true,
        path: paths.dashboard.products,
        element: <DashProducts />
    },
    {
        exact: true,
        path: paths.dashboard.home,
        element: <DashProfile />
    },
    {
        exact: true,
        path: paths.dashboard.financial,
        element: <DashFinancial />
    },
    {
        exact: true,
        path: paths.dashboard.checkin,
        element: <DashCheckin />
    },
    {
        exact: true,
        path: paths.dashboard.support,
        element: <DashSupport />
    },
    {
        exact: true,
        path: paths.dashboard.orders,
        element: <DashOrders />
    },
]