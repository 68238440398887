import ordersApi from 'api/requests/orders';
import Chips from 'components/_UI/Chips';
import Icon from 'components/_UI/Icon';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import currency from 'helpers/currency';
import date from 'helpers/date';
import time from 'helpers/time';
import React, { useEffect, useState } from 'react'
import { CreditCard } from 'react-feather';
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import ProductOrdersTable from './ProductOrdersTable';

export default function OrderDetail({ data }) {
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState(false);


    useEffect(() => {
        getOrderDetail();
    }, [data])

    async function getOrderDetail() {
        setLoading(true);

        try {
            const { data: response } = await ordersApi.detail(data?.id);
            setContent(response?.data);
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    return (
        <Container>
            <Text name="subtitle" paddingBottom={'30px'}>
                Pedido #{data?.id}
            </Text>

            {
                loading
                    ? <Loader />
                    : (
                        <Column>
                            <InfoContainer>
                                <ChipsContainer>
                                    <Chips prefix='Pagamento' text={data?.status} />
                                </ChipsContainer>

                                <Text marginTop={'5px'} marginLeft={'15px'}>
                                    <b>Informações do pedido: </b> Pedido cadastrado em {date.format(data?.created_at)} - {time.getFormatedTime(data?.created_at.split(' '[1]))}.
                                </Text>
                            </InfoContainer>

                            <CardsContainer>
                                <Card>
                                    <Text marginBottom={'10px'}>Valor total</Text>
                                    <Text type='bold' name='highlight'> {currency.format(content?.total)} </Text>
                                    {/* <Text>Ingressos: R$ 300,00</Text>
                                    <Text>Produtos: R$ 300,00</Text> */}

                                    <BetweenRow>
                                        <Text>Itens:</Text>
                                        <Text>{currency.format(content?.total)}</Text>
                                    </BetweenRow>

                                    <BetweenRow>
                                        <Text>Descontos:</Text>
                                        <Text color={colors.danger}>-{currency.format(content?.discount)}</Text>
                                    </BetweenRow>

                                    <BetweenRow>
                                        <Text>Cupom:</Text>
                                        <Text color={content?.coupon ? colors.primaryLight : colors.night} >{content?.coupon || 'N/A'}</Text>
                                    </BetweenRow>
                                </Card>
                                <Card>
                                    <Text marginBottom={'10px'}>Cliente</Text>
                                    <Text type='bold' name='highlight'> {content?.customer?.name} </Text>
                                    <Text> {content?.customer?.email}  </Text>
                                </Card>
                                <Card>
                                    <Text marginBottom={'10px'}>Pagamento</Text>
                                    <Row>
                                        {
                                            content?.payment_method == 'credit_card'
                                                ? <CreditCard color={colors.night} />
                                                : (
                                                    <Column width='24px' height='24px'>
                                                        <Icon name='pix' color={colors.night} />
                                                    </Column>
                                                )
                                        }
                                        <Text type='bold' name='highlight' marginLeft='8px' paddingBottom={'2px'}>
                                            {content?.payment_method == 'credit_card' ? 'Cartão de crédito' : 'PIX'}
                                        </Text>
                                    </Row>

                                    {
                                        content?.payment_method == 'credit_card' && (
                                            <Text marginTop='10px'>Parcelas: {content?.installments}x </Text>
                                        )
                                    }
                                </Card>
                            </CardsContainer>

                            <Column marginTop={'60px'}>
                                <Text type='bold' >Itens do pedido ({content?.products?.length}): </Text>

                                <ProductOrdersTable data={content?.products} />
                            </Column>
                        </Column>
                    )
            }
        </Container>
    )
}


const Container = styled(Column)`
    width: 100%;
`;

const ChipsContainer = styled.div`
    width: 160px;
    & > div > div {
        width: 164px;
    }
`;

const InfoContainer = styled(Row)`
    flex-wrap: wrap;
`;

const CardsContainer = styled(Column)`

    @media screen and (min-width: ${breakpoints.lg}) {
        flex-direction: row;
    }
`;

const Card = styled(Column)`
    margin-top: 30px;
    border-radius: 8px;
    background-color: ${colors.neutralLight};
    padding: 20px;
    box-shadow: 1px 1px 4px #ddd;

    @media screen and (min-width: ${breakpoints.lg}) {
        margin-left: 1.8%;

        &:first-child {
            margin-left: 0;
        }
    }
`;

const BetweenRow = styled(Row)`
    justify-content: space-between;
`;