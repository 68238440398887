import { useState, useEffect } from "react"
import styled from "styled-components"
import Fieldset from "components/_UI/Fieldset"
import { Column } from "styled/alignment/Column"
import { Row } from "styled/alignment/Row"
import Text from "components/_UI/Text"
import colors from "theme/colors"
import masks from "helpers/masks"
import date from "helpers/date"
import fieldValidations from "helpers/fieldValidations"
import fieldMessages from "texts/messages/field"
import breakpoints from "theme/breakpoints"

export default function SalePeriodForm({
    salePeriod,
    onChangeSalePeriod = () => {}
}) {
    const [saleStartDate, setSaleStartDate] = useState()
    const [saleStartTime, setSaleStartTime] = useState()
    const [saleEndDate, setSaleEndDate] = useState()
    const [saleEndTime, setSaleEndTime] = useState()

    const [saleStartDateError, setSaleStartDateError] = useState()
    const [saleStartTimeError, setSaleStartTimeError] = useState()
    const [saleEndDateError, setSaleEndDateError] = useState()
    const [saleEndTimeError, setSaleEndTimeError] = useState()

    const [syncProp, setSyncProp] = useState(true)

    function changeSaleStartDate(newDate) {
        setSaleStartDate(newDate)
        onChangeSalePeriod({
            sale_start: null,
            sale_end: null
        })

        if (!saleStartTime || !saleEndDate || !saleEndTime || saleStartTimeError) {

            return
        }

        const dateValidation = fieldValidations.date().validate.validDateRange(newDate)
        if (dateValidation !== true) {
            setSaleStartDateError(dateValidation)
            return
        }

        setSaleStartDateError('')

        const unformattedStartDate = date.unformat(newDate)
        const unformattedEndDate = date.unformat(saleEndDate)
        onChangeSalePeriod({
            sale_start: `${unformattedStartDate} ${saleStartTime}:00`,
            sale_end: `${unformattedEndDate} ${saleEndTime}:00`
        })
    }

    function changeSaleStartTime(newTime) {
        setSaleStartTime(newTime)
        onChangeSalePeriod({
            sale_start: null,
            sale_end: null
        })

        if (!fieldValidations.time().pattern.value.test(newTime)) {
            setSaleStartTimeError(fieldMessages.invalidTime('início'))
            return
        }

        setSaleStartTimeError('')

        if (!saleStartDate || !saleEndDate || !saleEndTime || saleStartDateError) {
            return
        }

        const unformattedStartDate = date.unformat(saleStartDate)
        const unformattedEndDate = date.unformat(saleEndDate)
        onChangeSalePeriod({
            sale_start: `${unformattedStartDate} ${newTime}:00`,
            sale_end: `${unformattedEndDate} ${saleEndTime}:00`
        })
    }

    function changeSaleEndDate(newDate) {
        setSaleEndDate(newDate)
        onChangeSalePeriod({
            sale_start: null,
            sale_end: null
        })

        if (!saleEndTime || !saleStartDate || !saleStartTime || saleEndTimeError) {
            return
        }

        const dateValidation = fieldValidations.date().validate.validDateRange(newDate)
        if (dateValidation !== true) {
            setSaleEndDateError(dateValidation)

            return
        }

        setSaleEndDateError('')
        
        const unformattedStartDate = date.unformat(saleStartDate)
        const unformattedEndDate = date.unformat(newDate)
        onChangeSalePeriod({
            sale_start: `${unformattedStartDate} ${saleStartTime}:00`,
            sale_end: `${unformattedEndDate} ${saleEndTime}:00`
        })
    }

    function changeSaleEndTime(newTime) {
        setSaleEndTime(newTime)
        onChangeSalePeriod({
            sale_start: null,
            sale_end: null
        })

        if (!fieldValidations.time().pattern.value.test(newTime)) {
            setSaleEndTimeError(fieldMessages.invalidTime('fim'))
            return
        }

        setSaleEndTimeError('')

        if (!saleEndDate || !saleStartDate || !saleStartTime || saleEndDateError) {
            return
        }

        const unformattedStartDate = date.unformat(saleStartDate)
        const unformattedEndDate = date.unformat(saleEndDate)
        onChangeSalePeriod({
            sale_start: `${unformattedStartDate} ${saleStartTime}:00`,
            sale_end: `${unformattedEndDate} ${newTime}:00`
        })
    }

    useEffect(() => {
        if (!salePeriod || !salePeriod?.sale_start || !salePeriod?.sale_end) {
            return
        }

        const { sale_start, sale_end } = salePeriod
        const [startDate, startTime] = sale_start.split(' ')
        setSaleStartDate(date.format(startDate))
        setSaleStartTime(startTime.substring(0, startTime.length - 3))

        const [endDate, endTime] = sale_end.split(' ')
        setSaleEndDate(date.format(endDate))
        setSaleEndTime(endTime.substring(0, endTime.length - 3))
    }, [])

    return (
        <Row flexWrap='wrap' gap='20px'>
            <Row flexWrap='wrap' flex='1' gap='8px'>
                <Column minWidth='200px' flex='1'>
                    <Fieldset
                        syncProp={syncProp}
                        layout='squired'
                        label='Data de início'
                        name='sale_start_day'
                        mask={masks.date}
                        validations={fieldValidations.date()}
                        value={saleStartDate}
                        onChangeValue={changeSaleStartDate}
                    />
                    { 
                        saleStartDateError && (
                            <Column>
                                <Text color={colors.danger} name='small'> { saleStartDateError } </Text>
                            </Column>
                        )
                    }
                </Column>
                <Column minWidth='200px' flex='1'>
                    <Fieldset
                        syncProp={syncProp}
                        layout='squired'
                        label='Horário de início'
                        name='sale_start_time'
                        mask={masks.time}
                        validations={fieldValidations.time('Horário de início')}
                        value={saleStartTime}
                        onChangeValue={changeSaleStartTime}
                    />
                    { 
                        saleStartTimeError && (
                            <Column>
                                <Text color={colors.danger} name='small'> { saleStartTimeError } </Text>
                            </Column>
                        )
                    }
                </Column>
            </Row>
            <Row flexWrap='wrap' flex='1' gap='8px'>
                <Column minWidth='200px' flex='1'>
                    <Fieldset
                        syncProp={syncProp}
                        layout='squired'
                        label='Data de fim'
                        mask={masks.date}
                        name='sale_end_day'
                        validations={fieldValidations.date()}
                        value={saleEndDate}
                        onChangeValue={changeSaleEndDate}
                    />
                    { 
                        saleEndDateError && (
                            <Column>
                                <Text color={colors.danger} name='small'> { saleEndDateError } </Text>
                            </Column>
                        )
                    }
                </Column>
                <Column minWidth='200px' flex='1'>
                    <Fieldset
                        syncProp={syncProp}
                        layout='squired'
                        label='Horário de fim'
                        mask={masks.time}
                        name='sale_end_time'
                        validations={fieldValidations.time('Horário de fim')}
                        value={saleEndTime}
                        onChangeValue={changeSaleEndTime}
                    />
                    { 
                        saleEndTimeError && (
                            <Column>
                                <Text color={colors.danger} name='small'> { saleEndTimeError } </Text>
                            </Column>
                        )
                    }
                </Column>
            </Row>
        </Row>
    )
}