import eventsApi from 'api/requests/events';
import Button from 'components/_UI/Button';
import Icon from 'components/_UI/Icon';
import LinkPage from 'components/_UI/LinkPage';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import DynamicProfileBackground from 'components/commons/DynamicProfileBackground';
import { useBackButtonCollapseDash } from 'context/dash/BackButtonCollapseDash';
import { useDetailVisible } from 'context/dash/DetailVisible';
import { useShowAllEvents } from 'context/dash/ShowAllEvents';
import { useEvent } from 'context/events/Event';
import { useBackButtonCollapse } from 'context/general/BackButtonCollapse';
import date from 'helpers/date';
import types from 'helpers/system/types';
import TicketsForSale from 'modules/dashboard/events/detail/tickets/TicketsForSale';
import CronogramModal from 'modules/events/detail/CronogramModal';
import React, { useState, useEffect } from 'react'
import { Clock, Copy, Link, MapPin } from 'react-feather';
import styled from 'styled-components'
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column'
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import eventTypes from 'theme/eventTypes';

import fonts from 'theme/fonts';
import ProductsForSale from '../products/ProductsForSale';
import breakpoints from 'theme/breakpoints';
import InfoTooltip from 'components/_UI/InfoTooltip';
import ToastContainer from 'components/_UI/ToastsContainer';
import useToast from 'hooks/useToast';


const truncateHTML = (html, limit) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  const textContent = div.textContent || div.innerText || '';

  if (textContent.length <= limit) {
    return html;
  }

  let truncatedText = textContent.slice(0, limit) + '...';

  div.innerHTML = truncatedText;
  return div.innerHTML;
};

export default function EventDetail({
  item,
  withBackButton = false,
  hasTickets,
  setHasTickets,
  setHasProducts,
  hasProducts,
  isDraft = false
}) {

  const [fullCronogramModal, setFullCronogramModal] = useState(false)
  const { showAllEvents, setShowAllEvents } = useShowAllEvents()
  const [showFullText, setShowFullText] = useState(false);
  const [visibleBackButton, setVisibleBackButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { backButtonCollapseDash, setBackButtonCollapseDash } = useBackButtonCollapseDash()

  const { toasts, toast } = useToast();
  const { setEvent } = useEvent();

  const eventUrl = `${import.meta.env.VITE_CLIENT_URL}/evento/${data?.slug}`;

  useEffect(() => {
    getEvent(item)

    // ERA PRA SER A LÓGICA DO BOTAO DE VOLTAR
    // if(withBackButton) {
    //   setBackButtonCollapseDash({...backButtonCollapseDash, visible: true})

    //   return
    // }

    // setBackButtonCollapseDash({...backButtonCollapseDash, visible: false})


  }, [])

  const getEvent = async (slug) => {
    setLoading(true)
    try {
      const { data } = await eventsApi.getBySlug(slug)
      setData(data?.data)
      setEvent(data?.data);

      setHasTickets(data?.data?.products.some(el => el.type === 'ticket'))


      setHasProducts(data?.data?.products.some(el => el.type === 'product'))


    } catch (error) {

    } finally {
      setLoading(false)
    }
  }

  const handleShowMore = () => {
    setShowFullText(!showFullText);
  };

  async function coppyEventUrl() {
    if (navigator?.clipboard?.writeText) {
      try {
        await navigator.clipboard.writeText(eventUrl)
        toast.success('Link copiado com sucesso!', { position: 'top-center' })
      } catch (error) {
        copyToClipboardFallback(eventUrl)
      }
    }
  }

  function copyToClipboardFallback(value) {
    const input = document.createElement('input');
    input.value = value;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    toast.success('Link copiado com sucesso!', { position: 'top-center' })
  }

  return (
    <ContainerDetail>
      <ToastContainer toasts={toasts} />
      {!loading ? (
        <>
          <ContentImage
            bg={data?.images[0] || '/assets/images/placeholder-events.png'}
          >
            <DynamicProfileBackground
              img={data?.images[0]}
              size={'200px'}
              event
            />
          </ContentImage>

          <ContentStatus>
            <EventStatus active={data?.is_past}>
              <Text color={colors.neutral} >{data?.is_past ? 'Encerrado' : 'Ativo'}</Text>
            </EventStatus>

            <EventType type={data?.classification?.name}>
              {types.getBySlug(data?.classification?.name)?.label}
            </EventType>

            {
              data?.adulthood && <ContentAdulthood><Text color={colors.neutral} type='bold' paddingBottom={'0'}> +18 </Text></ContentAdulthood>
            }


            {data?.tags?.map(el => (
              <CategoriesInfos>
                {el.name}
              </CategoriesInfos>
            ))}

          </ContentStatus>


          <Text name='title' marginTop={'15px'} color={colors.secondaryPurple}>{data?.name}</Text>

          {
            !isDraft && (
              <EventLinkContainer>
                <Link size={'24px'} color={colors.primaryDashboard} />

                <LinkPage isWrapper to={eventUrl}>
                  <Text
                    marginLeft={'4px'}
                    paddingBottom={'0'}
                    color={colors.primaryDark}
                    decoration={'underline'}
                  >
                    {eventUrl}
                  </Text>
                </LinkPage>

                <InfoTooltip
                  bgColor={colors.night}
                  text='Copiar link do evento'
                  type='none'
                >
                  <CopyContainer onClick={coppyEventUrl}>
                    <Copy size={12} color={colors.neutral} />
                  </CopyContainer>
                </InfoTooltip>
              </EventLinkContainer>

            )
          }


          <ContentInfo address>

            <MapPin name='pin-simple' size={'24px'} color={colors.primaryDashboard} />

            {
              !data?.address ? (
                <Text marginLeft={'10px'} paddingBottom={'0'}>&nbsp;&nbsp;Local a definir</Text>
              ) : (
                <ContentLink>
                  <LinkPage isWrapper to={data?.link_map}>
                    <Text color={colors.primaryDark} marginLeft={'4px'} decoration='underline' paddingBottom={'0'}>
                      {data?.address?.street?.name && `${data?.address?.street?.name},`} {data?.address?.street?.district && `Bairro ${data?.address?.street?.district}`}
                      {data?.address?.number && `, nº ${data?.address?.number}`}
                      {data?.address?.complement && ` - ${data?.address?.complement}`}
                    </Text>
                  </LinkPage>
                </ContentLink>
              )
            }
          </ContentInfo>

          <ContentInfoRow position={data?.dates?.length > 1}>


            <Clock size={'24px'} color={colors.primaryDashboard} />

            <DateContainer>
              {
                data?.dates?.length > 1 ? (
                  <>
                    <Text
                      color={colors.night} paddingBottom={'0'} marginLeft={'4px'}>

                      <b>Dia 1</b> - {date?.getDay(data?.dates[0]?.date)}
                      &nbsp;de {date?.getMounthName(data?.dates[0]?.date)}
                      &nbsp;- {date?.getTimeWithoutSeconds(data?.dates[0]?.start)}
                      {data?.dates[0].end ? ` até ${date.getTimeWithoutSeconds(data?.dates[0]?.end)}` : ''}
                    </Text>

                    <Text color={colors.night} paddingBottom={'0'} marginLeft={'4px'}>
                      <b>Dia 2</b> - {date?.getDay(data?.dates[1]?.date)}
                      &nbsp;de {date?.getMounthName(data?.dates[1]?.date)}
                      &nbsp;- {date?.getTimeWithoutSeconds(data?.dates[1]?.start)}
                      {data?.dates[1]?.end ? ` até ${date.getTimeWithoutSeconds(data?.dates[1]?.end)}` : ''}
                    </Text>

                    {data?.dates?.length >= 3 && (
                      <ContentCronogram>
                        <Button
                          category='outline'
                          size='medium'
                          marginBottom='0'
                          onClick={() => setFullCronogramModal(true)}
                        >
                          Ver cronograma completo
                        </Button>

                        <CronogramModal
                          data={data?.dates}
                          visible={fullCronogramModal}
                          setVisible={setFullCronogramModal}
                        />
                      </ContentCronogram>
                    )}
                  </>
                ) : (
                  <OnlyOnedate>
                    <Text color={colors.night} paddingBottom={'0'} marginLeft={'4px'}>
                      {data?.dates[0] && date?.getDay(data?.dates[0]?.date)}
                      &nbsp;de {data?.dates[0] && date?.getMounthName(data?.dates[0]?.date)}
                      &nbsp;- {data?.dates[0] && date?.getTimeWithoutSeconds(data?.dates[0]?.start)}
                      {data?.dates[0]?.end ? ` até ${date.getTimeWithoutSeconds(data?.dates[0]?.end)}` : ''}
                    </Text>
                  </OnlyOnedate>
                )
              }

            </DateContainer>

          </ContentInfoRow>

          {
            data?.description_with_html ? (
              <Description>
                <Text name='highlight' marginBottom={'10px'}>Descrição</Text>

                <div
                  dangerouslySetInnerHTML={{
                    __html: showFullText
                      ? data.description_with_html
                      : truncateHTML(data.description_with_html, 300),
                  }}
                />

                {data.description_with_html.length > 300 && (
                  <Button
                    category="outline"
                    marginTop="5px"
                    onClick={handleShowMore}
                  >
                    {showFullText ? 'Ver menos' : 'Ver descrição completa'}
                  </Button>
                )}
              </Description>
            ) : (
              <>
                <Description limitChar={showFullText}>
                  <Text name='highlight' marginBottom={'10px'}>Descrição</Text>
                  {
                    data?.description?.length > 300 ? (

                      showFullText ?

                        <Text> {data?.description} </Text>
                        :
                        <Text>{data?.description.slice(0, 285)}.... </Text>
                    ) : (
                      <Text> {data?.description} </Text>
                    )

                  }
                </Description>
                {
                  data?.description?.length > 300 &&
                  <Button category='outline' marginTop='5px' onClick={() => setShowFullText(!showFullText)}>
                    {showFullText ? 'Ver menos' : 'Ver mais'}
                  </Button>
                }
              </>
            )
          }



          {
            !!hasTickets &&
            <TicketsForSale data={data?.products} />
          }
          {
            !!hasProducts &&
            <ProductsForSale data={data?.products} />
          }
        </>
      ) : (
        <ContentLoader>
          <Loader />
        </ContentLoader>
      )
      }


    </ContainerDetail>
  )
}

const ContainerDetail = styled(Column)`
  height: 100vh;
  display: flex;
`;

const ContentStatus = styled(Row)`
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: 5px;
`;

const EventStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 25px;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
  & > p {
    padding-bottom: 0;
    font-weight: bold;
  }
  ${({ active }) => active === false && `
    background: ${colors.successLight};
    & > p {
      color: ${colors.neutralDark};
    }
  `}
  ${({ active }) => active === true && `
    background: ${colors.primaryDashboard};
    & > p {
      color: #eee;
    }
  `}
`;

const ContentImage = styled.div`
  width: 100%;
  height: auto;  
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: ${breakpoints.lg}) {
    & > div > img {
      filter: blur(6px);
    }

    &:after {
      background-image: ${({ bg }) => `url(${bg})`};
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; 
      content: '';
      position: absolute;
      left: auto;
      bottom: 0;
      width: 50%;
      height: 100%;    
      border-radius: 8px;
      box-shadow: 0px 2px 5px #aaa;
    }
  }
`

const StyledBackgroundContainer = styled(FullyCentered)`
    width: 100%;
    height: auto;  
    position: relative;
    background: ${colors.neutralDark};
    

    ${({ type }) => type === eventTypes.free.slug && ` 
      & > div {
          background: ${eventTypes.free.color};
          height: 200px;
          border-radius: 0 0 18px 18px;
      }
        
    `}

    ${({ type }) => type === eventTypes.paid.slug && `
      & > div {
          background: ${eventTypes.paid.color};
          height: 200px;
          border-radius: 0 0 18px 18px;
      }    
    `}
    
    ${({ isImage }) => isImage && `
        background: ${colors.neutralLight};
        width: 100%;
    `}
`;

const StyledBackground = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 18px;
`;


const ContentInfo = styled(Row)`
  margin-bottom: 18px;
  align-items: flex-start;
  text-align: left;

  ${({ address }) => address && `
      align-items: center;
  `}

  &:nth-child(3){
      margin-bottom: 0;
  }

  & > p {
      padding: 0;
      margin: 0;
  }
`;

const StyledIconPin = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

const EventType = styled.div`
    margin-right: 5px;
    height: 25px;
    color: ${colors.neutralDark};
    border-radius: 4px;
    width: 72px;
    display: flex;
    justify-content: center;
    align-items:center;
    font-size: ${fonts.sizes.default};
    font-weight: bold;
    margin-bottom: 5px;
    text-transform: capitalize;

    ${({ type }) => type === eventTypes.free.slug && `
        background: ${eventTypes.free.color};
    `}

    ${({ type }) => type === eventTypes.paid.slug && `
        background: ${eventTypes.paid.color};
    `}
`;

const CategoriesInfos = styled.div`
    margin-right: 5px;
    height: 25px;
    color: ${colors.neutralDark};
    border-radius: 4px;
    width: auto;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items:center;
    font-size: ${fonts.sizes.default};
    font-weight: bold;
    background-color: ${colors.goldLight};
    margin-bottom: 5px;
    text-transform: capitalize;
`;

const ContentInfoRow = styled(Row)`
    margin-bottom: 18px;
    align-items: center;
    text-align: left;

    ${({ position }) => position && `
      align-items: flex-start;
    `}

    ${({ address }) => address && `
        align-items: center;
    `}

    &:nth-child(3){
        margin-bottom: 0;
    }

    & > p {
        padding: 0;
        margin: 0;
    }
`;


const ContentCronogram = styled(Row)`
    width: 100%;
    & > button {
      display: flex;
      justify-content: flex-start;
    }
  
`;

const DateContainer = styled.div`
    height: auto;
`;

const OnlyOnedate = styled.div`
    height: 100%;
`;

const ContentIconContainer = styled.div`
    width: 25px;
    height: 25px;
    margin-right: 5px;

`;

const Description = styled.div`
  text-align: justify;
  margin-top: 10px;
  & > p {
    color: ${colors.nightLight};
  }
`;

const ContentAdulthood = styled(Row)`
  width: 35px;
  height: 25px;
  border-radius: 4px;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  & > p {
    padding-bottom: 0;
    font-weight: bold;
  }
  background: black;
`;

const EventLinkContainer = styled(Row)`
  align-items: center;
  margin-bottom: 18px;

  & > a {
    width: auto !important;
    padding-right: 6px;
  }
`;

const ContentLink = styled.div`
  width: auto;
`;

const CopyContainer = styled.div`
  background: ${colors.primaryLight};
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer;
  transition: all ease-in-out .35;
  margin-left: 4px;

  &:hover {
    background: ${colors.primary};
    transform: scale(1.1);
  }
`;

const ContentLoader = styled(Column)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;