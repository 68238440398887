import Icon from 'components/_UI/Icon';
import Loader from 'components/_UI/Loader';
import Text from 'components/_UI/Text';
import EventCard from 'modules/events/card/EventCard';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import colors from 'theme/colors';
import breakpoints from 'theme/breakpoints';
import { useCollapse } from 'context/general/Collapse';
import Ilustration from 'components/_UI/Ilustration';


export default function OrdersList({ data, expired, home, setIsLoading, renderDetail, isLoading}) {
    function handleDetail(item) {
        renderDetail(item)
    }
    
    return (
        <ContainerList>
        {isLoading ? (
            <Loader />
        ) : (

            !!data?.length ?
                data.map((el, index) => (
                    <ContainerItem
                        key={index}
                    >
                      
                        <EventCard
                            data={el}
                            fullWidth={!home && true}
                            key={index}
                            expired={expired}
                            whiteBackground
                            onEventClick={(e) => handleDetail(e)}
                        />
                    </ContainerItem>
                )) : (
                    <ContentNotData >
                        <ContentIcon>
                            <Ilustration name='emptyWallet' />
                        </ContentIcon>

                        
                        <Text textAlign={'center'} type='bold' name='subtitle' color={colors.primary} >
                            Você ainda não possui ingressos ou produtos na carteira =( 
                        </Text>
                    </ContentNotData>
                )

        )}

    </ContainerList>
    )
}

const ContainerList = styled(Column)` 
    width: 100%;
    padding: 5px;
    margin-top: 20px;
    align-items: center;

    @media screen and (min-width: ${breakpoints.lg}) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }
`;

const OrderItem = styled(Column)`
    margin-bottom: 15px;
`;

const ContentNotData = styled(Column)`
    width: 100%;
    align-content: center;
    justify-content: center;
    height: 350px;
    @media screen and (min-width: ${breakpoints.lg}) {
        height: 500px;
        
    }
    & > h2 {
        margin-top: 6px;
    }

`;

const ContentIcon = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 300px;
`;

const ContainerItem = styled(Column)`
    width: 100%;
    height: 100%;
    background: ${colors.neutralBackground};

    & > div  {
        box-shadow: unset;
    }

    @media screen and (min-width: ${breakpoints.lg}) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        background: ${colors.neutralBackground};
        width: 32% !important;

        /* & > div {
            margin-left: 1%;
        } */
    }

`;