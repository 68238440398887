import React, { useState } from 'react';
import styled from 'styled-components';
import { Row } from 'styled/alignment/Row';
import colors from 'theme/colors';
import OrdersTable from './OrdersTable';

export default function Orders() {
    const tabs = [
        { name: 'all', label: 'Todos' },
        { name: 'approved', label: 'Aprovados' },
        { name: 'pending', label: 'Pendentes' },
        { name: 'canceled', label: 'Expirados' },
        // { name: 'chargeback', label: 'Reembolsos' },
    ];

    const [selectedTab, setSelectedTab] = useState('all');

    return (
        <>
            <Tabs>
                {tabs.map(el => (
                    <TabItem
                        key={el.name}
                        selected={el.name === selectedTab}
                        onClick={() => setSelectedTab(el.name)}
                    >
                        {el.label}
                    </TabItem>
                ))}
            </Tabs>

            <OrdersTable status={selectedTab} />
        </>
    );
}

const Tabs = styled(Row)`
    border-bottom: 1px solid ${colors.night};
    margin-top: 20px;
`;

const TabItem = styled.div`
    padding: 8px 14px;
    cursor: pointer;
    transition: border-bottom 0.2s ease-in-out;

    &:first-child {
        padding-left: 10px;
    }

    ${({ selected }) => selected && `
        border-bottom: 3px solid ${colors.occasionalPurple};
        font-weight: bold;
    `};
`;