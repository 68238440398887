import Text from 'components/_UI/Text';
import React, { useState, useEffect } from 'react';
import { Clock } from 'react-feather';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import ExpirationModal from '../commom/ExpirationModal';

export default function TimeWarning({ setTime }) {
    const initialTime = 20 * 60; // 20 minutos em segundos
    const [timeLeft, setTimeLeft] = useState(initialTime);
    const [expirationModalVisible, setExpirationModalVisible] = useState(false);

    useEffect(() => {
        const endTime = Date.now() + initialTime * 1000;

        const interval = setInterval(() => {
            const remaining = Math.floor((endTime - Date.now()) / 1000);
            if (remaining > 0) {
                setTimeLeft(remaining);
                setTime(remaining);
            } else {
                clearInterval(interval);
                setTimeLeft(0);
                setExpirationModalVisible(true);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [setTime]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    return (
        <>
            <Container>
                <CardWarning>
                    <Column width='20px'>
                        <Clock size={20} color={colors.neutral} />
                    </Column>
                    <Text
                        name='subtitle'
                        marginLeft={'10px'}
                        marginRight={'10px'}
                        color={colors.neutral}
                        paddingBottom={0}
                        type='bold'
                    >
                        {formatTime(timeLeft)}
                    </Text>
                    <Text color={colors.neutral} paddingBottom={0}>
                        Após este tempo, os ingressos serão liberados para venda novamente.
                    </Text>
                </CardWarning>
            </Container>
            {expirationModalVisible && (
                <ExpirationModal visible={expirationModalVisible} />
            )}
        </>
    );
}

const Container = styled(Row)`
    margin-top: 20px;
`;

const CardWarning = styled(FullyCentered)`
    background-color: #ec942c;
    padding: 16px 12px;
    border-radius: 6px;
    align-items: center;

    & > p {
        @media screen and (min-width: ${breakpoints.lg}) {
            padding-right: 90px;
        }
    }
`;
