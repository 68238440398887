import React, { useCallback, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useJsApiLoader } from '@react-google-maps/api';
import UserLocationMark from './UserLocationMark';
import EventMarker from './EventMarker';
import styled from 'styled-components';
import MultipleMarkers from './MultipleMarkers';
import mapStyle from 'theme/mapStyle';
import breakpoints from 'theme/breakpoints';
import { useUserLocation } from 'context/user/UserLocation';
import { useCurrentLocation } from 'context/user/CurrentLocation';
import { useUserLocale } from 'context/user/UserLocale';

export default function Map({
    data,
    withUserLocation = false,
    multiples,
    onMarkerClick,
}) {
    const STATUS_SUCCESS = 'OK';

    const { userLocation } = useUserLocation();
    const {userLocale} = useUserLocale();
    const { currentLocation } = useCurrentLocation();

    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [zoom, setZoom] = useState(12.5);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: import.meta.env.VITE_GMAPS_KEY,
    });

    const geocodeCity = useCallback(() => {
        if (!currentLocation?.city || !window.google) return;

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: currentLocation?.city }, (results, status) => {
            if (status === STATUS_SUCCESS && results[0]) {
                const { lat, lng } = results[0].geometry.location;
                setCenter({ lat: lat(), lng: lng() });
            }
        });
    }, [currentLocation?.city, userLocale]);

    useEffect(() => {
        if (isLoaded) {
            geocodeCity();
        }
    }, [isLoaded, geocodeCity]);

    useEffect(() => {
        if (userLocation) {
            setCenter({ lat: +userLocation.lat, lng: +userLocation.long });
        }
    }, [userLocation]);

    return (
        <GoogleMapsContainer>
            <GoogleMapReact
                bootstrapURLKeys={{ key: import.meta.env.VITE_GMAPS_KEY }}
                center={center}
                defaultZoom={zoom}
                options={{
                    minZoom: 11,
                    maxZoom: 18,
                    styles: mapStyle
                }}
            >
                {
                    withUserLocation && userLocale && (
                        <UserLocationMark lat={userLocale.lat} lng={userLocale.long} />
                    )
                }

                {data && data.map(el => (
                    <EventMarker
                        key={el.slug}
                        lat={el.address.lat}
                        lng={el.address.long}
                        name={el.name}
                        type={el.classification?.name}
                        image={el.thumb || el?.photo || el?.profileImage?.thumb}
                        onMarkerClick={() => onMarkerClick(el)}
                    />
                ))}

                {multiples && multiples.map(el => (
                    <MultipleMarkers
                        key={el?.multiples[0].slug}
                        lat={el?.multiples[0].address?.lat}
                        lng={el?.multiples[0].address?.long}
                        data={el?.multiples}
                    />
                ))}
            </GoogleMapReact>
        </GoogleMapsContainer>
    );
}

const GoogleMapsContainer = styled.div`
    width: 100%;
    height: 100dvh;
    position: absolute;
    left: 0;
    top: 0;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        position: relative;
        width: 66%;
    }
`;