import api from "api";

const promotionalCodes = {

    getByEvent: (slug) => {
        return api().get(`/coupon/by-event/${slug}`);
    },

    delete: (id) => {
        return api().delete(`/coupon/${id}`);
    },

    create: (payload) => {
        return api().post('/coupon', payload);
    },
    
    update: (id, payload) => {
        return api().put(`/coupon/${id}`, payload);
    },

    getTypes: () => {
        return api().get('/coupon-types');
    },
    
    getDistincts: () => {
        return api().get('/coupon/distinct');
    },


}

export default promotionalCodes;