import Table from 'components/_UI/Table';
import Text from 'components/_UI/Text';
import currency from 'helpers/currency';
import React from 'react'

export default function ProductOrdersTable({ data }) {
    const ordersList = data?.map(item => {
        return {
            name: <Text name='small'>{item?.name}</Text>,
            quantity: <Text name='small'>{item?.quantity}</Text>,
            price: <Text name='small'>{currency.format(item?.price)}</Text>,
            discount: <Text name='small'>{currency.format(item?.discount)}</Text>,
        };
    })

    return (
        <Table
            rows={ordersList}
            columns={['Nome', 'Quantidade', 'Preço', 'Desconto']}
        />

    )
}
