import Divider from 'components/_UI/Divider';
import Text from 'components/_UI/Text';
import CheckoutOrderMinimal from 'modules/checkout/products/CheckoutOrderMinimal';
import DescriptionWithHTML from 'modules/events/detail/DescriptionWithHTML';
import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';

export default function PageContent({ data }) {

    return (
        <Container>
            <Side side='tickets'>
                {
                    !!data?.products?.length && !data?.is_past && (
                        <CheckoutOrderMinimal event={data} />
                    )
                }

            </Side>

            <Side side='content'>
                <Text name='title' color={colors.nightDark}>Descrição</Text>

                <Description>
                    {
                        data?.description_with_html
                            ? (
                                <DescriptionWithHtmlContainer>
                                    <DescriptionWithHTML data={data?.description_with_html} />
                                </DescriptionWithHtmlContainer>
                            ) : (
                                <Text
                                    color={colors.nightDark}
                                    marginTop='2px'
                                    marginBottom='16px'
                                >
                                    {data.description}
                                </Text>
                            )
                    }
                </Description>

                {
                    !!data?.products?.length && (
                        <ContentInfos>
                            <Divider
                                marginTop={'20px'}
                                marginBottom='15px'
                                color={colors.primaryDashboard}
                                opacity={0.3}

                            />

                            <Text
                                marginTop='10px'
                                name='highlight'
                                type='bold'
                                color={colors.nightDark}
                                marginBottom={'6px'}
                            >
                                Informações importantes
                            </Text>

                            <Text
                                marginBottom={'6px'}
                            >
                                Para realizar cancelamentos através do Whizr, é necessário estar atento ao prazo máximo de pedido.
                            </Text>

                            <Text marginBottom={'6px'} >
                                Conforme os termos de uso e os direitos do consumidor, a solicitação poderá ser realizada em até 7 dias corridos da compra, antecedendo o evento em pelo menos 48h.
                            </Text>

                            <Text marginBottom={'6px'}>
                                Em casos de políticas ofertadas diretamente pelos organizadores, serão eles os responsáveis pelo cumprimento das mesmas, assim como pelo reembolso e validações necessárias.
                            </Text>

                            <Text>
                                Fique atento, e em caso de dúvidas sobre as políticas do evento, entre em contato com o organizador diretamente.
                            </Text>
                        </ContentInfos>
                    )
                }
            </Side>
        </Container>
    )
}


const Container = styled(Column)`
  width: 100%;
  background-color: #fefefe;
  padding-bottom: 100px;
  
    @media screen and (min-width: ${breakpoints.lg}) {
        flex-direction: row-reverse;
        padding: 0 6%; 
    }
`;

const Side = styled(Column)`
    padding: 20px;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        padding: 46px 40px;

        ${({ side }) => side === 'content' && `
            width: 60%;
        `}
        
        ${({ side }) => side === 'tickets' && `
            width: 40%;
            background-color: #fefefe;
            align-items: flex-end;
        `}
    }
    
`;

const Description = styled(Column)`
    align-items: center;
    text-align: left;  
`;

const DescriptionWithHtmlContainer = styled(Column)`
`;

const ContentInfos = styled(Column)`
    /* padding: 0 6%; */
`;