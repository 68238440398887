import Button from 'components/_UI/Button';
import Icon from 'components/_UI/Icon';
import Text from 'components/_UI/Text';
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import paths from 'routes/paths';
import styled from 'styled-components';
import colors from 'theme/colors';
import { User, MapPin, LogIn } from 'react-feather';
import useAuth from 'hooks/useAuth';
import { useUser } from 'context/user/User';
import Loader from 'components/_UI/Loader';
import breakpoints from 'theme/breakpoints';
import { useCollapse } from 'context/general/Collapse';
import Checkin from 'modules/dashboard/checkin/Checkin';
import LinkPage from 'components/_UI/LinkPage';
import { Column } from 'styled/alignment/Column';

export default function Navbar() {
  const { isAuth } = useAuth();
  const { user } = useUser()
  
  const location = useLocation();
  const { collapse, setCollapse } = useCollapse();

  const [selectedIcon, setSelectedIcon] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }, [])


  useEffect(() => {
    switch (location.pathname) {
      case paths.events.home:
        setSelectedIcon('home');
        break;
      case paths.user.profile:
        setSelectedIcon('profile');
        break;
      case paths.login:
        setSelectedIcon('login');
      case paths.dashboard.home:
        setSelectedIcon('store');
      case paths.user.wallet:
        setSelectedIcon('wallet');
        break;
      default:
        break;
    }
  }, [location.pathname]);

  function renderValidate() {
    setCollapse({
      ...collapse,
      withContent: true,
      height: 'max',
      arrowPosition: false,
      componentToRender: () => <Checkin withParticipants minimal />
    });
  }

  return (
    <NavbarContainer>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ToLink to={paths.events.home} isSelected={selectedIcon === 'home'}>
            <IconContainer>
              <MapPin color={selectedIcon === 'home' ? colors.primary : colors.primaryDashboard} />
            </IconContainer>
            <Text color={selectedIcon === 'home' ? colors.primary : colors.primaryDashboard}>
              Explorar
            </Text>
          </ToLink>

          {isAuth && (
            (user?.role?.id != import.meta.env.VITE_PRODUCER_ROLE_ID && user?.role?.id != import.meta.env.VITE_ESTABLISHMENT_ROLE_ID) && (
              <ToLink to={paths?.user?.wallet} isSelected={selectedIcon === 'wallet'}>
                <IconContainer>
                  <Icon name="wallet" color={selectedIcon === 'wallet' ? colors.primary : colors.primaryDashboard} />
                </IconContainer>
                <Text color={selectedIcon === 'wallet' ? colors.primary : colors.primaryDashboard}>
                  Carteira
                </Text>
              </ToLink>
            )
          )}

          {
            isAuth && (user?.role?.id == import.meta.env.VITE_ESTABLISHMENT_ROLE_ID || user?.role?.id == import.meta.env.VITE_ADMIN_ROLE_ID) && (
              <ToLink to={paths.dashboard.home} isSelected={selectedIcon === 'store'}>
                <IconContainer>
                  <Icon name='store' color={selectedIcon === 'store' ? colors.primary : colors.primaryDashboard} />
                </IconContainer>
                <Text color={selectedIcon === 'store' ? colors.primary : colors.primaryDashboard}>
                  Estabelecimento
                </Text>
              </ToLink>
            )
          }

          {
            isAuth && (user?.role?.id == import.meta.env.VITE_PRODUCER_ROLE_ID || user?.role?.id == import.meta.env.VITE_ADMIN_ROLE_ID) && (
              <ToLink to={paths.dashboard.resume} isSelected={selectedIcon === 'profile'}>
                <IconContainer>
                  <Icon name='profile' color={selectedIcon === 'profile' ? colors.primary : colors.primaryDashboard} />
                </IconContainer>
                <Text color={selectedIcon === 'profile' ? colors.primary : colors.primaryDashboard}>
                  Painel do produtor
                </Text>
              </ToLink>
            )
          }


          {isAuth ? (
            (user?.role?.id != import.meta.env.VITE_ESTABLISHMENT_ROLE_ID && user?.role?.id != import.meta.env.VITE_PRODUCER_ROLE_ID && user?.role?.id != import.meta.env.VITE_ADMIN_ROLE_ID) && (
              <ToLink to={paths.user.profile} isSelected={selectedIcon === 'profile'}>
                <IconContainer>
                  <User color={selectedIcon === 'profile' ? colors.primary : colors.primaryDashboard} />
                </IconContainer>
                <Text color={selectedIcon === 'profile' ? colors.primary : colors.primaryDashboard}>
                  Perfil
                </Text>
              </ToLink>
            )
          ) : (
            <DirectSignin>
              <LinkPage target={'_self'} to={paths.login} isSelected={selectedIcon === 'login'}>
                <IconContainer>
                  <LogIn color={selectedIcon === 'login' ? colors.primary : colors.primaryDashboard} />
                </IconContainer>
                <Text color={selectedIcon === 'login' ? colors.primary : colors.primaryDashboard}>
                  Entrar ou criar conta
                </Text>
              </LinkPage>
            </DirectSignin>
          )}

          {
            isAuth && user?.have_permission_to_checkin && (
              <Item isSelected={selectedIcon === 'qrcode'} onClick={renderValidate}>
                <IconContainer>
                  <Icon name='qrcode' color={selectedIcon === 'qrcode' ? colors.primary : colors.primaryDashboard} />
                </IconContainer>
                <Text color={selectedIcon === 'qrcode' ? colors.primary : colors.primaryDashboard}>
                  Validação
                </Text>
              </Item>
            )
          }
        </>
      )
      }



    </NavbarContainer>
  );
}

const NavbarContainer = styled.nav`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-top: 2px;
  align-items: center;
  background: ${colors.neutralLight};
  border: 1px solid ${colors.occasionalGrey};
  justify-content: space-around;
  z-index: 20;

  @media screen and (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const IconContainer = styled.button`
  width: 28px;
  height: 28px;
  justify-content: center;
  flex-direction: row;
  background: ${colors.neutralLight};
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const ToLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > p {
    margin-top: 2px;
    font-size: 10px;
  }
  color: ${({ isSelected }) => (isSelected ? colors.primary : colors.primaryDashboard)};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > p {
    margin-top: 2px;
    font-size: 10px;
  }
  color: ${({ isSelected }) => (isSelected ? colors.primary : colors.primaryDashboard)};
`;

const ContentWallet = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ isSelected }) => (isSelected ? colors.primary : colors.primaryDashboard)};
  justify-content: center;
  align-items: center;
  & > p {
    margin-top: 2px;
    font-size: 10px;
  }
`;

const DirectSignin = styled(Column)`
  width: auto;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  & > a >  p {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0px;
  }
  & > a >  p > p {
    margin-top: 2px;
    font-size: 10px;
  }
  & > a  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  color: ${({ isSelected }) => (isSelected ? colors.primary : colors.primaryDashboard)};
`;
