import dayjs from 'dayjs';

const time = {
    getHours: (d) => dayjs(d).format('HH'),

    getMinutes: (d) => dayjs(d).format('mm'),

    getFormatedTime: (hour, minutes, separator = ':') => {
        return `${time.getHours(hour)}${separator}${time.getMinutes(minutes)}`
    }
};

export default time;