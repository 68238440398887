import Icon from 'components/_UI/Icon';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Column } from 'styled/alignment/Column';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import DashHeader from './DashHeader';
import paths from 'routes/paths';
import { Link } from 'react-router-dom';
import { useContainerClick } from 'context/dash/ContainerClick';
import { useOnSelectMenu } from 'context/general/OnSelectMenu';
import { DeleteAccountDialog } from 'modules/users/profile/DeleteAccountDialog';
import Modal from 'components/_UI/Modal';
import { CheckCircle } from 'react-feather';
import Text from 'components/_UI/Text';
import { UpdatePasswordDialog } from 'modules/users/profile/UpdatePasswordDialog';
import fonts from 'theme/fonts';
import { useUser } from 'context/user/User';

export default function DashSlot({ children, onMenu = () => false }) {
    const { user } = useUser();
    const { containerClick, setContainerClick } = useContainerClick()

    const [menuExpanded, setMenuExpanded] = useState(false);
    const [menuVisible, setMenuVisible] = useState(0);
    const { onSelectMenu, setOnSelectMenu } = useOnSelectMenu()
    const [open, setOpen] = useState(false)
    const [openChangePassword, setOpenChangePassword] = useState(false)
    const [modalSucessPassword, setModalSucessPassword] = useState(false);


    useEffect(() => {
        if (containerClick) {
            setMenuExpanded(false)
            setMenuVisible(menuVisible + 1)
        }

        if (onSelectMenu) {
            actionsMenu()
        }

    }, [containerClick, onSelectMenu])

    function toggleMenu() {
        setMenuExpanded(!menuExpanded)
        onMenu()
    }

    function actionsMenu() {
        if (onSelectMenu?.tab === 'profile') {
            if (user.role.id == import.meta.env.VITE_PRODUCER_ROLE_ID) {
                window.location.href = paths.dashboard.resume

            }

            if (user.role.id == import.meta.env.VITE_ESTABLISHMENT_ROLE_ID) {
                window.location.href = paths?.dashboard?.home
            }
        }

        // if(onSelectMenu?.tab === 'delete-account') {
        //     setOpen(true)
        // } 

        if (onSelectMenu?.tab === 'logout') {
            localStorage.removeItem("token");
            window.location.href = paths.home
        }

        if (onSelectMenu?.tab === 'use-terms') {
            window.location.href = paths.terms.useTerms
        }

        if (onSelectMenu?.tab === 'change-password') {
            setOpenChangePassword(true)
        }

        if (onSelectMenu?.tab === 'privacy-policies') {
            window.location.href = paths.terms.policiePrivacy
        }

    }

    const navItems = [
        { name: 'Resumo', icon: 'chart', route: paths.dashboard.resume },
        { name: 'Estabelecimento', icon: 'store', route: paths.dashboard.home },
        { name: 'Eventos', icon: 'cheers', route: paths.dashboard.events },
        { name: 'Produtos', icon: 'label', route: paths.dashboard.products },
        { name: 'Financeiro', icon: 'money', route: paths.dashboard.financial },
        { name: 'Pedidos', icon: 'orders', route: paths.dashboard.orders },
        { name: 'Validação', icon: 'qrcode', route: paths.dashboard.checkin },
        { name: 'Suporte', icon: 'support', route: paths.dashboard.support },
    ]

    return (
        <Container>
            <DashHeader
                containerClick={containerClick}
                menuVisible={menuVisible}
                onToggleMenu={() => toggleMenu()}
                visible
            />

            <MenuExpanded active={menuExpanded}>
                <ListContainer>
                    {
                        navItems.map((el, index) => {
                            if (el?.name === 'Estabelecimento' && user?.role?.id != import.meta.env.VITE_ESTABLISHMENT_ROLE_ID) {
                                return
                            }

                            return (
                                (
                                    <ListItem to={el.route} selected={window.location.pathname === el.route}>
                                        <MenuIconContainer>
                                            <Icon
                                                name={el.icon}
                                                color={window.location.pathname !== el.route ? colors.secondary : colors.occasionalPurple}
                                            />
                                        </MenuIconContainer>
                                        &nbsp;
                                        {el.name}
                                    </ListItem>
                                )
                            )
                        })
                    }


                    {
                        user?.role?.id == import.meta.env.VITE_PRODUCER_ROLE_ID && (
                            <>
                                <Text marginTop={'35px'} paddingLeft={'10px'}>Em breve</Text>
                                <ListItem shortly>
                                    <MenuIconContainer>
                                        <Icon
                                            name={'profile'}
                                            color={colors.secondary}
                                        />
                                    </MenuIconContainer>
                                    &nbsp;
                                    Perfil
                                </ListItem>
                            </>

                        )
                    }

                    {/* <ListItem shortly>
                        <MenuIconContainer>
                            <Icon
                                name={'orders'}
                                color={colors.secondary}
                            />
                        </MenuIconContainer>
                        &nbsp;
                        Pedidos
                    </ListItem> */}


                </ListContainer>

            </MenuExpanded>

            <MainContent onClick={() => setContainerClick(containerClick + 1)}>
                <Content>
                    {children}
                </Content>
            </MainContent>

            {open &&
                <DeleteAccountDialog open={open} setOpen={setOpen} />
            }

            {
                openChangePassword &&
                <UpdatePasswordDialog
                    setModalSucessPassword={setModalSucessPassword}
                    open={openChangePassword}
                    setOpen={setOpenChangePassword}
                ></UpdatePasswordDialog>
            }

            <Modal isOpen={modalSucessPassword} onClose={() => setModalSucessPassword(false)}>
                <ContentModalSucessPassword>
                    <CheckCircle color={colors.successLight} size={'40px'} />
                    <Text color={colors.primaryDashboard} marginTop={'20px'}>
                        Sua senha foi alterada com sucesso!
                    </Text>
                </ContentModalSucessPassword>
            </Modal>
        </Container>
    )
}

const Container = styled.div`
  height: auto;
  width: 100%;
  flex-direction: column;
  @media screen and (min-width: ${breakpoints.sm}){
    display: flex;
    width: 100%;
    flex-direction: row;
  }
  /* @media screen and (min-width: ${breakpoints.lg}) {
    display: flex;
    flex-direction: row;
  } */
`;

const MenuExpanded = styled.nav`
    background: ${colors.neutral};
    width: 0;
    opacity: 0;
    height: 100%;
    display: flex;
    padding-top: 100px;
    overflow: hidden;
    position: fixed;
    transition: all 0.4s ease 0s;
    left: 0;
    top: 0;
    z-index: 5;

    @media screen and (min-width: ${breakpoints.lg}) {
        opacity: 1 !important;
        height: 100vh;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 0;
        padding-left: 25px;
        padding-bottom: -100px;
        width: 200px;
    }

    ${({ active }) => active && `
        width: 75%;
        opacity: 1;
    `}
`;

const ListContainer = styled(Column)`
    margin: 0;
    font-size: 20px;
    align-items: flex-start;
    text-align: left;
    margin-left: 30px;
    margin-top: 10px;

    @media screen and (min-width: ${breakpoints.lg}){
        font-size: .9em;
        margin-left: 0;
        align-items: flex-start;
    }
`;

const ListItem = styled(Link)`
    display: flex;
    align-items: center;
    color: ${colors.secondary};
    margin-top: 24px;
    cursor: pointer;
    transition: all ease-in-out .25s;
    /* padding: 10px; */
    padding: 6px 10px;
    width: 100%;
    
    @media screen and (min-width: ${breakpoints.lg}) {
        margin-top: 14px;
    }

    ${({ shortly }) => shortly && `
        margin-top: -2px !important;
        pointer-events: none !important;

        &:hover {
            transform: scale(1.0) !important;
        }
    `}

    &:hover{
        transform: scale(1.1);
    }

    ${({ selected }) => selected && `
        color: ${colors.occasionalPurple};
        font-weight: bold;
        border-left: 3px solid ${colors.occasionalPurple};
        border-radius: 2px;
    `}
`;

const MenuIconContainer = styled.div`
    display: flex;
    margin-right: 4px;
    width: 30px;
    height: 30px;

    &:first-child{
        padding-right: 0;
    }
`;

const MainContent = styled.main`
    width: 100%;
    padding-top: 120px;
    height: 100vh;
    background: ${colors.neutralBackground};

    @media screen and (min-width: ${breakpoints.sm}){
        height: 100vh;
        padding-top: 120px;
    }   
    @media screen and (min-width: ${breakpoints.lg}){
        height: 100vh;
    }   
    
`;


const Content = styled.div`
    display: flex;
    border: 1px solid ${colors.neutral};
    border-radius: 8px;
    min-height: calc(100% - 115px);
    padding: 0 20px 20px 20px;
    background: ${colors.neutralBackground};
    display: flex;
    justify-content: space-between;
`;

const ContentModalSucessPassword = styled(Column)`
  align-items: center;
`;

