import Fieldset from 'components/_UI/Fieldset';
import Text from 'components/_UI/Text';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import breakpoints from 'theme/breakpoints';
import { useForm } from "react-hook-form";
import colors from 'theme/colors';
import { useCheckout } from 'context/checkout/Checkout';
import masks from 'helpers/masks';
import unformat from 'helpers/unformat';
import { useUser } from 'context/user/User';
import fieldValidations from 'helpers/fieldValidations';
import UserDataCard from './UserDataCard';
import CustomFieldsForm from './CustomFieldsForm';

export default function ReceiptForm({
    trigger,
    onReceiptFormCompleted,
    tickets,
    customFields = [],
    hasDocument = false
}) {
    const { checkout, setCheckout } = useCheckout();
    const { user } = useUser();

    const [isEditing, setIsEditing] = useState(false);
    const customFieldsRefs = useRef([]);

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (trigger) {
            handleSubmit(onSubmit)();
        }
    }, [trigger]);

    useEffect(() => {
        setValue("firstName_0", user?.first_name || "");
        setValue("lastName_0", user?.last_name || "");
        setValue("email_0", user?.email);
    }, [tickets, setValue, user, isEditing]);

    const handleEdit = () => {
        setIsEditing(true);
        setValue("firstName_0", user?.first_name || "");
        setValue("lastName_0", user?.last_name || "");
        setValue("email_0", user?.email);
    };

    function createCustomFieldsPayload(fields, values) {
        const payload = {}

        Object.values(fields).forEach((field) => {
            if (field.type === 'upload') {
                if (values[field.id].url) {
                    payload[field.label] = values[field.id].url
                }
                return
            }

            if (['radio', 'checkbox'].includes(field.type)) {
                if (values[field.id].length) {
                    payload[field.label] = values[field.id]
                }
                return
            }
            if (values[field.id]) {
                payload[field.label] = values[field.id]
            }
        })

        return payload
    }

    function onSubmit(data) {
        const customFieldValues = []

        if (customFieldsRefs.current) {
            for (const ref of customFieldsRefs.current) {
                if (ref.validate()) {
                    ref.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    })
                    return
                }

                const customFieldValue = createCustomFieldsPayload(customFields, ref.getCustomFieldsValues())
                customFieldValues.push(customFieldValue)
            }
        }

        const { phone, document } = data;
        const customerFirstName = tickets?.length > 1 ? user?.first_name : data["firstName_0"] || user?.first_name;
        const customerLastName = tickets?.length > 1 ? user?.last_name : data["lastName_0"] || user?.last_name;

        const guests = tickets?.map((ticket, index) => ({
            name: `${data[`firstName_${index}`]} ${data[`lastName_${index}`]}`,
            email: data[`email_${index}`],
            batch_id: ticket.id,
            custom_fields: customFieldValues[index] || null
        }));

        const payload = {
            firstName: customerFirstName,
            lastName: customerLastName,
            document,
            phone: unformat.getNumbers(phone),
            guests,
        };

        setCheckout({
            ...checkout,
            customer: {
                first_name: customerFirstName,
                last_name: customerLastName,
                phone,
                guests,
            },
        });

        if (onReceiptFormCompleted) {
            onReceiptFormCompleted(payload);
        }
    }

    return (
        <ContainerReceiptForm>
            <Text name='subtitle' textAlign='center' type='bold' marginTop='30px'>
                Informações do participante
            </Text>

            {
                tickets?.length > 1 && (
                    <Text name='small' color={colors.occasionalPurple} type='bold'>
                        Ingresso 1 - {tickets[0].name}
                    </Text>
                )
            }

            {
                !isEditing &&
                (
                    <Column>
                        <UserDataCard data={user} onEdit={handleEdit} />

                        <Fieldset
                            marginTop='6px'
                            label='Telefone *'
                            name="phone"
                            register={register}
                            mask={masks.phone}
                            validations={fieldValidations.phone()}
                            layout='squired'
                        />

                        {errors?.phone?.message && (
                            <Column marginLeft='10px'>
                                <Text color={colors.danger} name='small'> {errors.phone?.message} </Text>
                            </Column>
                        )}

                        {
                            hasDocument &&
                            <Column>
                                <Fieldset
                                    marginTop='6px'
                                    label='CPF *'
                                    layout='squired'
                                    name="document"
                                    register={register}
                                    mask={masks.cpf}
                                    validations={fieldValidations.cpf()}
                                />

                                {errors.document?.message && (
                                    <Column>
                                        <Text color={colors.danger} name='small'> {errors.document?.message} </Text>
                                    </Column>
                                )}
                            </Column>
                        }

                        {
                            !!customFields.length &&
                            <Column marginTop={'10px'}>
                                <Text name='subtitle' textAlign={'center'} type='bold' marginTop={'16px'}>
                                    Campos adicionais
                                </Text>
                                <CustomFieldsForm
                                    ref={(el) => customFieldsRefs.current[0] = el}
                                    customFields={customFields}
                                />
                            </Column>
                        }
                    </Column>
                )
            }

            <Column>
                {!!isEditing && (
                    <Column marginTop='-20px'>
                        <RowInDesktop>
                            <Column>
                                <Fieldset
                                    marginTop='20px'
                                    label='Nome *'
                                    name="firstName_0"
                                    register={register}
                                    defaultValue={user?.first_name || ""}
                                    validations={fieldValidations.name('Nome')}
                                    layout='squired'
                                />

                                {errors?.firstName_0?.message && (
                                    <Column>
                                        <Text color={colors.danger} name='small'> {errors.firstName_0?.message} </Text>
                                    </Column>
                                )}
                            </Column>

                            <Column>
                                <Fieldset
                                    marginTop='6px'
                                    label='Sobrenome *'
                                    name="lastName_0"
                                    register={register}
                                    defaultValue={user?.last_name || ""}
                                    validations={fieldValidations.name('Sobrenome')}
                                    layout='squired'
                                />
                                {errors?.lastName_0?.message && (
                                    <Column>
                                        <Text color={colors.danger} name='small'> {errors.lastName_0?.message} </Text>
                                    </Column>
                                )}
                            </Column>
                        </RowInDesktop>

                        <Column>
                            <Fieldset
                                marginTop='6px'
                                label='E-mail *'
                                name="email_0"
                                layout='squired'
                                register={register}
                                defaultValue={user?.email || ""}
                                validations={fieldValidations.email('E-mail')}
                            />
                            {errors.email_0?.message && (
                                <Column>
                                    <Text color={colors.danger} name='small'> {errors.email_0?.message} </Text>
                                </Column>
                            )}
                        </Column>

                        {
                            !!customFields.length &&
                            <Column marginTop={'10px'}>
                                <Text name='subtitle' textAlign={'center'} type='bold' marginTop={'16px'}>
                                    Campos adicionais
                                </Text>
                                <CustomFieldsForm
                                    ref={(el) => customFieldsRefs.current[0] = el}
                                    customFields={customFields}
                                />
                            </Column>
                        }
                    </Column>
                )}

                {
                    tickets?.length > 1 &&
                    tickets?.slice(1)?.map((ticket, index) => (
                        <MultipleTicketsContainer key={index + 1}>
                            <Text name='small' color={colors.occasionalPurple} type='bold'>
                                Ingresso {index + 2} - {ticket.name}
                            </Text>
                            <Column marginTop='-20px'>
                                <RowInDesktop>
                                    <Column>
                                        <Fieldset
                                            marginTop='20px'
                                            label='Nome *'
                                            name={`firstName_${index + 1}`}
                                            register={register}
                                            defaultValue={ticket.firstName || ""}
                                            validations={fieldValidations.name('Nome')}
                                            layout='squired'
                                        />

                                        {errors[`firstName_${index + 1}`]?.message && (
                                            <Column>
                                                <Text color={colors.danger} name='small'> {errors[`firstName_${index + 1}`]?.message} </Text>
                                            </Column>
                                        )}
                                    </Column>

                                    <Column>
                                        <Fieldset
                                            marginTop='6px'
                                            label='Sobrenome *'
                                            name={`lastName_${index + 1}`}
                                            register={register}
                                            defaultValue={ticket.lastName || ""}
                                            validations={fieldValidations.name('Sobrenome')}
                                            layout='squired'
                                        />
                                        {errors[`lastName_${index + 1}`]?.message && (
                                            <Column>
                                                <Text color={colors.danger} name='small'> {errors[`lastName_${index + 1}`]?.message} </Text>
                                            </Column>
                                        )}
                                    </Column>
                                </RowInDesktop>
                                <Column>
                                    <Fieldset
                                        marginTop='6px'
                                        label='E-mail *'
                                        name={`email_${index + 1}`}
                                        layout='squired'
                                        register={register}
                                        validations={fieldValidations.email('E-mail')}
                                    />
                                    {errors[`email_${index + 1}`]?.message && (
                                        <Column>
                                            <Text color={colors.danger} name='small'> {errors[`email_${index + 1}`]?.message} </Text>
                                        </Column>
                                    )}
                                </Column>

                                {
                                    !!customFields.length &&
                                    <Column marginTop={'10px'}>
                                        <Text name='subtitle' textAlign={'center'} type='bold' marginTop={'16px'}>
                                            Campos adicionais
                                        </Text>
                                        <CustomFieldsForm
                                            ref={(el) => customFieldsRefs.current[index + 1] = el}
                                            customFields={customFields}
                                        />
                                    </Column>
                                }
                            </Column>
                        </MultipleTicketsContainer>
                    ))
                }

                {
                    !!isEditing && (
                        <>

                            <Fieldset
                                marginTop='6px'
                                label='Telefone *'
                                name="phone"
                                register={register}
                                mask={masks.phone}
                                validations={fieldValidations.phone()}
                                layout='squired'
                            />

                            {errors?.phone?.message && (
                                <Column marginLeft='10px'>
                                    <Text color={colors.danger} name='small'> {errors.phone?.message} </Text>
                                </Column>
                            )}

                            {
                                hasDocument &&
                                <Column>
                                    <Fieldset
                                        marginTop='6px'
                                        label='CPF *'
                                        layout='squired'
                                        name="document"
                                        register={register}
                                        mask={masks.cpf}
                                        validations={fieldValidations.cpf()}
                                    />

                                    {errors.document?.message && (
                                        <Column>
                                            <Text color={colors.danger} name='small'> {errors.document?.message} </Text>
                                        </Column>
                                    )}
                                </Column>
                            }
                        </>
                    )
                }

            </Column>
        </ContainerReceiptForm>
    );
}

const ContainerReceiptForm = styled(Column)`
`;

const MultipleTicketsContainer = styled(Column)`
    padding: 20px 0;
    border-bottom: 1px solid ${colors.nightLight};
`;

const RowInDesktop = styled(Column)`
   @media screen and (min-width: ${breakpoints.md}) {
    flex-direction: row;

    div:nth-child(2) {
        margin-top: 14px;
        margin-left: 10px;
    }
    
   }
`;
