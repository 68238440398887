import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useOnSelectMenu } from 'context/general/OnSelectMenu'
import { useUser } from 'context/user/User'
import paths from 'routes/paths'
import { CheckCircle } from 'react-feather'
import colors from 'theme/colors'
import Text from 'components/_UI/Text'
import Modal from 'components/_UI/Modal'
import { Column } from 'styled/alignment/Column'
import { UpdatePasswordDialog } from 'modules/users/profile/UpdatePasswordDialog'

export default function DashHeaderActions() {
    const navigate = useNavigate()
    const { onSelectMenu } = useOnSelectMenu()
    const { user } = useUser()
    const [openChangePassword, setOpenChangePassword] = useState(false)
    const [modalSuccessPassword, setModalSuccessPassword] = useState(false)

    useEffect(() => {
        const { tab } = onSelectMenu || {}

        if (!tab) {
            return
        }

        if (tab === 'profile') {
            if (user.role.id == import.meta.env.VITE_PRODUCER_ROLE_ID) {
                navigate(paths.dashboard.resume)
            }

            if (user.role.id == import.meta.env.VITE_ESTABLISHMENT_ROLE_ID) {
                navigate(paths.dashboard.home)
            }

            if (user.role.id == import.meta.env.VITE_USER_ROLE_ID) {
                navigate(paths.user.profile)
            }
        }

        if (tab === 'wallet') {
            navigate(paths.user.wallet)
        }

        if (tab === 'logout') {
            localStorage.removeItem("token")
            window.location.reload()
        }

        if (tab === 'use-terms') {
            navigate(paths.terms.useTerms)
        }

        if (tab === 'change-password') {
            setOpenChangePassword(true)
        }

        if (tab === 'privacy-policies') {
            navigate(paths.terms.policiePrivacy)
        }
    }, [onSelectMenu])

    return (
        <>
            {
                openChangePassword &&
                <UpdatePasswordDialog
                    setModalSucessPassword={setModalSuccessPassword}
                    open={openChangePassword}
                    setOpen={setOpenChangePassword}
                ></UpdatePasswordDialog>
            }

            <Modal isOpen={modalSuccessPassword} onClose={() => setModalSuccessPassword(false)}>
                <ContentModalSucessPassword>
                    <CheckCircle color={colors.successLight} size={'40px'} />
                    <Text color={colors.primaryDashboard} marginTop={'20px'}>
                        Sua senha foi alterada com sucesso!
                    </Text>
                </ContentModalSucessPassword>
            </Modal>
        </>
    )
}

const ContentModalSucessPassword = styled(Column)`
  align-items: center;
`;