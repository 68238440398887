import BackButtonCollapseDashProvider from 'context/dash/BackButtonCollapseDash'
import DetailVisibleProvider from 'context/dash/DetailVisible'
import ShowAllEventsProvider from 'context/dash/ShowAllEvents'
import DashContainer from 'modules/dashboard/_common/DashContainer'
import Orders from 'modules/dashboard/orders/Orders'
import Products from 'modules/dashboard/products/Products'
import RightSlotProducts from 'modules/dashboard/products/right-slot/RightSlotProducts'
import React, { useState } from 'react'

export default function DashOrders() {
    return (
        <BackButtonCollapseDashProvider>
            <ShowAllEventsProvider>
                <DetailVisibleProvider>

                    <DashContainer title={'Pedidos'}>
                        <Orders />
                    </DashContainer>

                </DetailVisibleProvider>
            </ShowAllEventsProvider>
        </BackButtonCollapseDashProvider>
    )
}
