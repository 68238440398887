import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import colors from "theme/colors";
import sizes from "theme/sizes";
import Button from "components/_UI/Button";
import Fieldset from "components/_UI/Fieldset";
import Text from "components/_UI/Text";
import { FullyCentered, HorizontalCenter } from "styled/alignment/Center";
import { Column } from "styled/alignment/Column";
import { StyledForm } from "styled/UI/StyledForm";
import signInTexts from "texts/general/sign-in";
import Divider from "components/_UI/Divider";
import Icon from "components/_UI/Icon";
import breakpoints from "theme/breakpoints";
import { useStepRegister } from "context/login/StepRegister";
import loginApi from "api/requests/login";
import { Link, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Loader from "components/_UI/Loader";
import fieldValidations from "helpers/fieldValidations";
import paths from "routes/paths";
import { useUser } from "context/user/User";
import GoogleSignIn from "./GoogleSignIn";
import buttonText from "texts/buttons/buttonText";
import Modal from "components/_UI/Modal";
import ConfirmEmailModal from "./ConfirmEmailModal";
import userApi from "api/requests/user";
import LinkPage from "components/_UI/LinkPage";
import { Row } from "styled/alignment/Row";
import routeParams from "theme/routeParams";
import { Plus } from "react-feather";


function SignIn() {
  const { setStepRegister } = useStepRegister();
  const { user, setUser } = useUser();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const redirectTo = searchParams.get('redirectTo') || paths.home;

  const [confirmEmail, setConfirmEmail] = useState(null)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const linkFormCreatePartner = 'https://docs.google.com/forms/d/192P_cUM7SJVSpGkod0FFf5lqkSgbmi5TQrA15djApiw/'

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false)
  const [showResendEmailMessage, setShowResendEmailMessage] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [buttonsLoading, setButtonsLoading] = useState(false);
  const [googleErrorMessage, setGoogleErrorMessage] = useState(false);
  const [confirmPassword, setConfirPassword] = useState(null);


  useEffect(() => {
    const confirmEmail = searchParams.get('confirmEmail');
    const loginPartner = searchParams.get('loginWithPartner');

    if(loginPartner == 1) {
      loginWithPartner()
    }

    if (confirmEmail == 1) {
      setShowConfirmModal(true);
      return
    }

  }, []);

  const getCookie = (name) => {
    const cookies = document.cookie.split('; ');
    const cookie = cookies.find((c) => c.startsWith(`${name}=`));
    return cookie ? cookie.split('=')[1] : null;
  };

  const loginWithPartner = () => {
    const token = getCookie('token');
    localStorage.setItem("token", token);
    window.location.reload();
  }

  const login = async ({ email, password }) => {
    try {
      setIsLoading(true);

      const { data } = await loginApi.signIn({ email, password });

      localStorage.setItem("token", data.accessToken);

      searchParams.delete('redirectTo');
      navigate({
        pathname: redirectTo,
        search: new URLSearchParams(Object.fromEntries(searchParams)).toString()
      }, { replace: true });
      window.location.reload();

    } catch (error) {      
      setErrorMessage(error?.response?.data?.message);
      setShowResendEmailMessage(false);

      if (error?.response?.data?.message === 'E-mail não verificado') {
        setShowResendEmailMessage(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const resendConfirmCode = async () => {
    setLoadingModal(true)
    try {
      await loginApi.resendConfirmCode(user);
      setShowConfirmModal(true)
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
    } finally {
      setLoadingModal(false)
    }
  };

  const startSignUp = () => {
    setStepRegister(1)
    navigate({
      pathname: paths.register,
      search: new URLSearchParams(Object.fromEntries(searchParams)).toString()
    })
  }

  return (
    <Container>
      <BackButton>
        <StyledBackButton onClick={() => navigate(paths.home)}>
          <Icon name='back' color={colors.night} />
        </StyledBackButton>
      </BackButton>
      <BoxContainer fullyCentralized disabled={loadingModal}>
        <LogoContainer>
          <Icon name={"whizr-logo"} color={colors.primary} />
        </LogoContainer>

        <StyledForm onSubmit={handleSubmit(login)}>
          <Fieldset
            placeholder={signInTexts.inputs.username}
            badgeIcon={"avatar"}
            badgePosition={"left"}
            name="email"
            register={register}
            validations={fieldValidations.email()}
          />
          {errors.email?.message && (
            <HorizontalCenter>
              <Text color={colors.danger}> {errors.email?.message} </Text>
            </HorizontalCenter>
          )}

          <Fieldset
            placeholder={signInTexts.inputs.password}
            badgeIcon={"lock"}
            badgePosition={"left"}
            badgeIconPassword
            inputType="password"
            marginTop={sizes.big}
            badgeIconColorPassword={colors.night}
            badgeIconPositionPassword={'right'}
            name="password"
            register={register}
            validations={fieldValidations.passwordWithoutPatterns()}
          />
          {errors.password?.message && (
            <HorizontalCenter>
              <Text color={colors.danger}> {errors.password?.message} </Text>
            </HorizontalCenter>
          )}
          {!isLoading ? (
            <Button
              variant={buttonsLoading ? "disabled" : "primary"}
              size="large"
              marginTop={sizes.normal}
              type="submit"
            >
              {signInTexts.buttons.enter}
            </Button>
          ) : (
            <Loader />
          )}
        </StyledForm>

        {errorMessage && (
          <>
            <Text color={colors.danger} type="bold" marginTop={sizes.small}>
              {errorMessage}
            </Text>
            {showResendEmailMessage && (
              loadingModal ? (
                <Loader />
              ) : (
                <React.Fragment>
                  <Text name="small" marginBottom={sizes.quark}>
                    {signInTexts.texts.resendEmailText}
                  </Text>
                  <Button
                    onClick={() => resendConfirmCode()}
                    category="outline"
                    size="small"
                    marginBottom="30px"
                  >
                    {signInTexts.texts.resendEmailButton}
                  </Button>
                </React.Fragment>
              )
            )}
          </>
        )}

        {!buttonsLoading && (
          <>
            <Text name="small">{signInTexts.texts.forgotPassword}</Text>
            <Button path={paths.recoverAccount} size="small" category="outline">
              {signInTexts.texts.restoreAccount}
            </Button>
          </>
        )}

        <Divider />

        {buttonsLoading ? (
          <Loader />
        ) : (
          <>
            <Column fullyCentralized marginTop={sizes.medium}>
              <Text name="small">{signInTexts.texts.dontHaveAccount}</Text>
              <Button
                variant="secondary"
                size="large"
                marginTop={sizes.nano}
                onClick={startSignUp}
              >
                <Plus /> &nbsp;&nbsp;
                {signInTexts.buttons.newAccount}
              </Button>
            </Column>

            {/* <GoogleSignIn
              googleErrorMessage={(e) => setGoogleErrorMessage(e)}
              googleLoading={(e) => setButtonsLoading(e)}
            />

            {googleErrorMessage && (
              <HorizontalCenter marginTop='14px'>
                <Text color={colors.danger}> {googleErrorMessage} </Text>
              </HorizontalCenter>
            )} */}


            <LinkCreatePartner to={linkFormCreatePartner} target={'_blank'}>
              Quer ser um parceiro do Whizr?
            </LinkCreatePartner>

            <Text name="small" marginTop={'40px'} textAlign={'center'}>
              Ao continuar você concorda com os <LinkTerms to={`${paths.terms.useTerms}`}>Termos de Uso</LinkTerms> e <LinkTerms to={`${paths.terms.policiePrivacy}`}>Política de Privacidade.</LinkTerms>
            </Text>

          </>
        )}

        {showConfirmModal && (
          <ConfirmEmailModal
            fromSignIn={true}
            setShowConfirmModal={setShowConfirmModal}
            onLogged={(e) => login(e)}
          />
        )}

      </BoxContainer>
    </Container>
  );
}

const Container = styled(Column)`
  align-items: center;
`

const BoxContainer = styled(FullyCentered)`
  flex-direction: column;
  max-width: 420px;
  height: 100%;
  padding: 40px;
  border-radius: 32px 0px 0px 32px;
  background: ${colors.neutralDark};
    ${({ disabled }) => disabled && css`
        pointer-events: none;
    `}

`;

const LogoContainer = styled.div`
  width: 110px;
  height: 110px;
  margin-bottom: ${sizes.gigantic};

  // @media screen and (min-width: ${breakpoints.lg}) {
  //     display: none;
  // }
`;

const LinkCreatePartner = styled(Link)`
  text-decoration: underline;
  color: ${colors.primary};
  margin-top: 8px;
  font-size: 12px;
  cursor: pointer;
`
const LinkTerms = styled(Link)`
  text-decoration: underline;
  color: ${colors.primary};
  font-size: 12px;
`;

const BackButton = styled(Row)`
  width: 100%;
  height: 40px;
  padding: 10px;
  margin-top: 40px;
  /* background: red; */
  align-items: center;
`;



const StyledBackButton = styled(Row)`
  width: 40px;
  padding: 10px;
  cursor: pointer;
  @media screen and (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

export default SignIn;
