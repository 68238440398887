import Text from 'components/_UI/Text';
import { useCheckout } from 'context/checkout/Checkout';
import currency from 'helpers/currency';
import React, { useState, useEffect } from 'react';
import { MinusCircle, PlusCircle } from 'react-feather';
import styled from 'styled-components';
import { Column } from 'styled/alignment/Column';
import { Row } from 'styled/alignment/Row';
import breakpoints from 'theme/breakpoints';
import colors from 'theme/colors';
import date from 'helpers/date';

export default function TicketItem({ ticket, minimal = false, loginModalCount = false, selectedTickets, setSelectedTickets, couponTrigger, onChangeTotalValue }) {
    const [quantity, setQuantity] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    const { checkout, setCheckout } = useCheckout();

    // Função para calcular a quantidade de itens selecionados por id
    const calculateQuantityFromSelectedTickets = (id) => {
        return selectedTickets.filter(selectedTicket => selectedTicket.id === id).length;
    };

    const incrementQuantity = () => {
        if (quantity < ticket.quantity && quantity < 10) {
            setQuantity(prev => prev + 1);

            const updatedTotalPrice = ticket.price * (quantity + 1);
            const updatedTotalOriginalPrice = Number(ticket.original_price) * (quantity + 1);
            const priceChange = updatedTotalPrice - totalPrice;
            const totalPriceChange = updatedTotalOriginalPrice - (Number(ticket.original_price) * quantity);

            setTotalPrice(updatedTotalPrice);
            onChangeTotalValue(priceChange, totalPriceChange);

            // Adiciona uma nova instância do ticket completo ao selectedTickets
            setSelectedTickets(prevSelectedTickets => [
                ...prevSelectedTickets,
                ticket
            ]);
        }
    };

    const decrementQuantity = () => {
        if (quantity > 0) {
            setQuantity(prev => prev - 1);

            const updatedTotalPrice = ticket.price * (quantity - 1);
            const updatedTotalOriginalPrice = Number(ticket.original_price) * (quantity - 1);
            const priceChange = updatedTotalPrice - totalPrice;
            const totalPriceChange = updatedTotalOriginalPrice - (Number(ticket.original_price) * quantity);

            setTotalPrice(updatedTotalPrice);
            onChangeTotalValue(priceChange, totalPriceChange);

            // Remove apenas uma instância do ticket correspondente
            setSelectedTickets(prevSelectedTickets => {
                const ticketIndex = prevSelectedTickets.findIndex(selectedTicket => selectedTicket.id === ticket.id);
                if (ticketIndex > -1) {
                    const updatedTickets = [...prevSelectedTickets];
                    updatedTickets.splice(ticketIndex, 1); // Remove uma instância do ingresso
                    return updatedTickets;
                }
                return prevSelectedTickets;
            });
        }
    };

    useEffect(() => {
        if (loginModalCount > 1) {
            setQuantity(0);
            setTotalPrice(0);
        }

        if (quantity === 0) {
            setCheckout(prevCheckout => ({
                ...prevCheckout,
                batches: prevCheckout?.batches?.filter(item => item.id !== ticket.id) || []
            }));
        } else {
            setCheckout(prevCheckout => {
                const existingBatch = prevCheckout?.batches?.find(item => item.id === ticket.id);
                if (existingBatch) {
                    // Atualiza a quantidade do item existente
                    return {
                        ...prevCheckout,
                        batches: prevCheckout.batches.map(item =>
                            item.id === ticket.id
                                ? { ...item, quantity: quantity }
                                : item
                        )
                    };
                } else {
                    return {
                        ...prevCheckout,
                        batches: [...prevCheckout.batches, { id: ticket.id, quantity: quantity }]
                    };
                }
            });
        }
    }, [quantity, loginModalCount]);

    // useEffect para lidar com o couponTrigger
    useEffect(() => {
        // Atualiza a quantidade conforme o selectedTickets
        const newQuantity = calculateQuantityFromSelectedTickets(ticket.id);
        setQuantity(newQuantity);

        const updatedTotalPrice = ticket.price * newQuantity;
        const updatedTotalOriginalPrice = Number(ticket.original_price) * newQuantity;
        const priceChange = updatedTotalPrice - totalPrice;
        const totalPriceChange = updatedTotalOriginalPrice - totalPrice;

        setTotalPrice(updatedTotalPrice);
        onChangeTotalValue(priceChange, totalPriceChange);
    }, [couponTrigger]);

    return (
        !!ticket?.is_active && (
            <TicketItemContainer minimal={minimal}>
                <Column>
                    <Text type='bold' marginBottom={'5px'}>
                        {ticket.name}
                    </Text>

                    <Column width='80%'>
                        {
                            Number(ticket?.original_price) === Number(ticket?.price) &&
                            <Text color={colors.night} marginBottom={'5px'}>
                                {ticket?.original_price != 0 ? currency.format(ticket?.original_price) : 'Gratuito'}
                            </Text>
                        }
                        {
                            Number(ticket?.original_price) !== Number(ticket?.price) &&
                            <Row marginBottom={'5px'}>
                                <Text
                                    color={colors.nightLight}
                                    marginRight={'.5rem'}
                                    decoration={'line-through'}
                                >
                                    {currency.format(ticket?.original_price)}
                                </Text>
                                <Text color={colors.success}>
                                    {currency.format(ticket?.price)}
                                </Text>
                            </Row>
                        }
                        {
                            ticket?.description && (
                                <Text color={colors.primaryLight} type='bold' marginBottom={'5px'}>
                                    {ticket?.description}
                                </Text>
                            )
                        }
                        {
                            ticket.quantity <= 15 &&
                            <Text color={colors.occasionalOrange} name='small' marginBottom='5px'>
                                Restam poucos ingressos
                            </Text>
                        }
                        {
                            ticket.sale_end &&
                            <Text color={colors.night} name='small' type='italic' paddingBottom={0}>
                                Vendas até {date.format(ticket.sale_end)}
                            </Text>
                        }
                    </Column>
                </Column>

                <QuantityContainer>
                    <ToggleValuesContainer>
                        <MinusCircle
                            style={{ cursor: 'pointer' }}
                            color={colors.night}
                            size={20}
                            onClick={decrementQuantity}
                        />

                        <Text marginLeft={'6px'} marginRight={'6px'} color={colors.night} paddingBottom={0}>
                            {quantity}
                        </Text>

                        <PlusCircle
                            style={{
                                pointerEvents: quantity === 10 ? 'none' : 'auto',
                                cursor: quantity === 10 ? 'not-allowed' : 'pointer'
                            }}
                            color={quantity === 10 ? colors.nightLight : colors.night}
                            size={20}
                            onClick={incrementQuantity}
                        />
                    </ToggleValuesContainer>
                    <Text type='bold' color={colors.night} paddingBottom={0}>
                        R$ {totalPrice.toFixed(2)}
                    </Text>
                </QuantityContainer>
            </TicketItemContainer>
        )
    );
}

const TicketItemContainer = styled(Row)`
    margin-top: 20px;
    padding: 14px 0;
    align-items: center;
    border-bottom: 1px solid ${colors.nightLight};

    ${({ stoped }) => stoped && `
        opacity: .5;
    `};

    @media screen and (min-width: ${breakpoints.lg}) {
        border-bottom: none;
        border-top: 1px solid ${colors.nightLight};
        margin-top: 0;
        
        &:first-child {
            border-top: none;
        }

        ${({ minimal }) => minimal && `
            border-top: none;
            border-bottom: 1px solid ${colors.nightLight};
        `}
    }
`;

const QuantityContainer = styled(Column)`
    flex-direction: column;
    width: auto;
    align-items: center;
    width: 100px;
`;

const ToggleValuesContainer = styled(Row)`
    margin-bottom: 10px;
    width: auto;
`;
