import ordersApi from 'api/requests/orders';
import DashCollapse from 'components/_UI/DashCollapse';
import InfoTooltip from 'components/_UI/InfoTooltip';
import Loader from 'components/_UI/Loader';
import Paginate from 'components/_UI/Paginate';
import Table from 'components/_UI/Table';
import Text from 'components/_UI/Text';
import currency from 'helpers/currency';
import date from 'helpers/date';
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Info } from 'react-feather';
import styled from 'styled-components';
import { FullyCentered } from 'styled/alignment/Center';
import { Column } from 'styled/alignment/Column';
import colors from 'theme/colors';
import OrderDetail from './OrderDetail';
import LinkPage from 'components/_UI/LinkPage';
import Chips from 'components/_UI/Chips';
import Ilustration from 'components/_UI/Ilustration';
import paths from 'routes/paths';

export default function OrdersTable({ status }) {
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState([]);
    const [perPage, setPerpage] = useState(null);
    const [lastPage, setLastPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [totalItems, setTotalItems] = useState(null);
    const [detail, setDetail] = useState();

    useEffect(() => {
        getOrders();
    }, [status])

    async function getOrders(page = 1) {
        setLoading(true);

        try {
            const { data } = await ordersApi.get({ status, page })
            setContent(data?.data);
            setPerpage(data?.meta.per_page)
            setLastPage(data.meta.last_page)
            setCurrentPage(data?.meta.current_page)
            setTotalItems(data?.meta.total)
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    const ordersList = content?.map(item => {
        const date = new Date(item?.created_at);

        return {
            // id: <Text name='small'>{item?.id}</Text>,
            name: <Text name='small'>{item?.customer.name}</Text>,
            date: <Text name='small'>
                {date.toLocaleDateString('pt-BR')} - {date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}
            </Text>,
            value: <Text name='small'>{currency.format(item?.total)}</Text>,
            event: (
                <LinkPage isWrapper to={`${paths.dashboard.events}?detail=${item?.event?.slug}`} target={'_SELF'}>
                    <Text name='small' decoration='underline' color={colors.primary}>
                        {item?.event?.name}
                    </Text>
                </LinkPage>
            ),
            status: <Chips text={item?.status} size='small' />,
            actions:
                <>
                    <ContentAction
                        onClick={() => setDetail(item)}
                    >
                        <InfoTooltip
                            bgColor={colors.night}
                            text={'Ver detalhes'}
                            type='none'
                        >
                            <Info size={20} color={colors.primaryLight} />
                        </InfoTooltip>
                    </ContentAction>
                </>
        };
    })

    return (
        <Container>
            {
                loading ? (
                    <Loader />
                ) : (
                    content?.length <= 0 ? (
                        <Column fullyCentralized>
                            <Column width='auto' height='300px'>
                                <Ilustration name='orders' />
                            </Column>
                            <Text marginTop={'15px'} name='highlight'>Você ainda não possui pedidos.</Text>
                        </Column>
                    ) : (
                        <>
                            <Table
                                rows={ordersList}
                                // columns={['ID', 'Cliente', 'Data', 'Valor', 'Evento', 'Status', 'Ações']}
                                columns={['Cliente', 'Data', 'Valor', 'Evento', 'Status', 'Ações']}
                            />
                            
                            {
                                lastPage > 1 && (
                                    <Paginate
                                        perPage={perPage}
                                        totalItems={totalItems}
                                        lastPage={lastPage}
                                        firstPage={1}
                                        currentPage={currentPage}
                                        marginTop={'30px'}
                                        onPaginate={(number) => getOrders(number)}
                                    />
                                )
                            }
                        </>
                    )
                )
            }

            <DashCollapse visible={detail} onClose={() => setDetail(false)}>
                {detail && <OrderDetail data={detail} />}
            </DashCollapse>
        </Container >

    )
}

const Container = styled(Column)`
    min-height: 300px;
    margin-top: 30px;
    border-radius: 8px;
    background-color: ${colors.neutralLight};
    padding: 20px;
    box-shadow: 1px 1px 4px #ddd;
    justify-content: center;
`;

const ContentAction = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all ease-in-out 0.25s;

    & > div > svg {
        &:hover {
            transform: scale(1.1);
       }
    }
`